import React, { FC, useMemo } from 'react';

import withLoadComponentDataUrl from '../../../Load/LoadComponentDataUrl';

import { IGallerySliderItem } from './interfaces';

import './gallery-slider-item.scss';

// gallery modal item
const GallerySliderItem: FC<IGallerySliderItem> = ({ current, prev, data, item, size }) => {
  // render
  const imageUrl = useMemo(() => {
    if (data?.data?.links) {
      return size === 'lg' ? data?.data?.links.modal_1600.href : data?.data?.links.modal_600.href;
    }
    return data?.data.attributes.uri.url;
  }, [size, data])
  return (
    <div
      className="gallery-slider-item"
      data-active={current}
      data-last={prev}>
        <img src={imageUrl} alt={item.meta.title} />
    </div>
  );
};

export default withLoadComponentDataUrl(GallerySliderItem);