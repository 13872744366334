import React, { FC } from 'react';

import Image from '../../../Image';

import { IListGroupItem } from './interfaces';

import './list-group-item.scss';

// list group item
const ListGroupItem: FC<IListGroupItem> = ({ attributes, relationships }) => {
  const { field_text: { value }, field_title } = attributes;

  // render
  return (
    <div className="list-group-item">
      <div className="list-group-item--title">
        {relationships.field_icone_svg.data &&
          <Image id={relationships.field_icone_svg.data.id} />}

        <p className="title">{field_title}</p>
      </div>
      <div className="list-group-item--content">
        <div className="description" dangerouslySetInnerHTML={{ __html: value }} />
      </div>
    </div>
  );
};

export default ListGroupItem;