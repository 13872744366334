import React, { FC } from 'react';

import ListIndicatorsContainer from './ListIndicatorsContainer';
import TitleText from '../../TitleText';

import { IListIndicators } from './interfaces';

import './list-indicators.scss';

// list indicators
const ListIndicators: FC<IListIndicators> = ({ id, attributes }) => {
  const { field_indicadores, field_title } = attributes;

  // render
  return (
    <div className="list-indicators">
      {field_title && <TitleText attributes={{ 
        field_title: field_title,
        field_title_tag: 'h2' }} id={id} />}

      <ListIndicatorsContainer items={field_indicadores} />
    </div>
  );
};

export default ListIndicators;