import React, { FC } from 'react';

import Image from '../../Image';

import { ICardCollapseImage } from './interfaces';

import './card-collapse-image.scss';

// card collapse image
const CardCollapseImage: FC<ICardCollapseImage> = ({ id, meta: { alt } }) => {
  // render
  return (
    <div className="card-collapse-image">
     <Image id={id} options={{ alt }} />
    </div>
  );
};

export default CardCollapseImage;