import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ITimeLineHomeInfo } from './interfaces';

import './timeline-home-info.scss';

// time line home info
const TimeLineHomeInfo: FC<ITimeLineHomeInfo> = ({ data }) => {
  const { attributes: { field_title, field_texto} } = data;

  // render
  return (
    <div className="timeline-home-info">
      <Link className="home" to="/">
        <span className="icon icon-logo"></span>
      </Link>
      <p className="title">{field_title}</p>
      {field_texto?.value && <div className="description" dangerouslySetInnerHTML={{ __html: field_texto.value }} />}
    </div>
  );
};

export default TimeLineHomeInfo;