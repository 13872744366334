import React, { FC } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import TimeLineHomeBackground from './TimeLineHomeBackground';
import TimeLineHomeInfo from './TimeLineHomeInfo';
import TimeLineHomeImage from './TimeLineHomeImage';
import withLoadComponentDataNode from '../../Load/LoadComponentDataNode';

import { ITimeLineHome } from './interfaces';

import './timeline-home.scss';

// time line home
const TimeLineHome: FC<ITimeLineHome> = ({ active, data, onPrevNext, media, init }) => {
  // render
  return (
    <div className="timeline-home" data-active={active}>
      {(media === 'xs' || media === 'sm') &&
        <TimeLineHomeBackground animate={active} />}

      <div className="timeline-home--container">
        {data &&
          <TimeLineHomeImage data={data.included[0]} />}

        {data &&
          <TimeLineHomeInfo data={data.included[0]} />}

        {(media === 'xs' || media === 'sm') && <AnchorLink
          className="btn-next"
          href={`#timeline-item-${init?.title}`}>
            <span className="icon icon-arrow-left-min"></span>
          </AnchorLink>}

        {(media === 'md' || media === 'lg') && <button
          className="btn-next"
          onClick={() => onPrevNext('next')}>
          <span className="icon icon-arrow-left-min"></span>
        </button>}
      </div>
    </div>
  );
};

export default withLoadComponentDataNode(TimeLineHome);