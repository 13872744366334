import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import HeaderContainer from '../HeaderContainer';
import withLoadComponentDataUrl from '../../../components/Load/LoadComponentDataUrl';

import { IHeaderMenu } from './interfaces';

import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';

import './header-menu.scss';

// header menu
const HeaderMenu: FC<IHeaderMenu> = ({ data, lang, open, mediaQuery }) => {
  // render
  return (
    <div className="header-menu" data-active={open}>
      <Link
        className="header-menu--logo"
        to={`/${lang.lang}`}
        aria-label="logo"><Logo /></Link>

      <HeaderContainer
        lang={lang}
        items={data}
        open={open}
        mediaQuery={mediaQuery}
        url={`/${lang.lang}/api/menu-image/`} />
    </div>
  );
};

export default withLoadComponentDataUrl(HeaderMenu);