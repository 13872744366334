import React, { cloneElement, FC } from 'react';
import { useResponsive } from 'react-hooks-responsive';

import { IMediaQueries } from './interfaces';

// break points
const breakpoints = { xs: 0, sm: 767, md: 1023, lg: 1140 };

// load component data
const MediaQueries: FC<IMediaQueries> = ({ children, breakpoints }) => {
  const { size } = useResponsive(breakpoints);

  // render
  return(
    <>
      {cloneElement(children, { mediaQuery: size })}
    </>
  );
};

// load component data
const withMediaQueries = (ComponentElement: any) => {
  // render
  return (props: any) => {
    const { size } = useResponsive(breakpoints);

    // render
    return(
      <>
        <ComponentElement queries={size} {...props} />
      </>);
  };
};

export { withMediaQueries, MediaQueries };
export default MediaQueries;