import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ILangContext, ILangItem, ILangProvider } from './interfaces';

import { langs, findLangByUrl, getLang } from './langs';

// lang context
const LangContext = createContext({} as ILangContext);

// lang provider
const LangProvider: FC<ILangProvider> = ({ children }) => {
  let history = useHistory();
  const { i18n } = useTranslation();
  const [ lang, setLang] = useState<ILangItem>(findLangByUrl(history.location.pathname));
  // set language
  const setLanguage = useCallback((value: string) => {
    if (value === lang.name) return false;
    
    const valueLang = getLang(value);

    if (valueLang instanceof Object) {
      setLang(valueLang)
      history.push('/'+value);
    }
  }, [ lang, history ]);

  // get lang
  const getLanguage = useCallback((lang: string) =>
    langs.filter((item: any) => item?.lang === lang)[0], []);

  // use effect
  useEffect(() => {
    i18n.changeLanguage(lang?.lang);
  }, [ lang, i18n ]);

  // render
  return (
    <LangContext.Provider value={{
      lang: lang,
      langs,
      getLanguage,
      setLang: setLanguage
    }}>
      {children}
    </LangContext.Provider>
  );
};

export { LangContext, LangProvider };
export default LangProvider;