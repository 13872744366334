import React, { FC, useCallback } from 'react';

import ShipsFiltersCategoryItem from './ShipsFiltersCategoryItem';

import { IShipsFiltersCategory } from './interfaces';

import getFilters from './filters';

import './ships-filters-category.scss';

// ships filters category
const ShipsFiltersCategory: FC<IShipsFiltersCategory> = ({ uuid, filters, setFilters, lang }) => {
  // on set filters
  const onSetFilters = useCallback((value: any, name: any) => {
    if (value instanceof Object) {
      const items: any = {...filters};

      if (value.value !== '') {
        items[name] = value;
      } else {
        delete items[name];
      }

      setFilters({ ...items });
    }
  }, [ filters, setFilters ]);

  // render
  return (
    <div className="ships-filters-category">
      {getFilters(uuid, lang.lang).map(({ options, defaultText, name }, index: number) =>
        <ShipsFiltersCategoryItem
          active={true}
          name={name}
          defaultText={defaultText}
          options={options}
          selectedOption={filters[name]}
          onChange={(value: any) => onSetFilters(value, name)}
          key={index} />)}
    </div>
  );
};

export default ShipsFiltersCategory;