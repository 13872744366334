import React, { FC } from 'react';

import { ISelectItem } from './interfaces';

import './select-item.scss';

// select item
const SelectItem: FC<ISelectItem> = ({ item, onSelectOption, selectedOption }) => {
  const { value, label } = item;

  // render
  return (
    <div
      className="select-item"
      onClick={() => onSelectOption(item)} data-active={selectedOption.value === value}>
      <p className="text">{label}</p>
    </div>
  );
};

export default SelectItem;
