const get_filters = (filter:any, lang:any = '') => {
  switch(filter) {
    case "field_embarcacao_dp":
      return { 
        defaultText: "DP",
        name: "field_embarcacao_dp",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "dp1", label: "DP1"},
          {value: "dp2", label: "DP2"},
        ]
      };
    case 'field_embarcacao_acomodacaototal':
      return {
        defaultText: lang === 'en' ? "Accommodation" : "Acomodação Total",
        name: "field_embarcacao_acomodacaototal",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "20_40", label: lang === 'en' ? "20 to 40" : "20 a 40"},
          {value: "40_60", label: lang === 'en' ? "40 to 60" : "40 a 60"},
          {value: "60_80", label: lang === 'en' ? "60 to 80" : "60 a 80"},
          {value: "80_100", label: lang === 'en' ? "80 to 100" : "80 a 100"},
          {value: "acima_100", label: lang === 'en' ? "over 100" : "acima de 100"},
        ]
      };
    case 'field_embarcacao_guindaste':
      return {
        defaultText: lang === 'en' ? "Offshore Crane" : "Guindaste Offshore",
        name: "field_embarcacao_guindaste",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "10_ton_3000m", label: lang === 'en' ? "10 ton @ 3,000m":"10 ton @ 3.000m"},
          {value: "20_ton_3000m", label: lang === 'en' ? "20 ton @ 3,000m":"20 ton @ 3.000m"},
        ]
      };
    case 'field_embarcacao_fifi':
      return { 
        defaultText: "FiFi",
        name: "field_embarcacao_fifi",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "fifi_1", label:"FiFi 1"},
          {value: "fifi_2", label:"FiFi 2"},
          {value: "no_fifi", label:"No FiFi"},
        ],
      };
    case 'field_embarcacao_deck_area':
      return { 
        defaultText: lang === 'en' ? "Deck Area" : 'Área de Convés',
        name: "field_embarcacao_deck_area",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "150_500", label: lang === 'en'? "150 to 500" : "150 a 500"},
          {value: "500_750", label: lang === 'en'? "500 to 750" : "500 a 750"},
          {value: "750_1000", label: lang === 'en' ? "750 to 1,000":"750 a 1.000"},
          {value: "acima_1000", label: lang === 'en' ? "over 1,000":"acima de 1.000"},
        ],
      };
    case 'field_embarcacao_capacidade_oro':
      return {
        defaultText: lang === 'en' ? "ORO Capacity" : "Capacidade ORO",
        name: "field_embarcacao_capacidade_oro",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "500_750", label: lang === 'en'? "500 to 750":"500 a 750"},
          {value: "750_1080", label: lang === 'en'? "750 to 1,080":"750 a 1.080"},
          {value: "acima_1100", label: lang === 'en'? "over 1,100":"acima de 1.100"},
        ]
      };
    case 'field_embarcacao_dwt':
      return {
        defaultText: lang === 'en' ? "Deadweight" : "Tonelagem Porte Bruto",
        name: "field_embarcacao_dwt",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "1500_3000", label: lang === 'en'? "1,500 to 3,000" : "1.500 a 3.000"},
          {value: "3000_4000", label: lang === 'en'? "3,000 to 4,000" : "3.000 a 4.000"},
          {value: "4000_4500", label: lang === 'en'? "4,000 to 4,500" : "4.000 a 4.500"},
          {value: "acima_4500", label: lang === 'en'? "over 4,500" : "acima de 4.500"},
        ]
      };
    case 'field_embarcacao_bollard_pull':
      return {
        defaultText: "Bollard Pull",
        name: "field_embarcacao_bollard_pull",
        options: [
          {value: "", label: lang === 'en'? "All options" : "Todas as opções"},
          {value: "160_200", label: lang === 'en' ? "160 to 200" : "160 a 200"},
          {value: "acima_200", label: lang === 'en' ? "over 200" : "acima de 200"},
        ]
      };
  }
}
// rsv
export const getFilterRSV = (lang:any) => [
  get_filters('field_embarcacao_dp', lang),
  get_filters('field_embarcacao_acomodacaototal', lang),
  get_filters('field_embarcacao_guindaste', lang),
  get_filters('field_embarcacao_fifi', lang),
];

// orsv
export const getFilterORSV = (lang:any) => [
  get_filters('field_embarcacao_dp', lang),
  get_filters('field_embarcacao_deck_area', lang),
  get_filters('field_embarcacao_capacidade_oro', lang),
  get_filters('field_embarcacao_fifi', lang),
];

// psv
export const getFilterPSV = (lang:any) => [
  get_filters('field_embarcacao_dwt', lang),
  get_filters('field_embarcacao_dp', lang),
  get_filters('field_embarcacao_deck_area', lang),
  get_filters('field_embarcacao_fifi', lang),
];

// ahts
export const getFilterAHTS = (lang:any) => [
  get_filters('field_embarcacao_dp', lang),
  get_filters('field_embarcacao_bollard_pull', lang),
  get_filters('field_embarcacao_deck_area', lang),
  get_filters('field_embarcacao_fifi', lang),
];

// get filters
const getFilters = (uuid: string, lang: string): any[] => {
  switch (uuid) {
    case 'e630a8a3-277f-4d65-b7d6-73d5c9241ecc':
      return getFilterRSV(lang);
    case 'c8ee153f-e9bc-411d-8f42-99012d7df48a':
      return getFilterORSV(lang);
    case '3c8ef042-a652-4936-a27e-30d330289a85':
      return getFilterPSV(lang);
    case '4df9fc6f-a152-48a9-afed-4171213d2020':
      return getFilterAHTS(lang);
  }

  return [];
};

export default getFilters;