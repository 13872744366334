import React, { FC, memo, useState } from 'react';

import TabsBackground from './TabsBackground';
import TabsContent from './TabsContent';

import { ITabs } from './interfaces';

import './tabs.scss';

// tabs
const Tabs: FC<ITabs> = ({ id, type, attributes, lang }) => {
  const [ animation, setAnimation ] = useState<boolean>(true);
  const [ current, setCurrent ] = useState<number>(0);

  // render
  return (
    <div className="tabs">
      <div className="tabs--title">
        <h2 className="text">{attributes.field_title}</h2>
      </div>

      <TabsBackground
        animation={animation}
        setAnimation={setAnimation} />

      <TabsContent
        current={current}
        animation={animation}
        setAnimation={setAnimation}
        setCurrent={setCurrent}
        url={`/${lang.lang}/jsonapi/paragraph/${type.replace('paragraph--', '')}/${id}?include=field_embarcacoes`} />
    </div>
  );
};

export default memo(Tabs);
