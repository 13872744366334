import React, { FC } from 'react';

import FeaturesSlider from './FeaturesSlider';

import { IFeatures } from './interfaces';

import './features.scss';

// features
const Features: FC<IFeatures> = ({ lang, relationships }) => {
  const { field_caracteristicas: { data } } = relationships;

  // render
  return (
    <div className="features">
      <FeaturesSlider
        lang={lang}
        items={data} />
    </div>
  );
};

export default Features;