import React, { FC, useCallback, useState } from 'react';
import { useGesture } from 'react-use-gesture';

import BaseSlider from '../../Slider/Base';
import TimeLineBackground from '../TimeLineBackground';
import TimeLineControls from '../TimeLineControls';
import TimeLineControlsMobile from '../TimeLineControlsMobile';
import TimeLineHome from '../TimeLineHome';
import TimeLineItem from '../TimeLineItem';
import { ITimeLineItem } from '../TimeLineItem/interfaces';

import { ITimeLineList } from './interfaces';

import './timeline-list.scss';

// time line list
const TimeLineList: FC<ITimeLineList> = ({
  current,
  last,
  lang,
  items,
  onPrevNext,
  setCurrent,
  entity,
  media
}) => {
  const { entity: { bundle, uuid } } = entity;

  const [animate, setAnimate] = useState<boolean>(false);
  // const [scroller, setScroller] = useState<boolean>(true);

  // on change
  const onChange = useCallback(({ direction: [, yDir], distance, cancel }, ...rest) => {
    if (media === 'xs' || media === 'sm') return false;
    
    if (distance > 400) {
      const dir = yDir < 0 ? 'prev' : 'next';

      cancel(onPrevNext(dir));
      setAnimate(true);
    }
  }, [onPrevNext, setAnimate, media]);

  // on change item
  const onChangeItem = useCallback((index: number) => {
    setCurrent(index);
    setAnimate(true);
  }, [setCurrent]);

  // bind
  const bind = useGesture({
    onDragEnd: props => {onChange(props)},
    // onWheelEnd: props => {console.log(props); onChange(props)},
    // onScrollEnd: props => {console.log(props); onChange(props)},
  });

  // render
  return (
    <>
      {(media === 'xs' || media === 'sm') &&
        <TimeLineControlsMobile
          items={items} />}

      {(media === 'md' || media === 'lg') &&
        <TimeLineControls
          current={current}
          items={items}
          setCurrent={onChangeItem} />}

      {items &&
        <div
          {...bind()}
          className="timeline-list"
          data-current={current}
          data-type={items[current].color}>
          <TimeLineBackground
            animate={animate}
            current={current}
            color={items[current]?.color}
            setAnimate={setAnimate} />

          {items.map((item: ITimeLineItem, index: number) =>
            item.color === 'home' ?
              <TimeLineHome
                active={index === current}
                lang={lang}
                node={`${bundle}/${uuid}?include=field_paragraphs`}
                onPrevNext={onPrevNext}
                media={media}
                init={items[1]}
                key={index} /> :

              <TimeLineItem
                {...item}
                // setScroller={setScroller}
                current={index === current}
                last={index === last}
                key={index} />)}
        </div>}
    </>
  );
};

export default BaseSlider(TimeLineList);