import React, { FC } from 'react';
import { Parallax } from 'react-scroll-parallax';

import Image from '../Image';

import { IImageFull } from './interfaces';

import './image-full.scss';

// image full
const ImageFull: FC<IImageFull> = ({ relationships }) => {
  const { field_imagem: { data } } = relationships;

  // render
  return (
    <Parallax className="image-full" translateY={[10, 0]}
      // tagOuter="div"
    >
      <Image id={data.id} />
    </Parallax>
  );
};

export default ImageFull;