import React, { FC } from 'react';

import Link from '../../../components/Link';
import Search from '../../../components/Search';
import SelectLang from '../../../components/SelectLang';
import withLoadComponentDataUrl from '../../../components/Load/LoadComponentDataUrl';

import { IHeaderTop, IHeaderTopItem } from './interfaces';

import './header-top.scss';

// header top
const HeaderTop: FC<IHeaderTop> = ({ data, lang, mediaQuery, open, setOpen }) => {
  // render
  return (
    <div className="header-top" data-media={mediaQuery}>
      {mediaQuery === 'lg' && <ul className="header-top--list">
        {data && data.map(({ relative, title, external }: IHeaderTopItem, index: number) =>
          <li className="header-top--item" key={index}>
            <Link
              internal={!external}
              className="link-item"
              href={relative}
              aria-label={title}
              target={external ? '_blank' : ''}>{title}</Link>
          </li>)}
      </ul>}

      <Search lang={lang} />

      <SelectLang />

      {mediaQuery !== 'lg' &&
        <button
          aria-label="open-menu"
          className="btn-clear header-top--toggle"
          data-active={open}
          onClick={() => setOpen(!open)}>
          <span className="icon icon-hamburger"></span>  
          <span className="icon icon-close"></span>  
        </button>}
    </div>
  );
};

export default withLoadComponentDataUrl(HeaderTop);