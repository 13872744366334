import React, { FC, useCallback, useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import Pagination from '../../Pagination';

import { ISearchPagination } from './interfaces';

import './search-pagination.scss';

// env
const { REACT_APP_URL_BASE } = process.env;

// api
const api = (url: string) => axios.get(url).then(({ data }) => data);

// search pagination
const SearchPagination: FC<ISearchPagination> = ({ lang, pagination, setFilter, term }) => {
  const { data }: any = useSWR<[any]>(`${REACT_APP_URL_BASE}/${lang.lang}/api/search-count?search=${term}`, api);
  const [ current, setCurrent ] = useState<number>(1);

  // get total
  const getTotal = useCallback(() => {
    if (Array.isArray(data) && data?.length > 0) {
      return parseInt(Math.round(data?.length / pagination).toString(), 10);
    }

    return 0;
  }, [ data, pagination ]);

  // set pagination
  const setPagination = useCallback((value: number, total: number) => {
    setCurrent(value);
    setFilter(value, total);
  }, [ setCurrent, setFilter ]);

  // render
  return (
    <div className="search-pagination">
      <Pagination
        current={current}
        setCurrent={setPagination}
        total={getTotal()} />
    </div>
  );
};

export default SearchPagination;