import React, { FC, useCallback } from 'react';

import Blog from '../../pages/Blog';
import withLoadComponentDataUrl from '../../components/Load/LoadComponentDataUrl';

import Home from '../../pages/Home';
import Interna from '../../pages/Interna';
import ShipsPage from '../../pages/ShipsPage';
import ShipsCategory from '../../pages/ShipsCategory';
import ShipsProvider from '../../providers/ShipsProvider';


import { IPage } from './interfaces';

import './page.scss';

// interna
const Page: FC<IPage> = ({ data, lang, getType }) => {
  // get element
  const getElement = useCallback(() => {
    if (data instanceof Object === false) return false;
    
    if (data.entity.bundle === 'home') {
      return <Home node={getType(lang)} lang={lang} />;
    }

    if (data.entity.bundle === 'categoria_embarcacao') {
      return <ShipsCategory
        {...data}
        url={`/${lang.lang}/jsonapi/taxonomy_term/categoria_embarcacao?sort=weight,name&include=field_icone`} lang={lang} />;
    }

    if (data.entity.bundle === 'embarcacao') {
      return <ShipsProvider><ShipsPage {...data} lang={lang} /></ShipsProvider>;
    }
    
    if (data.entity.bundle === 'blog') {
      return <Blog lang={lang} url={getType(lang, data)} />;
    }

    return <Interna lang={lang} url={getType(lang, data)} />;
  }, [ data, getType, lang ]);
  
  // render
  return (
    <>
     {getElement()}
    </>
  );
};

export default withLoadComponentDataUrl(Page);