import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import { IBlogList } from './interfaces';

import './blog-list.scss';
import { IBlogItem } from '../BlogItem/interfaces';
import BlogItem from '../BlogItem';

// vars
const {
  REACT_APP_URL_BASE,
  REACT_APP_URL_NODE,
  REACT_APP_BLOG_PAGINATION
}: any = process.env;

// api
const api = (url: string) => axios.get(REACT_APP_URL_BASE + REACT_APP_URL_NODE + url).then(({ data }) => data);

// blog list
const BlogList: FC<IBlogList> = ({ lang, page, getTotal }) => {
  const [ init, setInit ] = useState<boolean>(false);
  const { data } = useSWR<any>(`/blog?filter[langcode]=${lang.lang}&include=field_image,field_assunto&sort=-field_date&page[limit]=${REACT_APP_BLOG_PAGINATION}&page[offset]=${REACT_APP_BLOG_PAGINATION * (page - 1)}`, api);
  // get item relation
  const getItemRelation = useCallback((id: string, items: any[]) =>
    new Promise((resolve, reject) => {
      const item = items.filter((item: any) => item.id === id);

      if (item instanceof Object) {
        resolve(item[0]);
      } else {
        reject(false);
      }
    }), []);

  // get info
  const getRelations = useCallback(async (relations: any) => {
    if (relations instanceof Object === false
      || data?.included instanceof Object === false) return false;
    
    const { field_assunto, field_image } = relations;

    const filesInclude = data?.included.filter((item: any) => item.type === 'file--file');
    const taxonomiesInclude = data?.included.filter((item: any) => item.type === 'taxonomy_term--assunto');

    const image = await getItemRelation(field_image.data.id, filesInclude);
    const tax = await getItemRelation(field_assunto.data.id, taxonomiesInclude);

    return { image, tax };
  }, [ getItemRelation, data ]);

  // use effect flag init
  useEffect(() => {
    if (init === false && data instanceof Object) {
      setInit(true);
      getTotal(data.meta.count);
    }
  }, [ data, init, getTotal ]);

  // render
  return (
    <div className="blog-list">
      <ul className="blog-list--container">
        {data && data.data.map((item: IBlogItem, index: number) =>
          <BlogItem
            {...item}
            getRelations={getRelations}
            key={index} />)}
      </ul>
    </div>
  );
};

export default memo(BlogList);