import React, { FC, useMemo } from 'react';
import withLoadComponentDataUrl from '../Load/LoadComponentDataUrl';
import "./table.scss";

const TableParagraph: FC<any> = ({data}) => {
    // render
    const table = useMemo(() => {
        var rows:any = [];
        var header:any = [];
        if (data) {
            var lines = data.data.relationships.field_table_row?.data?.map( (item:any) =>  data.included.find((inc:any) => inc.id === item.id && inc.type === item.type));
            var maxColumns = 0;
            lines = lines.map( ({attributes}:any) => {
                if (attributes?.field_table_text) {
                    return attributes?.field_table_text.map((i:any) => '<p>' + i + '</p>');
                }
                else if(attributes?.field_table_ckeditor) {
                    return attributes?.field_table_ckeditor.map((i:any) => i.processed);
                }
                return [];
            }).filter( (i:any) => i.length > 0);
            lines.forEach( (line:any) => {
                maxColumns = line.length > maxColumns ? line.length : maxColumns;
            })
            lines.forEach( (line:any, index: any) => {
                var row = []
                for(var i = 0; i < maxColumns; i++) {
                    row.push(line[i] || '&nbsp;');
                }
                if (index === 0) {
                    header.push(row);
                }
                else {
                    rows.push(row)
                }
            });
        }
        return {header,  rows};
    }, [data]);

    return table.rows.length > 0 ? (<div className="table">
        <table>
            {table.header.length > 0 && <thead>
                {table.header.map( (item:any, key:any) => <tr className="table-row" key={key}>
                    {item.map( (cell:any, idx:any) => <th className="table-title" key={idx} dangerouslySetInnerHTML={{__html: cell}} />)}
                </tr>)}
            </thead>}
            {table.rows.length > 0 && <tbody>
                {table.rows.map( (item:any, key:any) => <tr className="table-row" key={key}>
                {item.map( (cell:any, idx:any) => <td className="table-element" key={idx} dangerouslySetInnerHTML={{__html: cell}} />)}
                </tr>)}
            </tbody>}
        </table>
    </div>) : null;
};

export default withLoadComponentDataUrl(TableParagraph);