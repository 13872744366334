import React, { FC } from 'react';

import { ISelectMinTitle } from './interfaces';

import './select-min-title.scss';

// select title
const SelectMinTitle: FC<ISelectMinTitle> = ({
  active,
  defaultText,
  onToggle,
  selectedOption
}) => {
  // render
  return (
    <div className="select-min-title"
      onClick={() => onToggle(!active)}
      data-active={active}>
      <p className="select-min-title--content">
        <span
          className="text"
          data-empty={selectedOption instanceof Object ? true : false}>
            {selectedOption instanceof Object && selectedOption.label ? selectedOption.label : defaultText }
        </span>
        <span className="icon icon-arrow-down-round" data-active={active}></span>
      </p>
    </div>
  );
};


export default SelectMinTitle;