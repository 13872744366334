import React, { FC, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { useResponsive } from 'react-hooks-responsive';
import BaseSlider from '../../Slider/Base';
import GalleryModalControls from './GalleryModalControls';
import GalleryModalInfo from './GalleryModalInfo';
import GallerySlider from '../GallerySlider';
import GalleryThumbs from '../GalleryThumbs';

import { IGalleryModal } from './interfaces';

import './gallery-modal.scss';

const modal: any = document.getElementById('modal');

// gallery modal
const breakpoints = { xs: 0, sm: 767, md: 1023, lg: 1140 };

const GalleryModal: FC<IGalleryModal> = ({
  current,
  prev,
  direction,
  items,
  onPrevNext,
  setCurrent
}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  // on current
  const { size } = useResponsive(breakpoints);
  const onCurrent = useCallback((value: number) => {
    setOpen(true);
    setCurrent(value);
  }, [ setOpen, setCurrent ]);

  // render
  return (
    <div className="gallery-modal">
      <GalleryThumbs
        options={{infinite: false}}
        items={items}
        size={size}
        onCurrent={onCurrent} />

      {open && createPortal(<div className="gallery-modal-modal">
        <GalleryModalInfo
          item={items[current]}
          onCloseOpen={() => setOpen(false)} />

        <GallerySlider
          items={items}
          current={current}
          size={size}
          last={prev}
          direction={direction}
          onPrevNext={onPrevNext} />

        <GalleryModalControls onPrevNext={onPrevNext} />
      </div>, modal)}
    </div>
  );
};

export default BaseSlider(GalleryModal);