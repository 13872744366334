import React, { FC, useRef } from 'react';

import BackgroundFluent from '../../../Backgrounds/BackgroundFluent';
import UseDimension from '../../../../uses/UseDimension';

import { ReactComponent as Background } from '../../../../assets/timeline/bg-timeline-home.svg';

import { ITimeLineHomeBackground } from './interfaces';

import './timeline-home-background.scss';

import { settings } from './settings';

// time line home background
const TimeLineHomeBackground: FC<ITimeLineHomeBackground> = ({ animate }) => {
  const element = useRef<any>(null);
  const { height, width } = UseDimension(element);

  // render
  return (
    <div
      ref={element}
      className="timeline-home-background">
      <BackgroundFluent
        active={animate}
        speed={1}
        height={height}
        width={width}
        options={settings}>
          <Background />
      </BackgroundFluent>
    </div>
  );
};

export default TimeLineHomeBackground;