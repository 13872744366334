import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import MenuContainer from './MenuContainer';

import { IMenu } from './interfaces';

import './menu.scss';

// menu
const Menu: FC<IMenu> = ({ relationships, lang }) => {
  const { field_menu: { data: { id }} } = relationships;
  const location = useLocation();

  // render
  return (
    <div className="menu">
      <MenuContainer
        lang={lang}
        node={`submenu/${id}`}
        pathname={location?.pathname} />
    </div>
  );
};

export default Menu;