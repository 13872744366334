import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { PortalProvider } from "react-portal-hook";

import axios from 'axios';
import * as serviceWorker from './serviceWorker';

import MainProvider from './providers/MainProvider';

import Main from './layouts/Main';

import './i18n';
import './styles.css';

axios.defaults.baseURL = process.env.REACT_APP_URL_BASE;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <PortalProvider>
        <MainProvider>
          <Main />
        </MainProvider>
      </PortalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
