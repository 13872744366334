import React, { FC } from 'react';

import FeaturesHistorySlider from './FeaturesHistorySlider';
import withLoadComponentDataNode from '../Load/LoadComponentDataNode';

import { IFeaturesHistory } from './interfaces';

import './features-history.scss';

// features history
const FeaturesHistory: FC<IFeaturesHistory> = ({ data }) => {
  // render
  return (
    <div className="features-history">
      {data && <FeaturesHistorySlider
        items={data.data}
        include={data.included} /> }
    </div>
  );
};

export default withLoadComponentDataNode(FeaturesHistory);