import React, { FC, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import { ShipsContext } from '../../../../providers/ShipsProvider';

import { IShipsTabsItem } from './interfaces';

import './ships-tabs-item.scss';

// ships tabs item
const ShipsTabsItem: FC<IShipsTabsItem> = ({ current, index, name, url, total }) => {
  const shipsContext = useContext(ShipsContext);
  const { setIndexTab } = shipsContext;

  // use effect
  useEffect(() => {
    if (current === true) {
      setIndexTab(index);
    }
  }, [ index, setIndexTab, current ]);

  // render
  return (
    <div className="ships-tabs-item">
      <NavLink
        className="item-link"
        data-active={current}
        to={url}>
        <span className="count">{total}</span>
        <span className={`icon icon-${name.toLowerCase()}`}></span>
        <span className="text">{name}</span>
      </NavLink>
    </div>
  );
};

export default ShipsTabsItem;
