import React, { FC } from 'react';

import withLoadComponentDataUrl from '../../../components/Load/LoadComponentDataUrl';

import { IFooterSocial, IFooterSocialItem } from './interfaces';

import './footer-social.scss';

// footer social
const FooterSocial: FC<IFooterSocial> = ({ data }) => {
  // render
  return (
    <div className="footer-social">
      {data && data?.data.map(({ attributes: { field_redesocial_tipo, field_redesocial_url }}: IFooterSocialItem, index: number) =>
        <a
          className="item-link"
          href={field_redesocial_url}
          target="_blank"
          rel="noopener noreferrer"
          key={index}>
          <span className={`icon icon-${field_redesocial_tipo}`}></span>
        </a>)}
    </div>
  );
};

export default withLoadComponentDataUrl(FooterSocial);