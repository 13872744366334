import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ISelectLangItem } from './interfaces';

import './select-lang-item.scss';

const { PUBLIC_URL }: any = process.env;

// select lang item
const SelectLangItem: FC<ISelectLangItem> = ({
  lang,
  setOpen,
  setLang
}) => {
  const { t } = useTranslation();

  // select lang
  const selectLang = useCallback((lang: string) => {
    setOpen(false);
    setLang(lang);
  }, [ setLang, setOpen ]);

  // render
  return (
    <button
      aria-label={lang.lang}
      className="select-lang-item"
      onClick={() => selectLang(lang?.lang)}>
      <img className="icon-flag" src={`${PUBLIC_URL}${lang.image}`} alt={lang.lang} />
      <span className="text">{t(lang.name)}</span>
    </button>
  );
};

export default memo(SelectLangItem);