import React, { FC } from 'react';

import withLoadComponentDataUrl from '../../Load/LoadComponentDataUrl';

import { IBlockPrintItem } from './interfaces';

import './block-print-item.scss';

// block print item
const BlockPrintItem: FC<IBlockPrintItem> = ({ data }) => {
  // render
  return (
    <div className="block-print-item">
      {data &&
      <>
        <p className="block-print-item--name">{data?.data.attributes.field_title}</p>
        <ul className="block-print-item--list">
          {data?.data.attributes.field_assessoria_contato.map(({ first, second }, index: number) => 
            <li className="block-print-item--list--item" key={index}>
              <p className="label">{first}: <strong>{second}</strong></p>
            </li>)}
        </ul>
      </>}
    </div>
  );
};

export default withLoadComponentDataUrl(BlockPrintItem);