import React, { FC } from 'react';

import { IShipsCardModalProp, IShipsCardModalPropItem } from './interfaces';

import './ships-card-modal-prop.scss';

// ships card modal prop
const ShipsCardModalProp: FC<IShipsCardModalProp> = ({ items }) => {
  // render
  return (
    <ul className="ships-card-modal-prop">
      {items && items.map(({ icon, label, value }: IShipsCardModalPropItem, index: number) => 
        <li className="ships-card-modal-prop--item" key={index}>
          <p className="label">
            <span className={`icon icon-${icon}`}></span>
            <span className="text">{label}</span>
          </p>
          
          <p className="value">{value}</p>
        </li>)}
    </ul>
  );
};

export default ShipsCardModalProp;