import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Image from '../../Image';

import { IBlogItem } from './interfaces';

import './blog-item.scss';
// vars
const {
  REACT_APP_BLOG_CONSUMER_ID
}: any = process.env;
// blog item
const BlogItem: FC<IBlogItem> = ({ attributes, relationships, getRelations }) => {
  const { field_resumo: { value }, path: { alias }, title } = attributes;
  const [ blogAssets, setBlogAssets ] = useState<any>({ image: {}, tax: {}});
  
  // use effect
  useEffect(() => {
    const getInfo = async () => {
      getRelations(relationships)
        .then((data: any) => {
          if (data instanceof Object) {
            setBlogAssets({ ...data });
          }
        });
    }

    getInfo();
  }, [ relationships, getRelations ]);

  // render
  return (
    <li className="blog-item--wrapper">
      <Link className="blog-item" to={`${alias.substring(1)}`}>
        <div className="blog-item--image">
          {blogAssets &&
            <>
              {blogAssets.image.id &&
                <Image id={blogAssets.image.id} options={{ alt: title }} consumerId={REACT_APP_BLOG_CONSUMER_ID} crop='thumb_454_341' />}

              {blogAssets.tax.attributes &&
                <p className="taxonomy">{blogAssets.tax.attributes.name}</p>}
            </>}
        </div>

        <div className="blog-item--info">
          <p className="title">{title}</p>
          <div className="description" dangerouslySetInnerHTML={{ __html: value }} />
        </div>
      </Link>
    </li>
  );
};

export default BlogItem;