
import '../nanoid/polyfill';

import React, { FC, memo } from 'react';
import { nanoid } from 'nanoid';

import { ILoading } from './interfaces';

import { ReactComponent as Logo } from '../../assets/svg/logo.svg';

import './loading.scss';

// loading
const Loading: FC<ILoading> = () => {
  const id = nanoid();

  // render
  return (
    <div className="loading" data-active={id.toString()} key={id}>
      <Logo />
    </div>
  );
};

export const SmallLoading = () => {
  return (
    <div className="mini-loading small">
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default memo(Loading);