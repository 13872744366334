import React, { FC, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

import withLoadComponentDataUrl from '../../Load/LoadComponentDataUrl';

import { IBlockImageItem } from './interfaces';

import './block-image-item.scss';

// block image item
const BlockImageItem: FC<IBlockImageItem> = ({ data, multiple, type, image_style }) => {
  // get link
  const getLink = useCallback((field_link_text: string, field_link_url: string = ''): ReactNode => {
    return <Link className="link-item" to={field_link_url}>
      {field_link_text ?
        <span className="text">{field_link_text}</span> :
        <span className="icon icon-arrow-right-min"></span>}
    </Link>
  }, []);

  // get info
  const getInfo = useCallback(() => {
    if (data instanceof Object === false) return false;
    const { data: { attributes: { field_link_url, field_link_text, field_title, field_text: { value } } } } = data;

    switch (multiple) {
      case true:
        return <>
          <p className="title-h4">{field_title}</p>
          <p className="subtitle" dangerouslySetInnerHTML={{ __html: value }} />

          {field_link_url && getLink(field_link_text, field_link_url)}
        </>;
      default:
        return <>
          <p className="title-home">{field_title}</p>
          <p className="subtitle" dangerouslySetInnerHTML={{ __html: value }} />

          {field_link_url && getLink(field_link_text, field_link_url)}
        </>;
    }
  }, [ data, multiple, getLink ]);

  // get image
  const getImage = useCallback(() => {
    if (data instanceof Object === false) return false;
    const { included, data:{ relationships } } = data;

    if (Array.isArray(included)) {
      const { attributes: { filename, uri: { url } }, links } = included[0];

      let image_url = links && links[image_style] ? links[image_style].href : url;
      const {field_imagem: {data: {meta:{alt, title}}}} = relationships;

      return <Parallax className="parallax-outer image-container" translateY={[-20, 20]}>
        <img src={image_url} alt={alt ?? filename} title={title ?? filename} />
      </Parallax>;
    }
  }, [ data, image_style ]);

  // render
  return (
    <div className="block-image-item" data-type={multiple} data-color={type}>
      <div className="block-image-item--image">{getImage()}</div>
      <div className="block-image-item--info">{getInfo()}</div>
    </div>
  );
};

export default withLoadComponentDataUrl(BlockImageItem);