import React, { FC } from 'react';

import GalleryModal from '../../Gallery/GalleryModal';
import ShipsItemInfo from './ShipsItemInfo';
import ShipsItemRelationships from './ShipsItemRelationships';
import withLoadComponentDataNode from '../../Load/LoadComponentDataNode';

import { IShipsItem } from './interfaces';

import './ships-item.scss';

// ships item
const ShipsItem: FC<IShipsItem> = ({ data, id, lang }) => {
  // render
  return (
    <div className="ships-item">
      {data?.data &&
        <>
          <ShipsItemInfo
            lang={lang}
            {...data?.data}
            specification={data?.included} />

          {data?.data.relationships.field_embarcacao_galeria &&
            <GalleryModal
              items={data?.data.relationships.field_embarcacao_galeria.data} />}

          <ShipsItemRelationships
            id={id}
            lang={lang}
            parentId={data?.data.relationships.field_categoria_embarcacao.data.id} />
        </>}
    </div>
  );
};

export default withLoadComponentDataNode(ShipsItem);