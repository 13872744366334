import React, { FC, useCallback } from 'react';

import withLoadComponentDataUrl from '../../Load/LoadComponentDataUrl';

import { IShipsThumb } from './interfaces';

import './ships-thumb.scss';

// ships thumb
const ShipsThumb: FC<IShipsThumb> = ({ data, type }) => {
  // filter sources
  const filterSources = useCallback((sources, prefix) => {
    const elements: any= [];

    for (let key in sources) {
      const item = sources[key];
      const size = key.replace(prefix, '').split('_');

      if (size.length > 1) {
        const { href } = item;

        elements.push({ size: { 
          width: parseInt(size[1], 10),
          height: parseInt(size[2], 10) 
        }, href });
      }
    }

    elements.sort((a: any, b: any) => a.size.width - b.size.width); // order by with

    return elements;
  }, []);

  // get image
  const getImage = useCallback((type: any) => {
    if (data.data instanceof Object === false) return false;

    const { links } = data?.data;
    
    if (links instanceof Object && Object.keys(links).length > 0) {
      const images = filterSources(links, 'thumb');

      if (images instanceof Object && images.length > 0) {
        switch (type) {
          case 1:
            return <img src={images[0].href} alt={data.data.attributes.filename} />
        }
      }
    }

    return;
  }, [ data, filterSources ]);

  // render
  return (
    <div className="ships-thumb">
      {data?.data && getImage(type)}
    </div>
  );
};

export default withLoadComponentDataUrl(ShipsThumb);