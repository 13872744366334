import React from 'react';

import Blog from '../../components/Blog';
import BlockImage from '../../components/BlockImage';
import BlockImageFull from '../../components/BlockImageFull';
import BlockMap from '../../components/BlockMap';
import BlockPrint from '../../components/BlockPrint';
import BlockShip from '../../components/BlockShip';
import BlockSplit from '../../components/BlockSplit';
import ButtonDownload from '../../components/ButtonDownload';
import Card from '../../components/Card';
import CardCollapse from '../../components/CardCollapse';
import ClientSlider, { ClientSliderParagraph } from '../../components/ClientSlider';
import Features from '../../components/Features';
import FeaturesHistory from '../../components/FeaturesHistory';
import FormContact from '../../components/Forms/FormContact';
import Gallery from '../../components/Gallery';
import HighLights from '../../components/HighLights';
import ImageFull from '../../components/ImageFull';
import Menu from '../../components/Menu';
import ListAddress from '../../components/List/ListAddress';
import ListGroup from '../../components/List/ListGroup';
import ListIndicators from '../../components/List/ListIndicators';
import ListSimple from '../../components/List/ListSimple';
import Ships from '../../components/Ships';
import SocialMedia from '../../components/SocialMedia';
import Tabs from '../../components/Tabs';
import TitleText from '../../components/TitleText';
import Text from '../../components/Text';
import Webdoor from '../../components/Webdoor';
import ViewVideo from '../../components/ViewVideo';
import VideoPlayer from '../../components/VideoPlayer';
import ViewDocumentosIgualdadeSalarial from '../../components/ViewDocumentosIgualdadeSalarial';

import TableParagraph from '../../components/Table/paragraph';
import GoldenRulesParagraph from '../../components/GoldenRules/paragraph'; /*novo componente*/

import { ILangItem } from '../LangProvider/interfaces';

// env
const { REACT_APP_WEBDOOR_CONSUMER_ID, REACT_APP_BLOG_PAGINATION } = process.env;

// create component
export const createComponent = (element: any, type: string, lang: ILangItem) => {
  const { id } = element;

  switch (type) {
    case 'view':
      const { attributes: { field_view_type, field_title, field_view_pager }} = element;

      switch (field_view_type) {
        case 'blog':
          return <Blog lang={lang} key={id} />;
        case 'slider_clientes':
          return <ClientSlider
            lang={lang}
            node={`cliente?include=field_logo`}
            key={id} />;
        case 'feed_social':
          return <SocialMedia
            lang={lang}
            node={`noticia?&page[limit]=4`}
            consumerId='d84cdb29-3bd5-4234-a5b6-d0d9dc9ddbaa'
            image_style='thumb_375_280'
            title={field_title}
            key={id} />;
        case 'feed_blog_home':
          return <SocialMedia
            lang={lang}
            node={`blog?&page[limit]=4&sort=-field_date`}
            consumerId='d84cdb29-3bd5-4234-a5b6-d0d9dc9ddbaa'
            image_style='thumb_352_264'
            title={field_title}
            key={id} />;
        case 'lista_enderecos':
          return <ListAddress
            lang={lang}
            linkText={lang.lang === 'en' ? 'See on map' : 'Ver no mapa'}
            node={`endereco?include=field_image`}
            key={id} />;
        case 'lista_vagas':
          return <ListAddress
          //return <GoldenRules
            linkText={lang.lang === 'en' ? 'I wish to apply':'Quero me candidatar'}
            lang={lang}
            node={`vaga?include=field_image&filter[tipo][path]=field_vaga_tipo&sort=-sticky,created&filter[tipo][value]=mar`}
            key={id} />
        case 'lista_vagas_terra':
          return <ListAddress
            linkText={lang.lang === 'en' ? 'I wish to apply':'Quero me candidatar'}
            lang={lang}
            node={`vaga?include=field_image&filter[tipo][path]=field_vaga_tipo&sort=-sticky,created&filter[tipo][value]=terra`}
            key={id} />
        case 'embarcacao':
          return <Ships {...element} lang={lang} key={id} />;
        case 'slider_historia':
          return <FeaturesHistory
            node={`historia?filter[langcode]=${lang?.lang}&sort=title&fields[node--historia]=title,body,field_image,field_link_texto,field_link_tipo,field_link_url&include=field_image&consumerId=d84cdb29-3bd5-4234-a5b6-d0d9dc9ddbaa`}
            lang={lang}
            key={id} />;
        case 'webdoor':
          return <Webdoor
            lang={lang}
            node={`webdoor?include=field_image&consumerId=${REACT_APP_WEBDOOR_CONSUMER_ID || null}`}
            key={id} />;
        case 'documentos_igualdade_salarial':
          return <ViewDocumentosIgualdadeSalarial
            lang={lang}
            node={`documento_igualdade_salarial?include=field_documento_arquivo`}
            key={id} />;
        case 'video':
          return <ViewVideo
            limit={field_view_pager || REACT_APP_BLOG_PAGINATION}
            lang={lang}
            key={id} />;
      }

      return false;
    case 'blocos':
      return <BlockImage {...element} lang={lang} key={id} />;
    case 'bloco_caracteristicas':
      return <Features {...element} lang={lang} key={id} />;
    case 'bloco_icones_texto':
      return <ListGroup {...element} lang={lang} key={id} />;
    case 'block_1':
      return <Card {...element} lang={lang} key={id} />;
    case 'block_2':
      return <CardCollapse {...element} key={id} />;
    case 'block_3':
      return <BlockPrint {...element} lang={lang} key={id} />;
    case 'block_4':
      return <BlockShip {...element} key={id} />;
    case 'block_5':
      return <BlockMap {...element} key={id} />;
    case 'botao':
        return <ButtonDownload {...element} key={id} />;
    case 'diferenciais':
      return <HighLights {...element} lang={lang} key={id} />;
    case 'faixa_1':
      return <BlockSplit {...element} key={id} />;
    case 'faixa_2':
      return <BlockImageFull {...element} key={id} />;
    case 'formulario':
      return <FormContact {...element} lang={lang} key={id} />;
    case 'galeriaimagem':
      return <Gallery {...element} lang={lang} key={id} />;
    case 'image':
      return <ImageFull {...element} key={id} />;
    case 'menu':
      return <Menu lang={lang} {...element} key={id} />;
    case 'lista':
      return <ListSimple {...element} key={id} />;
    case 'lista_1':
      return <ListIndicators {...element} key={id} />;
    case 'lista_embarcacoes':
      return <Tabs {...element} lang={lang} key={id} />;
    case 'titulo':
      return <TitleText {...element} key={id} />;
    case 'texto':
      return <Text {...element} key={id} />;
    case 'video':
      return <VideoPlayer {...element} key={id} />;
    case 'blocos_regras_de_ouro':
      return <GoldenRulesParagraph
        {...element}
        key={id}
        url={`/${lang.lang}/jsonapi/paragraph/blocos_regras_de_ouro/${id}?include=field_regra_ouro_item,field_regra_ouro_item.field_imagem,field_regra_ouro_item.field_imagem`}
        />;
    case 'table':
      return <TableParagraph
        {...element}
        key={id}
        url={`/${lang.lang}/jsonapi/paragraph/table/${id}?include=field_table_row`}
        />;
    case 'cliente_slider':
      return <ClientSliderParagraph lang={lang} items={element?.relationships?.field_clientes?.data} key={id} />;
  }
};