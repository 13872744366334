import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Slider from 'react-slick';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useTranslation } from 'react-i18next';

import { ITimeLineControlsMobile } from './interfaces';

import './timeline-controls-mobile.scss';

import { settings } from './settings';

// time line controls mobile
const TimeLineControlsMobile: FC<ITimeLineControlsMobile> = ({ items }) => {
  const { t }: any = useTranslation();

  const element: any = useRef(null);
  const [ position, setPosition ] = useState<any>({ prev: { x: 0, y: 0 }, current: { x: 0, y: 0} });

  // use scroll position
  useScrollPosition(({ prevPos, currPos }) => {
    setPosition({ prev: prevPos, current: currPos});
  }, [], undefined, true, 600);

  // find element body scroll
  const findElementBodyScroll = useCallback((position: any) => {
    // const { current: { y } } = position;
    const elements = document.body.querySelectorAll('.timeline-list > div[data-active]');
    
    if (elements instanceof Object) {
      for (let index = 0; index < elements.length; index++) {
        const item = elements[index];

        if (item instanceof Object) {
          const { height, top } = item.getBoundingClientRect();
          const windowHeight = window.innerHeight * .4;

          if ((top + height - windowHeight) > 0) {
            return element.current.slickGoTo(index + 1);
          }
        }
      }
    }
  }, [ element ]);

  // use effect
  useEffect(() => {
    findElementBodyScroll(position)
  }, [ position, findElementBodyScroll ]);

  // render
  return (
    <div className="timeline-controls-mobile">
      <Slider
        {...settings}
        ref={slider => (element.current = slider)}
        className="timeline-controls-mobile--slider">
          <AnchorLink className="btn-item" href={`#timeline-item-${t('init')}`} key="home">{t('init')}</AnchorLink>

          {items && items.map(({ title }, index: number) => 
            <AnchorLink
              className="btn-item"
              href={`#timeline-item-${title}`}
              key={index}>{title}</AnchorLink>)}
      </Slider>
    </div>
  );
};

export default TimeLineControlsMobile;