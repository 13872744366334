import React, { FC, useContext } from 'react';

import ShipsTabsList from './ShipsTabsList';

import { ShipsContext } from '../../../providers/ShipsProvider';

import { IShipsTabs } from './interfaces';

import './ships-tabs.scss';

// Ships tabs
const ShipsTabs: FC<IShipsTabs> = ({ lang }) => {
  const shipsContext = useContext(ShipsContext);
  const { ships, indexTab, current } = shipsContext;

  // render
  return (
    <div className="ships-tabs">
      {ships &&
        <ShipsTabsList data={ships} current={current} index={indexTab} lang={lang} />}
    </div>
  );
};

export default ShipsTabs;