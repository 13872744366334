import React, { FC } from 'react';
import { Field } from 'formik';

import './index.scss';

// Input
const Checkbox: FC<any> = ({ maxLength, required, name, label, type, info, error, value, onChange, onBlur, onFocus, validate }) => {
  // render
  const events: any = {}
  if (onChange) events.onChange = onChange;
  if (onBlur) events.onBlur = onBlur;
  if (onFocus) events.onFocus = onFocus;
  if (validate) events.validate = validate;
  return (
    <label
      className="input-control checkbox-field"
      data-active={value !== '' && !error}
      data-error={!!error}
      data-type={type}>
        <Field
            name={name}
            checked={value}
            type="checkbox"
            {...events}
            required={required} />
        <span className="icon icon-check" ></span>
        {label}
      {error && <span className="error">{error}</span>}
    </label>
  );
};
export default Checkbox;