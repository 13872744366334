import React, { FC, useState, useCallback, useMemo } from 'react';

import { IViewVideo } from './interfaces';
import Pagination from '../Pagination';
import ViewVideoContent from './ViewVideoContent';
import './ViewVideo.scss';

// ViewVideo
const ViewVideo: FC<IViewVideo> = ({ lang, limit } : any) => {
    // render
    const [total, setTotal ] = useState<any>(1);
    const [page, setPage] = useState<any>(1);

    // get total
    const getTotal = useCallback((count: any) => 
        setTotal(count), []);
    const pages = useMemo(() => Math.ceil(parseInt(total, 10) / parseInt(limit, 10)), [total, limit])
    return (
        <div className="view-videos">
            <ViewVideoContent 
                limit={limit}
                lang={lang}
                page={page}
                getTotal={getTotal}
             ></ViewVideoContent>
        {pages > 1 && <Pagination
            current={page}
            setCurrent={setPage}
            total={pages} />}
        </div>
    );
};



export default ViewVideo;