import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { IFooterMenu } from './interfaces';

import './footer-menu.scss';

// footer menu
const FooterMenu: FC<IFooterMenu> = ({ items }) => {
  // return
  return (
    <ul className="footer-menu">
      {items && items.map((item: any, index: number) =>
        <li className="footer-menu--item" key={index}>
          <Link className="link-item" to={item.relative}>
            <span className="text">{item.title}</span>
          </Link>
        </li>)}
    </ul>
  );
};

export default FooterMenu;