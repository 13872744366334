import React, { FC } from 'react';
import { IGoldenRulesItem } from './interfaces';

import './golden-rules-item.scss';

// list address item
const GoldenRulesItem: FC<IGoldenRulesItem> = ({ title, text, icon/*, number*/}) => {
  // render
  return (
    <div className="golden-rules-item">
      {icon && <div className={"golden-rules-item--image " /*+ (number ? 'with-number':'')*/}>
        {/*number && <span className="number">{number}</span>*/}
        <img src={icon} alt={title || 'Icone'} title={title || 'Icone'} />
      </div>}

      <div className="golden-rules-item--info">
        {title && <h4 className="title">{title}</h4>}
        {text && <div className="tex" dangerouslySetInnerHTML={{ __html: text }} />}
      </div>
    </div>
  );
};

export default GoldenRulesItem;