import React, { FC } from 'react';

import ShipsContainer from './ShipsContainer';
import ShipsTabs from './ShipsTabs';

import ShipsProvider from '../../providers/ShipsProvider';

import { IShips } from './interfaces';

import './ships.scss';

// ships
const Ships: FC<IShips> = ({ lang }) => {
  // render
  return (
    <div className="ships">
      <ShipsProvider>
        <ShipsTabs lang={lang} />
        <ShipsContainer lang={lang} />
      </ShipsProvider>
    </div>
  );
};

export default Ships;
