import React, { FC, Fragment } from 'react';
import { Field } from 'formik';

import './input.scss';

// Input
const Input: FC<any> = ({ maxLength, required, name, label, type, info, error, value, onChange, onBlur, onFocus, validate }) => {
  const events: any = {}
  if (onChange) events.onChange = onChange;
  if (onBlur) events.onBlur = onBlur;
  if (onFocus) events.onFocus = onFocus;
  if (validate) events.validate = validate;
  // get input type
  const getInputType = () => {
    switch (type) {
      case 'checkbox':
      case 'check':
        return <Fragment>
          <Field
            {...events}
            name={name}
            checked={value}
            type="checkbox" 
            required={required} />
          {/* <label className="icon icon-check"></label> */}
        </Fragment>;
          
      case 'email':
        return <Field
          {...events}
          maxLength={maxLength}
          name={name}
          type="text" 
          required={required} />;

      case 'textarea':
        return <Fragment>
          {info && <label className="info">{info}</label>}
          <Field
            {...events}
            rows="7"
            maxLength={maxLength}
            name={name}
            component="textarea"
            required={required} />
        </Fragment>;

      case 'text':
      default:
        return <Field
          {...events}
          type="text"
          name={name}
          maxLength={maxLength}
          required={required} />;
    }
  };

  // render
  return (
    <label
      className="input-control"
      data-active={value !== '' && !error}
      data-error={!!error}
      data-type={type}>
      {getInputType()}
      <label className="label">{label}</label>
      {error && <label className="error">{error}</label>}
    </label>
  );
};
// Input.defaultProps = {
//   onChange: (props:any) => {},
//   onBlur: (props:any) => {},
//   onFocus: (props:any) => {},
// }
export default Input;