import React, { FC } from 'react';

import ListAddressContainer from './ListAddressContainer';
import withLoadComponentDataNode from '../../Load/LoadComponentDataNode';

import { IListAddress } from './interfaces';

import './list-address.scss';

// list address
const ListAddress: FC<IListAddress> = ({ data, linkText = false }) => {
  // render
  return (
    <div className="list-address">
      {data?.data && <ListAddressContainer items={data?.data} linkText={linkText} /> }
    </div>
  );
};

export default withLoadComponentDataNode(ListAddress);