import React, { FC } from 'react';
import Slider from 'react-slick';

import GalleryThumbsArrow from './GalleryThumbsArrow';
import GalleryThumbsItem from './GalleryThumbsItem';

import { IGalleryThumbs } from './interfaces';

import { settings } from './settings';

import './gallery-thumbs.scss';

const { REACT_APP_URL_FILE, REACT_APP_GALLERY_CONSUMER_ID } = process.env;

// gallery thumbs
const GalleryThumbs: FC<IGalleryThumbs> = ({ items, size, onCurrent, options }) => {
  // render
  return (
    <div className="gallery-thumbs">
      <Slider
        {...{
          ...settings,
          ...options
        }}
        className="gallery-thumbs--content"
        variableWidth={size === 'xs' || size === 'sm'}
        nextArrow={<GalleryThumbsArrow icon="arrow-right-l" />}
        prevArrow={<GalleryThumbsArrow icon="arrow-left-l" />}>
          {items && items.map((item: any, index: number) => 
            <GalleryThumbsItem
              {...item}
              url={`${REACT_APP_URL_FILE}/${item.id}?consumerId=${REACT_APP_GALLERY_CONSUMER_ID}`}
              onCurrent={onCurrent}
              index={index}
              key={index} /> )}
      </Slider>
    </div>
  );
};
export default GalleryThumbs;