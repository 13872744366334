import React, { FC } from 'react';
import Slider from 'react-slick';
import ClientSliderContainer from './ClientSliderContainer';
import { settings } from './ClientSliderContainer/settings';
import withLoadComponentDataNode from '../Load/LoadComponentDataNode';
import ClientSliderArrow from './ClientSliderArrow';
import {ClientSliderItemParagraph} from './ClientSliderItem';

import { IClientSlider } from './interfaces';

import './client-slider.scss';

export const ClientSliderWrapper: FC<any> = ({ children }) => {
  return (
    <div className="client-slider">
      {children}
    </div>
  );
};

export const ClientSliderParagraph: FC<any> = ({ lang, items }) => {
  return (
    <ClientSliderWrapper>
      <div className="client-slider-container">
        <Slider
          className="client-slider-container--slider"
          {...{
            ...settings,
            infinite: items.length > 5,
          }}
          nextArrow={<ClientSliderArrow icon="arrow-right" />}
          prevArrow={<ClientSliderArrow icon="arrow-left" />}>
          {items && items.map((item: any, index: number) =>
            <ClientSliderItemParagraph url={`/${lang.lang}/jsonapi/paragraph/${item.type.split('--')[1]}/${item.id}?include=field_imagem`} lang={lang} {...item} key={index} />
            )}
        </Slider>
      </div>
    </ClientSliderWrapper>
  );
};

// client slider
const ClientSlider: FC<IClientSlider> = ({ lang, data }) => {
  // render
  return (
    <ClientSliderWrapper>
      {data &&
        <ClientSliderContainer
          lang={lang}
          items={data?.data} />}
    </ClientSliderWrapper>
  );
};

export default withLoadComponentDataNode(ClientSlider);