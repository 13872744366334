import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import TimeComponent from '../../components/TimeLine';

import withLoadComponentDataUrl from '../../components/Load/LoadComponentDataUrl';

import { ITimeLine } from './interfaces';

import './timeline.scss';

// time line
const TimeLine: FC<ITimeLine> = ({ data, lang, location }) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div className="timeline">
      <div className="timeline--return">
        <Link to="/" className="btn-border back">
          <span className="icon icon-arrow-left-min"></span>
          {t('back')}
        </Link>
      </div>

      {data instanceof Object &&
        <TimeComponent
          items={data}
          lang={lang}
          url={`/${lang.lang}/router/translate-path?path=${location?.pathname}`} />}
    </div>
  );
};

export default withLoadComponentDataUrl(TimeLine);