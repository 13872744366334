import React, { FC } from 'react';

import { IListIndicatorsItem } from './interfaces';

import './list-indicators-item.scss';

// list indicators item
const ListIndicatorsItem: FC<IListIndicatorsItem> = ({ first, second }) => {
  // render
  return (
    <div className="list-indicators-item">
      <p className="title-number">{first}</p>
      <p className="description">{second}</p>
    </div>
  );
};

export default ListIndicatorsItem;