import React, { FC, useCallback } from 'react';
import Slider from 'react-slick';

import FeaturesArrow from '../FeaturesArrow';
import FeaturesItem from '../FeaturesItem';

import { IFeaturesSlider } from './interfaces';

import './features-slider.scss';

import { settings } from './settings';

// highlights slider
const FeaturesSlider: FC<IFeaturesSlider> = ({ lang, items }) => {
  // get type
  const getType = useCallback((index: number) => {
    if ((index + 1) % 3 === 0)
      return 3;

    if ((index + 1) % 2 === 0)
      return 2;

    return 1;
  }, []);

  // get component
  const getComponent = useCallback((item: any, index: number) => {
    const { id, type } = item;
    const typeComponent: string = type.replace('paragraph--', '');

    return <FeaturesItem
      {...item}
      itemType={getType(index)}
      url={`/${lang.lang}/jsonapi/paragraph/${typeComponent}/${id}?include=field_icone_svg`}
      key={index} />;
  }, [ lang, getType ]);

  // render
  return (
    <div className="features-slider">
      <Slider
        {...settings}
        nextArrow={<FeaturesArrow icon="arrow-right-l" />}
        prevArrow={<FeaturesArrow icon="arrow-left-l" />}>
        {items && items.map((item: any, index: number) => getComponent(item, index))}
      </Slider>
    </div>
  );
};

export default FeaturesSlider;