import React, { FC, useState } from 'react';

import BackgroundFluent from '../Backgrounds/BackgroundFluent';
import Image from '../Image';

import { ReactComponent as Background } from '../../assets/svg/bg-lines-lg.svg';

import { options } from './options';

import { IBlockSplit } from './interfaces';

import './block-split.scss';


// block split
const BlockSplit: FC<IBlockSplit> = ({ attributes, relationships }) => {
  const [ animation, setAnimation ] = useState<boolean>(false);
  const {
    field_link_tipo,
    field_link_url,
    field_link_text,
    field_texto: { value },
    field_title } = attributes;
  const { field_imagem: { data: { id } } } = relationships;

  // render
  return (
    <div className="block-split">
      <div className="block-split--background">
        <BackgroundFluent
          active={animation}
          speed={1}
          height={740}
          width={1600}
          options={options}>
          <Background />
        </BackgroundFluent>
      </div>

      <div className="block-split--container">
        <div className="block-split--image">
          <Image id={id} options={{ alt: field_title }} />
        </div>

        <div className="block-split--info">
          <div className="block-split--info--text">
            <p className="title">{field_title}</p>
            <div className="description" dangerouslySetInnerHTML={{ __html: value }} />
          </div>

          {field_link_url && <a
              className="btn-border"
              href={field_link_url}
              target={field_link_tipo === '' ? '_blank' : ''}
              onMouseOver={() => setAnimation(!animation)}>
              <span>{field_link_text}</span>
              <span className="icon icon-arrow-right-min"></span>
            </a>}
        </div>
      </div>
    </div>
  );
};

export default BlockSplit;