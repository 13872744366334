import React, { FC } from 'react';
import { Parallax } from 'react-scroll-parallax';

import Image from '../Image';

import { IBlockImageFull } from './interfaces';

import './block-image-full.scss';

// block image full
const BlockImageFull: FC<IBlockImageFull> = ({ attributes, relationships }) => {
  const { field_title, field_link_tipo, field_link_url, field_texto: { value } } = attributes;
  const { field_imagem: { data: { id } } } = relationships;

  // render
  return (
    <div className="block-image-full">
      <div className="block-image-full--image">
        <Parallax translateY={[-10, 0]}>
          <Image id={id} options={{ alt: field_title }} />
        </Parallax>
      </div>

      <div className="block-image-full--info">
        <p className="title">{field_title}</p>
        <div className="description" dangerouslySetInnerHTML={{ __html: value }} />

        {field_link_url && <a
          className="link-item"
          href={field_link_url}
          target={field_link_tipo !== 'interno' ? '_blank' : 'self'}
          rel="noopener noreferrer">
          <span className="icon icon-arrow-right-min"></span>
        </a>}
      </div>
    </div>
  );
};

export default BlockImageFull;