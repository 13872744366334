import React, { FC } from 'react';

import BlockPrintInfo from './BlockPrintInfo';
import Image from '../Image';

import { IBlockPrint } from './interfaces';

import './block-print.scss';

// block print
const BlockPrint: FC<IBlockPrint> = ({ lang, attributes, relationships }) => {
  const { field_title } = attributes;
  const { field_assessoria_item: { data }, field_imagem: { data: { id } }  } = relationships;

  // render
  return (
    <div className="block-print">
      <div className="block-print--container">
        <div className="block-print--image">
          <Image id={id} options={{ alt: field_title }} />
        </div>

        <BlockPrintInfo
          lang={lang}
          items={data}
          title={field_title} />
      </div>
    </div>
  );
};

export default BlockPrint;