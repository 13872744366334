import React, { FC, useCallback } from 'react';
import { useResponsive } from 'react-hooks-responsive';

import TimeLineList from './TimeLineList';
import withLoadComponentDataUrl, {correctImageUrl} from '../Load/LoadComponentDataUrl';

import { ITimeLine } from './interfaces';
import { ITimeLineItem } from './TimeLineItem/interfaces';

import './timeline.scss';

const breakpoints = { xs: 0, sm: 767, md: 1023, lg: 1140 };

// time line
const TimeLineComponent: FC<ITimeLine> = ({ data, items, lang }) => {
  const { size } = useResponsive(breakpoints);

  // get home
  const getHome = useCallback((items: ITimeLineItem[]) => {
    if (!Array.isArray(items) || !items.length) return false;

    items = items.map( item => {
      return {
        ...item,
        image_1: correctImageUrl(item.image_1),
        image_2: correctImageUrl(item.image_2),
        image_3: correctImageUrl(item.image_3),
      }
    });

    const item: any = { body: '', color: 'home', image_1: '', image_2: '', image_3: '', subtitle: '', title: lang.lang === 'en'? 'Home':'Início', uuid: '' };
    const elements: ITimeLineItem[] = [item, ...items];
    
    return elements;
  }, [lang.lang]);

  // render
  return (
    <div className="timeline-container">
      {items && data instanceof Object &&
        <TimeLineList lang={lang} items={getHome(items)} entity={data} media={size} />}
    </div>
  );
};

export default withLoadComponentDataUrl(TimeLineComponent);