import React, { FC } from 'react';

import TitleText from '../../../components/TitleText';

import { IBlogInfo } from './interfaces';

// blog info
const BlogInfo: FC<IBlogInfo> = ({ attributes }) => {
  const { title } = attributes;
  
  // render
  return (
    <div className="blog-info">
      <TitleText attributes={{ field_title: title, field_title_tag: 'h3' }} id="" />
    </div>
  );
};

export default BlogInfo;