import React, { FC } from 'react';

import Image from '../Image';
import TitleText from '../TitleText';

import { IBlockMap } from './interfaces';

import './block-map.scss';

// blog map
const BlockMap: FC<IBlockMap> = ({ attributes, relationships }) => {
  const { field_subtitle, field_title, field_texto: { value } } = attributes;
  const { field_imagem: { data: { id: desktop }}, field_imagem_mobile: { data: { id: mobile }} } = relationships;
  // render
  return (
    <div className="block-map">
      <TitleText attributes={{ field_title, field_title_tag: 'h2' }} id='' />

      <div className="block-map--content">
        <div className="block-map--container">
          <div className="block-map--info">
            <p className="title">{field_subtitle}</p>
            <div className="description" dangerouslySetInnerHTML={{ __html: value }} />
          </div>
        </div>

        <div className="block-map--image">
          <Image className="desktop" id={desktop} options={{ alt: field_title }} />
          <Image className="mobile" id={mobile ?? desktop} options={{ alt: field_title }} />
        </div>
      </div>
    </div>
  );
};

export default BlockMap;