import React, { FC } from 'react';

import WebdoorContent from './WebdoorContent';
import withLoadComponentDataNode from '../Load/LoadComponentDataNode';

import { IWebdoor } from './interfaces';

import './webdoor.scss';

// webdoor
const Webdoor: FC<IWebdoor> = ({ data }) => {
  // render
  return (
    <div className="webdoor">
      {data &&
        <WebdoorContent
          data={data?.data}
          items={data?.included} />}
    </div>
  );
};

export default withLoadComponentDataNode(Webdoor);