import React, { FC, useCallback } from 'react';
import { Parallax } from 'react-scroll-parallax';
import UseWindowResize from '../../../uses/UseWindowResize';
import LinkExternal from '../../Link/LinkExternal';

import { ICardInfo } from './interfaces';

import './card-info.scss';

// card info
const CardInfo: FC<ICardInfo> = ({ title, link, description }) => {
  // render
  const {width} = UseWindowResize();
  // const checkIsMobile =() => window.matchMedia('(max-width: 768px)').matches
  const checkIsMobile = useCallback(() => {
    return width <= 768 ? true : false;
  }, [width]);

  return (
    <Parallax className="card-info" translateY={[50, -40]} disabled={checkIsMobile()} shouldAlwaysCompleteAnimation>
      <h2 className="title-main">{title}</h2>
      <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
      
      {link.url && 
        <LinkExternal
          className="link-item"
          href={link.url}
          target={link.type}>
          <span className="icon icon-arrow-right-min"></span>
        </LinkExternal>}
    </Parallax>
  );
};

export default CardInfo;