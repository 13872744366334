import React, { FC, useContext } from 'react';
import { ShipsContext } from '../../../providers/ShipsProvider';
import { IShipsContext } from '../../../providers/ShipsProvider/interfaces';

import ShipsGallery from '../ShipsGallery';

import { IShipsContainer } from './interfaces';

// ships container
const ShipsContainer: FC<IShipsContainer> = ({ lang }) => {
  const shipsContext = useContext(ShipsContext);
  const { ships }: IShipsContext = shipsContext;

  // render
  return (
    <div className="ships--container">
      {ships && ships.map(({ name, uuid, tipo }: any, index: number) =>
        <ShipsGallery
          attributes={{
            field_categoriaembarcacao_tipo: tipo,
            name,
          }}
          id={uuid}
          lang={lang}
          node={`embarcacao?filter[langcode]=${lang?.lang}&filter[categoria][path]=field_categoria_embarcacao.id&sort=-sticky,title&filter[categoria][value]=${uuid}`}
          key={index} />)}
    </div>
  );
};

export default ShipsContainer;