import React, { FC } from 'react';

import GalleryModal from './GalleryModal';
import { withMediaQueries } from '../MediaQueries';

import { IGallery } from './interfaces';

import './gallery.scss';

// gallery
const Gallery: FC<IGallery> = ({ relationships, queries }) => {
  const { field_galeria_imagem: { data } } = relationships;

  // render
  return (
    <div className="gallery">
      <div className="gallery--content">
        {data && <GalleryModal
          items={data}
          queries={queries} />}
      </div>
    </div>
  );
};

export default withMediaQueries(Gallery);