import React, { useCallback, useRef, useState, useEffect, FC } from 'react';

import SelectItem from './SelectItem';
import SelectTitle from './SelectTitle';

import { ISelect } from './interfaces';

import './select.scss';

// select
const Select: FC<ISelect> = ({ defaultText, handleChange, error, options, selectedOption, name, validate }) => {
  const [ active, setActive ] = useState<boolean>(false);

  // element
  const element = useRef<any>(null);

  // check element
  const checkElement = useCallback(event => {
    if (element.current instanceof Object && event instanceof Object) {
      const { x, y } = event;
      const { height, left, top, width } = element.current.getBoundingClientRect();

      const maxHeight = height + top;
      const maxWidth = left + width;

      if ((x < left || x > maxWidth) || (y < top || y > maxHeight)) {
        setActive(false);
      }
    }
  }, [ setActive ]);

  // on change
  const onChange = useCallback(item => {
    setActive(false);
    handleChange(item);
  }, [ handleChange, setActive ]);

  // on toggle
  const onToggle = useCallback((active) => {
    setActive(active);
  }, [ setActive ]);

  // use effect
  useEffect(() => {
    if (active === true) {
      window.addEventListener('click', checkElement);
    } else {
      window.removeEventListener('click', checkElement);
    }

    return () => {
      window.removeEventListener('click', checkElement);
    };
  }, [ active, checkElement ]);

  // render
  return (
    <div
      className="select-control"
      data-active={selectedOption.value !== ''}
      data-error={!!error}>
      <div className="select" data-active={active} ref={element}>
        <input type="hidden" name={name} value={selectedOption.value} />
        <SelectTitle active={active} defaultText={defaultText} onToggle={onToggle} selectedOption={selectedOption} />

        {options && <ul className="select--list">
          {options.map((item: any, index: number) =>
            <li className="select--list--item" key={index}>
              <SelectItem item={item} onSelectOption={onChange} selectedOption={selectedOption} />
            </li>)}
        </ul>}
      </div>
      {error && <label className="error">{error}</label>}
    </div>
  );
};

export default Select;