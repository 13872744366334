import React from 'react';
import axios from 'axios';
import useSWR from 'swr';

// env
const { REACT_APP_URL_BASE } = process.env;

// api
const api = (url: string) => axios.get(url).then(({ data }) => data);

// load component data
const withLoadComponentDataUrl = (ComponentElement: any) => {
  // render
  return (props: any) => {
    const { data, error } = useSWR<[any]>(`${REACT_APP_URL_BASE}${props.url}`, api);

    // render
    return(
      <>
        <ComponentElement data={data} error={error} {...props} />
      </>);
  };
};

const correctImageUrl = (url:string) => {
  if (url.indexOf('//') === -1) return `${REACT_APP_URL_BASE}${url}`;
  return url;
}
export {correctImageUrl};
export default withLoadComponentDataUrl;