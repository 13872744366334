import React, { FC } from 'react';

import { IShipsCardValue } from './interfaces';

import './ships-card-value.scss';

// ships card value
const ShipsCardValue: FC<IShipsCardValue> = ({ icon, value }) => {
  // render
  return (
    <label className="ships-card-value">
      <span className={`icon icon-${icon}`}></span>
      <span className="text">{value}</span>
    </label>
  );
};

export default ShipsCardValue;