import React, { FC } from 'react';

import { ISelectMinItem } from './interfaces';

import './select-min-item.scss';

// select item
const SelectMinItem: FC<ISelectMinItem> = ({ item, onSelectOption, selectedOption }) => {
  const { label } = item;

  // render
  return (
    <div
      className="select-min-item"
      onClick={() => onSelectOption(item)}
      data-active={selectedOption instanceof Object && selectedOption === item}>
      <p className="text">{label}</p>
    </div>
  );
};

export default SelectMinItem;
