import React, { FC } from 'react';

import { IGalleryModalInfo } from './interfaces';

import './gallery-modal-info.scss';

// gallery modal info
const GalleryModalInfo: FC<IGalleryModalInfo> = ({ item, onCloseOpen }) => {
  // render
  return (
    <div className="gallery-modal-info">
      <button
        className="btn-item"
        onClick={() => onCloseOpen()}>
        <span className="icon icon-arrow-left-min"></span>
      </button>

      {item.meta.title &&
        <p className="text">{item.meta.title}</p>}
    </div>
  );
};

export default GalleryModalInfo;