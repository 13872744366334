import React, { FC } from 'react';
import { useTransition, animated as a } from 'react-spring';

import HeaderItem from '../HeaderItem';
import HeaderTopMobile from '../HeaderTopMobile';
import withLoadComponentDataUrl from '../../../components/Load/LoadComponentDataUrl';

import { IHeaderContainer } from './interfaces';

import './header-container.scss';

// header container
const HeaderContainer: FC<IHeaderContainer> = ({ data, items, lang, open, mediaQuery }) => {
  // transition mobile
  const transitions: any = useTransition(open, null, {
    from: { left: '-100%' },
    enter: { left: '0%' },
    leave: { left: '0%' },
  });

  // return
  return transitions.map(({ key, props }: any) => {
    if (mediaQuery !== 'lg' && open === true) {
      return (
        <a.div className="header-menu-container" style={props} key={key}>
          {items && items.map((item: any, index: number) =>
            <HeaderItem
              {...item}
              background={data && data.filter((submenu: any) => item.uuid === submenu.uuid)[0]}
              index={index}
              mediaQuery={mediaQuery}
              key={index} />)}

          <HeaderTopMobile
            lang={lang}
            open={open}
            url={`/${lang.lang}/api/menu_items/top${lang.lang === 'pt-br' ? '':'-'+lang.lang}`} />
        </a.div>
      );
    }

    return <div className="header-menu-container" key={key}>
      {items && items.map((item: any, index: number) =>
        <HeaderItem
          {...item}
          background={data && data.filter((submenu: any) => item.uuid === submenu.uuid)[0]}
          index={index}
          mediaQuery={mediaQuery}
          key={index} />)}
    </div>;
  });
};

export default withLoadComponentDataUrl(HeaderContainer);