import React, { FC, useState } from 'react';

import CardInfo from './CardInfo';
import CardImage from './CardImage';

import { ICard } from './interfaces';

import './card.scss';

// card info
const Card: FC<ICard> = ({ attributes, relationships }) => {
  const [ animation, setAnimation ] = useState<boolean>(false);

  const {
    field_texto: { value },
    field_title,
    field_block_corfundo,
    field_orientacao,
    field_link_tipo,
    field_link_url
  } = attributes;

  const { field_galeria_imagem: { data } } = relationships;
  // render
  return (
    <div
      className="card"
      data-type={field_block_corfundo}
      data-align={field_orientacao}>
      <div className="card-container">
        <CardImage
          animation={animation}
          setAnimation={setAnimation}
          items={data}
          type={field_block_corfundo} />

        <CardInfo
          description={value}
          link={{ 
            url: field_link_url,
            type: field_link_tipo,
          }}
          title={field_title} />
      </div>
    </div>
  );
};

export default Card;