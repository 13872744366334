import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Image from '../../Image';

import { ISocialMediaItem } from './interfaces';

import './social-media-item.scss';

// social media item
const SocialMediaItem: FC<ISocialMediaItem> = ({ attributes, relationships, image_style, consumerId }) => {
  // get date - hotfix dnf date
  const getDate = useCallback((value: string) => 
    value.split('-').reverse().join('.'), []);
  const getLink = useCallback(() => `/${attributes.path.langcode}${attributes.path.alias}`, [attributes]);

  // render
  return (
    <div className="social-media-item">
      <div className="social-media-item--title">
        <p className="title"><Link to={getLink()}>{attributes?.title}</Link></p>

        {relationships.field_image?.data && <Link to={getLink()}>
          <Image
            id={relationships.field_image?.data.id}
            alt={relationships.field_image?.data.meta.alt ?? attributes?.title}
            title={relationships.field_image?.data.meta.title ?? attributes?.title}
            consumerId={consumerId}
            crop={image_style}
            />
          </Link>}
      </div>
      <div className="social-media-item--content">
        {attributes.field_date && <p className="date"><Link to={getLink()}>{getDate(attributes.field_date)}</Link></p>}
        {attributes?.field_resumo && <div className="description" dangerouslySetInnerHTML={{ __html: '' }} />}
        {!attributes.field_resumo && attributes?.body && <div className="description" dangerouslySetInnerHTML={{ __html: attributes?.body.value }} />}
      </div>
    </div>
  );
};

export default SocialMediaItem;