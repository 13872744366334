import React, { FC } from 'react';

import { IWebdoorItemImage } from '../WebdoorItem/interfaces';
import { IWebdoorControls } from './interfaces';

import './webdoor-controls.scss';

// webdoor controls
const WebdoorControls: FC<IWebdoorControls> = ({ current, items, setCurrent }) => {
  // render
  return (
    <div className="webdoor-controls">
      <ul className="webdoor-controls--list">
        {items && items.map((item: IWebdoorItemImage, index: number) =>
          <li className="webdoor-controls--item" key={index}>
            <button
              aria-label="webdoor control"
              className="btn-circle"
              data-active={current === index}
              onClick={() => setCurrent(index)}></button>
          </li>)}
      </ul>
    </div>
  );
};

export default WebdoorControls;