import React, { FC, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import HeaderSubmenu from '../HeaderSubmenu';

import { IHeaderItem } from './interfaces';

import './header-item.scss';

// header item
const HeaderItem: FC<IHeaderItem> = ({ relative, background, below, title, mediaQuery }) => {
  const location = useLocation();
  const [ toggle, setToggle ] = useState(false);
  const isActive = useCallback(
    (relative, below) => {
      return relative === location.pathname || below?.some( (item:any) => item.relative === location.pathname);
    },
    [location]);
  const navigate = useCallback((event) => {
      if (below?.length && (mediaQuery === 'md' || mediaQuery === 'sm' || mediaQuery === 'xs')) {
        event.preventDefault();
        event.stopPropagation();
        setToggle(!toggle)
      }
    },
    [mediaQuery, toggle, below]
  );
  
  // render
  return (
    <div className="header-item">
      <Link
        aria-label={title}
        className={"link " + (isActive(relative, below) ? 'active':'')}
        onClick={navigate}
        data-open={toggle}
        to={relative}>{title}</Link>

      {below &&
        <HeaderSubmenu
          active={toggle}
          background={background}
          items={below} />}

      {below &&
        <button
          aria-label="icon-arrow"
          className="link-toggle"
          data-active={toggle}
          onClick={() => setToggle(!toggle)}>
          <span className="icon icon-arrow-right"></span>
        </button>}
    </div>
  );
};

export default HeaderItem;