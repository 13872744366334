import React, { FC, useState } from 'react';
import { createPortal } from 'react-dom';

import SearchContent from './SearchContent';

import { ISearch } from './interfaces';

import './search.scss';

// modal
const modal: any = document.getElementById('search');

// search
const Search: FC<ISearch> = ({ lang }) => {
  const [ open, setOpen ] = useState<boolean>(false);

  // render
  return (
    <div className="search">
      <button className="search-btn" onClick={() => setOpen(true)}>
        <span className="icon icon-search"></span>
      </button>

      {open && createPortal(<SearchContent
        lang={lang}
        open={open}
        setOpen={setOpen} />, modal)}
    </div>
  );
};

export default Search;