import React, { FC } from 'react';

import { ILinkExternal } from './interfaces';

// link
const LinkExternal: FC<ILinkExternal> = (props) => {
  const { children, target } = props;

  // render
  return (
    <a
      {...props}
      target={target === 'interno' ? '' : '_blank'}>
      {children}
    </a>
  );
};

export default LinkExternal;