import React, { FC } from 'react';
import { useDrag } from 'react-use-gesture';

import GallerySliderItem from './GallerySliderItem';

import { IGallerySlider } from './interfaces';

import './gallery-slider.scss';

const { REACT_APP_URL_FILE, REACT_APP_GALLERY_CONSUMER_ID } = process.env;

// gallery slider
const GallerySlider: FC<IGallerySlider> = ({
  current,
  direction,
  items,
  onPrevNext,
  size,
  last
 }) => {
  // drag
  
  const drag = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }: any) => {
    if (down && distance > 400) {
      const dir = xDir > 0 ? 'prev' : 'next';
      cancel(onPrevNext(dir));
    }
  });

  // render
  return (
    <div
      className="gallery-slider"
      data-direction={direction}
      {...drag()}>

      {items && items.map((item: any, index: number) =>
        <GallerySliderItem 
          key={index}
          current={current === index}
          size={size}
          prev={last === index}
          url={`${REACT_APP_URL_FILE}/${item.id}?consumerId=${REACT_APP_GALLERY_CONSUMER_ID}`}
          item={item} />)}
    </div>
  );
};

export default GallerySlider;