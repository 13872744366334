import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ISearchItem } from './interfaces';

import './search-item.scss';

// search item
const SearchItem: FC<ISearchItem> = ({ description, title, url }) => {
  // render
  return (
    <div className="search-item">
      <Link className="title" to={url}>{title}</Link>
      <div className="description" dangerouslySetInnerHTML={{ __html: description }}/>
    </div>
  );
};

export default SearchItem;