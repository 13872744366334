import axios from 'axios';
// import useSWR from 'swr';

// env
const { REACT_APP_URL_BASE } = process.env;

// api
export const api = (url: string) => axios.get(url).then(({ data }) => data);

export {
    REACT_APP_URL_BASE
}