import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ShipsCardModal from './ShipsCardModal';
import ShipsCardValue from './ShipsCardValue';
import ShipsThumb from '../ShipsThumb';
import { withMediaQueries } from '../../MediaQueries';

import { IShipsCard } from './interfaces';

import './ships-card.scss';

const { REACT_APP_URL_FILE, REACT_APP_SHIP_CONSUMER_ID } = process.env;

// ships card
const ShipsCard: FC<IShipsCard> = ({ attributes, relationships, queries }) => {
  const { t } = useTranslation();

  const {
    field_embarcacao_tag,
    field_embarcacao_area,
    field_embarcacao_caracteristica,
    field_embarcacao_deadweight,
    field_embarcacao_deadload,
    path: {
      alias,
      langcode
    },
    title
  } = attributes;

  const { field_image: { data: { id }} } = relationships;

  // get link
  const getLink = useCallback((alias: string, langcode: string) => alias ? `/${langcode}${alias}` : '/', []);

  // render
  return (
    <div className="ships-card">
      <p className="title">{title}</p>

      <Link className="ships-card--content" to={getLink(alias, langcode)}>
        <div className="ships-card--image">
          <ShipsThumb
            id={id}
            url={`${REACT_APP_URL_FILE}/${id}?consumerId=${REACT_APP_SHIP_CONSUMER_ID}`}
            type={1} />
        </div>

        {(queries === 'md' || queries === 'lg') &&
          <div className="ships-card--info">
            <ShipsCardValue icon="area" value={field_embarcacao_area} />
            <ShipsCardValue icon="kg" value={field_embarcacao_deadweight} />
            <ShipsCardValue icon="weight" value={field_embarcacao_deadload} />
          </div>}
        
        {(queries === 'xs' || queries === 'sm') &&
          <ShipsCardModal
            langcode={langcode}
            tags={field_embarcacao_tag}
            list={field_embarcacao_caracteristica}
            area={field_embarcacao_area}
            deadweight={field_embarcacao_deadweight}
            deadload={field_embarcacao_deadload}></ShipsCardModal>}
      </Link>

      {queries === 'lg' &&
        <ShipsCardModal
          langcode={langcode}
          tags={field_embarcacao_tag}
          list={field_embarcacao_caracteristica}
          area={field_embarcacao_area}
          deadweight={field_embarcacao_deadweight}
          deadload={field_embarcacao_deadload}>
          <div className="ships-card--link">
            <Link className="btn-border" to={getLink(alias, langcode)}>{t('see_more_details')}</Link>
          </div>
        </ShipsCardModal>}
    </div>
  );
};

export default withMediaQueries(ShipsCard);