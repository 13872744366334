import React, { FC } from 'react';

import { IListSimple, IListSimpleItem } from './interfaces';

import './list-simple.scss';

// list simple
const ListSimple: FC<IListSimple> = ({ attributes }) => {
  const { field_lista_item } = attributes;

  // render
  return (
    <div className="list-simple">
      <ul className="list-simple--list">
        {field_lista_item && field_lista_item.map(({ value }: IListSimpleItem, index: number) =>
          <li
            className="list-simple--item"
            dangerouslySetInnerHTML={{ __html: value }}
            key={index} />)}
      </ul>
    </div>
  );
};

export default ListSimple;