import React, { FC } from 'react';

import ShipsCardModalProp from './ShipsCardModalProp';
import ShipsCardModalList from './ShipsCardModalList';

import { IShipsCardModal } from './interfaces';

import './ships-card-modal.scss';

// ships card toggle
const ShipsCardModal: FC<IShipsCardModal> = ({ area, children, deadload, deadweight, list, tags, langcode }) => {
  // render
  return (
    <div className="ships-card-modal">
      <ul className="ships-card-modal--tags">
        {tags && tags.map((value: string, index: number) =>
          <li className="ships-card-modal--tags--item" key={index}><p className="tag">{value}</p></li>)}
      </ul>

      <ShipsCardModalProp
        items={[
          {
            icon: 'area',
            label: langcode === 'en' ? 'Area' : 'Área',
            value: area
          },
          {
            icon: 'kg',
            label: langcode === 'en' ? 'Deadweight' : 'Tonelagem Porte Bruto',
            value:  deadweight
          },
          {
            icon: 'weight',
            label: 'DP',
            value: deadload
          }
        ]} />
      
      <ShipsCardModalList items={list} />

      <div className="ships-card-modal--footer">
        {children}
      </div>
    </div>
  );
};

export default ShipsCardModal;