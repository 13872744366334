import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SearchItem from '../SearchItem';
import SearchPagination from '../SearchPagination';

import { ISearchItem } from '../SearchItem/interfaces';
import { ISearchResults } from './interfaces';

import './search-results.scss';

// env
const { REACT_APP_SEARCH_PAGINATION }: any = process.env;

// search results
const SearchResults: FC<ISearchResults> = ({ lang, items, term }) => {
  const { t }: any = useTranslation();
  const { search }: any = useParams();

  // filter range
  const [ filterArray, setFilterArray ] = useState<any[]>([0, 0]);

  // set filter
  const setFilter = useCallback((init: number, total: number) => {
    const count = Math.ceil(items.length / total);
    const ini = Math.ceil(count * (init - 1));
    const end = parseInt((ini + count).toString(), 10);

    setFilterArray([ ini, end ]);
  }, [ items, setFilterArray ]);

  // get items
  const getItems = useCallback(() => {
    if (items.length > 0) {
      const init = parseInt(filterArray[0].toString(), 10);
      const end = parseInt(filterArray[1].toString(), 10);

      return items.map((item: ISearchItem, index: number) => {
        if (index >= init && index < end) {
          return <li
            className="search-results--item"
            key={index}
            style={{ animationDelay: `${index * 70}ms` }}>
              <SearchItem {...item} />
          </li>;
        }

        return false;
      });
    }

    return [];
  }, [ items, filterArray ]);

  // use effect
  useEffect(() => {
    if (items.length > 0) {
      setFilterArray([0, REACT_APP_SEARCH_PAGINATION]);
    }
  }, [ items ]);

  // results
  return (
    <div className="search-results">
      <p className="search-results--title">
        <span className="text">{t('your_search_by')}</span>
        <span className="term">{search}</span>
      </p>

      {items.length > 0 ? <ul className="search-results--list">
        {items && getItems()}
        </ul> : <p className="search-results--empty">{t('no_result')}</p>}

      {items && items.length > 1 &&
        <SearchPagination
          lang={lang}
          pagination={REACT_APP_SEARCH_PAGINATION}
          setFilter={setFilter}
          term={term} />}
    </div>
  );
};

export default SearchResults;