import React, { FC, useEffect, useRef } from 'react';

import BackgroundFluent from '../../Backgrounds/BackgroundFluent';

import { ReactComponent as Background } from '../../../assets/timeline/bg-timeline.svg';

import { ITimeLineBackground } from './interfaces';

import { settings } from './settings';

import './time-line-background.scss';

// time line background
const TimeLineBackground: FC<ITimeLineBackground> = ({ animate, current, color, setAnimate }) => {
  const element = useRef<any>(null);

  // use effect
  useEffect(() => {
    setAnimate(true);
  }, [ current, setAnimate ]);

  // render
  return (
    <div
      className="time-line-background"
      ref={element}
      data-type={color}>
      <BackgroundFluent
          active={animate}
          speed={1}
          height={900}
          width={1600}
          options={settings}
          callback={() => setAnimate(false)}>
            <Background />
        </BackgroundFluent>
    </div>
  );
};

export default TimeLineBackground;