import React, { FC } from 'react';

import BackgroundFluent from '../../Backgrounds/BackgroundFluent';

import { ITabsBackground } from './interfaces';

import { ReactComponent as Background } from '../../../assets/svg/bg-highlights-ex.svg';

import './tabs-background.scss';

// options
const options = {
  from: { scale: 30, transform: 'scale(1)', freq: '0.008, 0' },
  to: { scale: 70, transform: 'scale(1)', freq: '0.0, 0.0' },
  config: { duration: 10000 }
};

// highlights
const TabsBackground: FC<ITabsBackground> = ({ animation, setAnimation }) => {
  // render
  return (
    <div className="tabs-background">
      <BackgroundFluent
        active={animation}
        speed={1}
        height={740}
        width={1600}
        options={options}
        callback={() => setAnimation(false)}>
        <Background />
      </BackgroundFluent>
    </div>
  );
};

export default TabsBackground;
