import React, { FC } from 'react';

import withLoadComponentDataUrl from '../../../Load/LoadComponentDataUrl';
import ListGroupItem from '../ListGroupItem';

import { IListGroupContent } from './interfaces';

import './list-group-content.scss';

// list group content
const ListGroupContent: FC<IListGroupContent> = ({ data }) => {
  return (
    <div className="list-group-content">
      {data && data?.included.map((item: any, index: number) =>
        <ListGroupItem {...item} key={index} />)}
    </div>
  );
};

export default withLoadComponentDataUrl(ListGroupContent);