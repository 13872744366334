import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import Image from '../../Image';

import { ITabsItem } from './interfaces';

import './tabs-item.scss';

// tabs item
const TabsItem: FC<ITabsItem> = ({ attributes, relationships }) => {
  const { field_embarcacao_categoria, field_text: { value }, field_title, field_link_url, field_link_text } = attributes;
  const { field_imagem: { data } }= relationships;

  // render
  return (
    <div className="tabs-item">
      <div className="tabs-item--image">
        <Image id={data.id} title={data.meta?.title} alt={data.meta?.alt} consumerId="7e2f2a76-190f-41c0-8f51-b699abbaa922" crop="thumb_436_353" />
      </div>

      <div className="tabs-item--info">
        <p className="subtitle">{field_embarcacao_categoria}</p>
        <p className="title">{field_title}</p>
        <p className="description" dangerouslySetInnerHTML={{ __html: value }} />

        {field_link_url && 
          <Link className="link-item" to={field_link_url}>
            {field_link_text ?
              <span className="text">{field_link_text}</span> : <span className="icon icon-arrow-right-min"></span>}
          </Link>}
      </div>
    </div>
  );
};

export default TabsItem;