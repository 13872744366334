import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import SelectMin from '../../../Forms/Form/SelectMin';

import './ships-filters-category-item.scss';

// ships filters category item
const ShipsFiltersCategoryItem: FC<any> = ({
  name,
  active,
  options,
  onChange,
  defaultText,
  selectedOption
}) => {
  const { t } = useTranslation();

  // render
  return (
    <div className="ships-filters-category-item">
      <SelectMin
        defaultText={t(defaultText)}
        active={true}
        disable={false}
        name={name}
        options={options}
        handleChange={onChange}
        selectedOption={selectedOption} />
    </div>
  );
};

export default memo(ShipsFiltersCategoryItem);