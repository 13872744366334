import React, { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonDownload from '../../../ButtonDownload';
import Image from '../../../Image';
import ShipsBackground from '../../ShipsBackground';
import ShipsCardModal from '../../ShipsCard/ShipsCardModal';

import { ShipsContext } from '../../../../providers/ShipsProvider';

import { IShipsContext } from '../../../../providers/ShipsProvider/interfaces';
import { IShipsItemInfo } from './interfaces';

import './ships-item-info.scss';

// ships item info
const ShipsItemInfo: FC<IShipsItemInfo> = ({ attributes, specification, relationships, lang }) => {
  const { t } = useTranslation();

  const {
    field_embarcacao_area,
    field_embarcacao_caracteristica,
    field_embarcacao_deadweight,
    field_embarcacao_deadload,
    field_embarcacao_link_saibamais,
    field_embarcacao_tag,
    title
  } = attributes;
  
  const {
    field_image: {
      data: {
        id,
      },
    }
  } = relationships;

  const shipsContext = useContext<IShipsContext>(ShipsContext);
  const { current } = shipsContext;

  // render
  return (
    <div className="ships-item-info">
      <div className="ships-item-info--title">
        <h1 className="title">{title}</h1>
      </div>
      
      <div className="ships-item-info--image">
        {current && <ShipsBackground name={current.name} type={current.tipo} />}
        <Image id={id} />
      </div>

      <div className="ships-item-info--attr">
        <ShipsCardModal
          langcode={lang.lang}
          tags={field_embarcacao_tag}
          list={field_embarcacao_caracteristica}
          area={field_embarcacao_area}
          deadweight={field_embarcacao_deadweight}
          deadload={field_embarcacao_deadload}>
          <div className="ships-item-info--attr--footer">
            {field_embarcacao_link_saibamais &&
              <a className="btn btn-border" href={field_embarcacao_link_saibamais}>{t('contract')}</a>}

            {specification instanceof Object && <ButtonDownload attributes={{
              field_botao_icone: 'pdf',
              field_link_url: specification[0].attributes.uri.url,
              field_link_text: t('specification'),
            }} id="1" />}
          </div>
        </ShipsCardModal>
      </div>
    </div>
  );
};

export default memo(ShipsItemInfo);