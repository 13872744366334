import React, { FC, useState, memo, useRef } from 'react';

import BackgroundFluent from '../../Backgrounds/BackgroundFluent';
import UseDimension from '../../../uses/UseDimension';

import { IShipsBackground } from './interfaces';

import { ReactComponent as Background } from '../../../assets/svg/bg-highlights-ex.svg';

import './ships-tabs-background.scss';

// options
const options = {
  from: { scale: 30, transform: 'scale(1)', freq: '0.008, 0' },
  to: { scale: 70, transform: 'scale(1)', freq: '0.0, 0.0' },
  config: { duration: 10000 }
};    

// ships tabs background
const ShipsBackground: FC<IShipsBackground> = ({ name, type, min }) => {
  const [ animate, setAnimate ] = useState<boolean>(false);

  const element = useRef<any>(null);
  const { width } = UseDimension(element);

  // render
  return (
    <div
      className="ships-tabs-background"
      ref={element}
      data-min={min}
      data-type={type}
      onMouseOver={() => setAnimate(true)}>
      <BackgroundFluent
        active={animate}
        speed={1}
        height={355}
        width={width}
        options={options}
        callback={() => setAnimate(false)}>
          <Background />
        </BackgroundFluent>
      <p className="title">{name}</p>
    </div>
  );
};

export default memo(ShipsBackground);