import React, { FC } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import Footer, {gerFooterUrl} from '../../layouts/Footer';
import Header from '../../layouts/Header';
import SearchResults from '../../components/Search/SearchResults';
import WebdoorInterna from '../../components/WebdoorInterna';

import { ISearch } from './interfaces';

const { REACT_APP_URL_BASE } = process.env;

// api
const api = (url: string) => axios.get(url).then(({ data }) => data);

// search
const Search: FC<ISearch> = ({ location, lang, queries }) => {
  const { search }: string = useParams();
  const { data }: any = useSWR<[any]>(`${REACT_APP_URL_BASE}/${lang.lang}/api/search?search=${search}`, api);

  // render
  return (
    <>
      <Header
        location={location}
        lang={lang}
        mediaQuery={queries} />

      <div className="interna">
        <WebdoorInterna />

        {data &&
          <SearchResults
            items={data}
            lang={lang}
            term={search.toString()} />}
      </div>

      <Footer
        url={gerFooterUrl(lang)}
        location={location}
        lang={lang}
        mediaQuery={queries} />
    </>
  );
};

export default Search;