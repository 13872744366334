import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IHeaderSubmenu } from './interfaces';

import './header-submenu.scss';
const { REACT_APP_URL_BASE } = process.env;
// header submenu
const HeaderSubmenu: FC<IHeaderSubmenu> = ({ background, active, items }) => {
  function ajustaUrl(url:string) {
    return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1 ? url : REACT_APP_URL_BASE + url;
  }
  // render
  return (
    <div className="header-submenu" data-active={active}>
      <ul className="header-submenu--list">
        {items && items.map((item, index) =>
          <li className="header-submenu--item" key={index}>
            <NavLink className="header-submenu--link" to={item.relative}>
              {item.title}
              <span className="icon icon-arrow-right"></span>
            </NavLink>
          </li>)}
        </ul>

      {background && <img
        alt={background.title}
        className="header-submenu--background"
        src={`${ajustaUrl(background.image)}`} />}
    </div>
  );
};

export default HeaderSubmenu;