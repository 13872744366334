import React, { FC, useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Content from '../Content';
import CookieAlert from '../../components/CookieAlert';

import { LangContext } from '../../providers/LangProvider';
import { MainContext } from '../../providers/MainProvider';

import { IMainContext } from '../../providers/MainProvider/interfaces';
import { ILangContext } from '../../providers/LangProvider/interfaces';
import { IMain } from './interfaces';

import '../../styles/main.scss';

// main
const Main: FC<IMain> = ({ location }) => {
  const langContext: ILangContext = useContext(LangContext);
  const mainContext: IMainContext = useContext(MainContext);

  const { lang, setLang }: any = langContext;
  const { getType } = mainContext;

  // main
  return (
    <>
      {lang instanceof Object && <Content
        location={location}
        lang={lang}
        getType={getType}
        setLang={setLang} />}
        <CookieAlert />
    </>
  );
};

export default withRouter(Main);