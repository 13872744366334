import React, { FC, useState } from 'react';

import BlockShipImage from './BlockShipImage';
import BlockShipInfo from './BlockShipInfo';

import { IBlockShip } from './interfaces';

import './block-ship.scss';

// block ship
const BlockShip: FC<IBlockShip> = ({ attributes, relationships }) => {
  const { field_block4_tipo, field_subtitle, field_orientacao, field_title } = attributes;
  const [ animate, setAnimate ] = useState<boolean>(false);

  // render
  return (
    <div
      className="block-ship"
      data-orientation={field_orientacao}
      onMouseOver={() => setAnimate(true)}>
      <div className="block-ship--container">
        <BlockShipImage
          {...relationships}
          animate={animate}
          type={field_block4_tipo}
          subtitle={field_subtitle}
          title={field_title}
          setAnimate={setAnimate} />

        <BlockShipInfo {...attributes} />
      </div>
    </div>
  );
};

export default BlockShip;