import React, { FC, useState } from 'react';

import CardCollapseInfo from './CardCollapseInfo';
import CardCollapseImage from './CardCollapseImage';

import { ICardCollapse } from './interface';

import './card-collapse.scss';

// card collapse
const CardCollapse: FC<ICardCollapse> = ({ attributes, relationships }) => {
  const [ collapse, setCollapse ] = useState<boolean>(false);

  // render
  return (
    <div className="card-collapse" data-collapse={collapse}>
      <div className="card-collapse--container">
        <CardCollapseImage
          {...relationships.field_imagem.data} />
        
        <CardCollapseInfo
          {...attributes}
          collapse={collapse}
          setCollapse={setCollapse} />
      </div>
    </div>
  );
};

export default CardCollapse;