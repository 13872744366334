import React, { FC, memo, useEffect, useState } from 'react';
import axios from 'axios';

import { IImage, IImageData, IImageDataEntity } from './interfaces';

import './image.scss';

const { REACT_APP_URL_BASE, REACT_APP_URL_FILE } = process.env;

// image
const Image: FC<IImage> = ({ id, options, consumerId, crop, fallback, onload, className, lazyload, alt, title }) => {
  // state
  const [ data, setData ] = useState<IImageData>();
  const [ loaded, setLoaded ] = useState<boolean>(false);

  // loaded
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const getImage = async (id: string, consumerId: any, crop: any) => {
      const url = `${REACT_APP_URL_BASE}${REACT_APP_URL_FILE}/${id}?${consumerId ? 'consumerId='+consumerId:''}`;

      return await axios.get(url, { cancelToken: source.token })
        .then(({ data: { data } }) => {
          const { attributes: { filename, uri: { url }}, links}: IImageDataEntity = data;
          if (lazyload && links[lazyload]) {
            const img = document.createElement('img');
            img.src = links[lazyload].href;
            img.onload = function() {
              setData({
                ...data,
                src: links[lazyload].href,
              })
            }
          }
          setData({ src: crop && crop in links ? links[crop].href : url, alt: filename })
        })
        .catch((error:any) => {
          if (fallback)
            setData({ src: fallback, alt: '' })
        });
    };
    if (id)
      getImage(id, consumerId, crop);
    else if (fallback)
      setData({ src: fallback, alt: '' })
    return () => source.cancel()
  }, [ id, consumerId, crop, fallback, lazyload ]);

  // render
  return (
    <>
      {data instanceof Object &&
        <img
          alt={alt ?? data.alt}
          title={title ?? data.alt}
          src={data.src}
          {...options}
          className={"image " + className}
          onLoad={(event) => {
            setLoaded(true);
            if (onload) onload(event.target)
          }}
          data-loading={loaded} />}
    </>
  );
};
Image.defaultProps = {
  onload: function(){},
  className: '',
}

export default memo(Image);