import React, { FC, memo, useState, useEffect, useRef, useCallback, useMemo } from 'react';

import { animated as a } from "react-spring";

import { ICardCollapseInfo } from './interfaces';

import './card-collapse-info.scss';

// card collapse info
const CardCollapseInfo: FC<ICardCollapseInfo> = ({
  collapse,
  field_texto,
  field_title,
  setCollapse
}) => {
  const [ defaultHeight, setDefaultHeight ] = useState<number>(0);
  const [ contentHeight, setContentHeight ]: any = useState<number>(0);

  // ref
  const element = useRef<any>(null);
  const content = useRef<any>(null);

  // expand
  const expand = useMemo(() => {
    return {
      height: collapse ? `${contentHeight}px` : `${defaultHeight}px`
    }
  }, [contentHeight, collapse, defaultHeight]);

  // checked paragraph
  const checkedParagraph = useCallback(() => {
    if (element.current instanceof Object === false) return false;
    
    const text = element.current.querySelectorAll('.description p');

    return text.length > 1 ? true : false;
  }, [ element ]);

  // use effect
  useEffect(() => {
    const collapseHeight = content?.current?.getBoundingClientRect().height || 0;
    if (element.current instanceof Object && defaultHeight === 0) {
      setContentHeight(collapseHeight);
      const text = element.current.querySelectorAll('.description p');
      
      if (text instanceof Object) {
        const { height } = text[0].getBoundingClientRect();
        setDefaultHeight(height);
      }
    } else {
      window.addEventListener('resize', setContentHeight(collapseHeight));
    }

    // clean-up
    return window.removeEventListener('resize', setContentHeight(collapseHeight));
  }, [ content, defaultHeight ]);

  // render
  return (
    <div
      ref={element}
      className="card-collapse-info">
      <div className="card-collapse-info--body">
        <h1 className="title-text">{field_title}</h1>

        <a.div
          className="card-collapse-info--body-content"
          style={expand}>
          <div
            ref={content}
            className="description"
            dangerouslySetInnerHTML={{ __html: field_texto.value }} />
        </a.div>
      </div>

      <div className="card-collapse-info--footer" data-active={checkedParagraph()}>
        <button
          className="btn-collapse"
          data-active={collapse}
          onClick={() => setCollapse(!collapse)}>
          <span className="icon icon-arrow-left"></span>
        </button>
      </div>
    </div>
  );
};

export default memo(CardCollapseInfo);