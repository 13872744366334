import React, { createContext, FC, useCallback, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import { IShipsContext, IShipsItem, IShipsProvider } from './interfaces';
import {LangContext} from '../LangProvider/index'
import {ILangContext} from '../LangProvider/interfaces'
import { useLocation } from 'react-router-dom';

// env
const { REACT_APP_URL_BASE } = process.env;

// api
const api = (url: string) => axios.get(url).then(({ data }) => data);

// page context
const ShipsContext = createContext({} as IShipsContext);

// page provider
const ShipsProvider: FC<IShipsProvider> = ({ children }) => {
  const location = useLocation();
  const langContext: ILangContext = useContext(LangContext);
  const { lang }: any = langContext;
  const { data }: any = useSWR<[any]>(`${REACT_APP_URL_BASE}/${lang.lang}/jsonapi/categoria/embarcacao?_format=json`, api);
  const [ current, setCurrent ] = useState<IShipsItem | any>(null);
  const [ indexTab, setIndexTab ] = useState<number>(0);

  // get item
  const getItem = useCallback((id: string, ships: any[]) => {
    if (ships instanceof Object === false) return false;

    return ships.filter(({ uuid }: any) => uuid === id)[0];
  }, []);

  // set init
  const init = useCallback((id: string, ships: IShipsItem[]) => {
    const item = getItem(id, ships);

    if (item instanceof Object) {
      setCurrent(item);
    }
  }, [ setCurrent, getItem ]);

  // use effect
  useEffect(() => {
    if (data instanceof Object) {
      data.filter((item: IShipsItem, index: number) => {
        if (item.url === location.pathname) {
          setIndexTab(index + 1);

          return true;
        }

        return false;
      });
    }
  }, [ data, location ]);

  // render
  return (
    <ShipsContext.Provider value={{
      ships: data,
      setCurrent: init,
      getItem,
      current,
      indexTab,
      setIndexTab
    }}>
      {children}
    </ShipsContext.Provider>
  );
};

export { ShipsContext, ShipsProvider };
export default ShipsProvider;