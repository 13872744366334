import React, { FC } from 'react';

import { IFeaturesArrow } from './interfaces';

import './features-arrow.scss';

// features arrow
const FeaturesArrow: FC<IFeaturesArrow> = ({ className, icon, onClick }) => {
  // render
  return (
    <button className={`features-arrow ${className}`} onClick={onClick}>
      <span className={`icon icon-${icon}`}></span>
    </button>
  );
};

export default FeaturesArrow;