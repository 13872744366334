import React, { FC } from 'react';

import { ITitleText } from './interfaces';

import './title-text.scss';

// title
const TitleText: FC<ITitleText> = ({ attributes }) => {
  const { field_title, field_title_tag } = attributes;
  const CustomTag = `${field_title_tag}` as keyof JSX.IntrinsicElements;

  // render
  return (
      <div className="title-text" data-type={field_title_tag}>
        <CustomTag className="text">{field_title}</CustomTag>
    </div>
  );
};
TitleText.defaultProps = {
    attributes: {
        field_title: '',
        field_title_tag: 'h1'
    }
};

export default TitleText;