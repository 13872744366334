import React, { FC } from 'react';
import Slider from 'react-slick';

import ClientSliderArrow from '../ClientSliderArrow';
import ClientSliderItem from '../ClientSliderItem';

import { IClientSliderContainer } from './interfaces';

import './client-slider-container.scss';

import { settings } from './settings';

// highlights slider
const ClientSliderContainer: FC<IClientSliderContainer> = ({ items }) => {
  // render
  return (
    <div className="client-slider-container">
      <Slider
        className="client-slider-container--slider"
        {...settings}
        nextArrow={<ClientSliderArrow icon="arrow-right" />}
        prevArrow={<ClientSliderArrow icon="arrow-left" />}>
        {items && items.map((item: any, index: number) =>
          <ClientSliderItem {...item} key={index} />)} 
      </Slider>
    </div>
  );
};

export default ClientSliderContainer;
