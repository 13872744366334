import React, { FC, useEffect, useRef } from 'react';
import Slider from 'react-slick';

import ShipsTabsItem from '../ShipsTabsItem';
import ShipsTabsItemHome from '../ShipsTabItemHome';
import { withMediaQueries } from '../../../MediaQueries';

import { IShipsTabsList } from './interfaces';

import { settings } from './settings';

import './ships-tabs-lists.scss';

// ships tabs list
const ShipsTabsList: FC<IShipsTabsList> = ({ current, data, index, lang, queries }) => {
  const element: any = useRef(null);

  // use effect
  useEffect(() => {
    if (element?.current instanceof Object) {
      if (index !== 0) {
        setTimeout(() => element.current.slickGoTo(index), 500); // delay
      }
    }
  }, [ index, element ]);

  // render
  return (
    <>
      {(queries === 'lg' || queries === 'md') &&
        <ul className="ships-tabs-list">
          <li className="ships-tabs-list--item">
            <ShipsTabsItemHome
              lang={lang}
              link={lang?.lang === 'pt-br' ? "embarcacoes" : "our-vessels"}
              image="/images/wheel.png" />
          </li>

          {data && data.map((item: any, index: number) =>
            <li className="ships-tabs-list--item" key={index}>
              <ShipsTabsItem {...item} current={current === item} index={index} />
            </li>)}
        </ul>}
        
      {(queries === 'xs' || queries === 'sm') &&
        <div className="ships-tabs-list--gallery">
          <Slider
            className="ships-tabs-list--gallery--slider"
            ref={slider => (element.current = slider)}
            {...settings}>
              <ShipsTabsItemHome lang={lang} link="embarcacoes" image="/images/wheel.png" />
              {data && data.map((item: any, index: number) =>
                <ShipsTabsItem {...item} current={current === item} key={index} index={index} />)}
          </Slider>
        </div>}
    </>
  );
};

export default withMediaQueries(ShipsTabsList);