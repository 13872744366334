import React, { FC } from 'react';

import FooterLogo from './FooterLogo';
import FooterMenu from './FooterMenu';
import FooterSocial from './FooterSocial';
import withLoadComponentDataUrl from '../../components/Load/LoadComponentDataUrl';

import { IFooter } from './interfaces';

import './footer.scss';

// footer
const Footer: FC<IFooter> = ({
  data,
  location,
  lang
}) => {
  // render
  return (
    <footer className="footer" data-type={location.pathname === '/'}>
      <div className="footer--container">
        <FooterLogo lang={lang} />

        <div className="footer--menu">
          <FooterSocial url={`/pt-br/jsonapi/node/rede_social?filter[langcode]=${lang?.lang}&sort=field_ordem`} />
          <FooterMenu items={data} />
        </div>
      </div>
    </footer>
  );
};

export const gerFooterUrl = (lang:any) => {
  return `/${lang?.lang}/api/menu_items/footer${lang?.lang === 'en' ? '-' + lang?.lang : ''}`;
}

export default withLoadComponentDataUrl(Footer);