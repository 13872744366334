import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ISearchContent } from './interfaces';

import './search-content.scss';

// search content
const SearchContent: FC<ISearchContent> = ({ lang, open, setOpen }) => {
  const { t }: any = useTranslation();
  const history = useHistory();

  const input = useRef<any>(null);
  
  // on search
  const onSearch = useCallback(() => {
    setOpen(false);
    if (input.current instanceof Object && input.current.value !== '') {
      history.push(`/${lang?.lang}/search/${input.current.value}`);
    }
  }, [ history, input, lang, setOpen ]);

  // on enter
  const onKeyPress = useCallback((e: any) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      onSearch();
    }
  }, [ onSearch ]);

  // use effect
  useEffect(() => {
    if (open === true) {
      input.current.focus();
    }
  }, [ open, setOpen ]);
  
  // render
  return (
    <div className="search-content">
      <div className="search-content--form">
        <label className="search-content--search">
          <span className="icon icon-search"></span>
          <input className="input-search" type="text" ref={input} onKeyDown={onKeyPress} />
        </label>

        <div className="search-content--controls">
          <button className="btn-search" onClick={() => onSearch()}>
            <span className="text">{t('searching')}</span>
          </button>

          <button className="btn-close" onClick={() => setOpen(false)}>
            <span className="icon icon-close"></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchContent;