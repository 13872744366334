import React, { FC, memo } from 'react';

import { IFeaturesHistoryItem } from './interfaces';

import './features-history-item.scss';
import { useMemo } from 'react';

// features item
const FeaturesHistoryItem: FC<IFeaturesHistoryItem> = ({ attributes, image, dataType }) => {
  const { body: { value }, field_link_texto, field_link_url, field_link_tipo, title } = attributes;
  const image_url = useMemo( () => {
    if (image.links && image.links.thumb_523_294) {
      return image.links.thumb_523_294.href;
    }
    return image.attributes.uri.url;
  }, [image])
  // render
  return (
    <div className="features-history-item" data-type={dataType}>
      <div className="features-history-item--image">
        {image_url &&
          <img src={image_url} alt={image.attributes.filename} />}
        <p className="date">{title}</p>
      </div>

      <div className="features-history-item--info">
        <div className="description" dangerouslySetInnerHTML={{ __html: value }} />
      
        {field_link_url &&
          <a 
            className="link-item"
            href={field_link_url} 
            target={field_link_tipo === 'interno' ? '' : '_blank'}>
            <span className="text">{field_link_texto}</span>
          </a>}
      </div>
    </div>
  );
};

export default memo(FeaturesHistoryItem);