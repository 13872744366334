import React, { FC, memo, useCallback, useState } from 'react';

import BlogList from '../BlogList';
import Pagination from '../../Pagination';

import { IBlogContainer } from './interfaces';

import './blog-container.scss';

// vars
const { REACT_APP_BLOG_PAGINATION }: any = process.env;

// blog container
const BlogContainer: FC<IBlogContainer> = ({ lang, page, setPage }) => {
  const [ total, setTotal ] = useState<number>(1);

  // get total
  const getTotal = useCallback((count: any) => 
    setTotal(Math.ceil(parseInt(count, 10) / parseInt(REACT_APP_BLOG_PAGINATION, 10))), []);
  
  // render
  return (
    <div className="blog-container">
      <BlogList
        getTotal={getTotal}
        lang={lang}
        page={page} />

      {total > 1 && <Pagination
        current={page}
        setCurrent={setPage}
        total={total} />}
    </div>
  );
};

export default memo(BlogContainer);
