import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import Loading from '../../components/Loading';
import { withMediaQueries } from '../../components/MediaQueries';

import Home from '../../pages/Home';
import PageDefault from '../PageDefault';
import TimeLine from '../../pages/TimeLine';

import { IContent } from './interfaces';
import Search from '../../pages/Search';

// content
const Content: FC<IContent> = ({ location, getType, lang, setLang, queries }) => {
  // use effect
  useEffect(() => {
    if (location instanceof Object) {
      setLang(location.pathname.split('/')[0]);
    }
  }, [ location, setLang ]);

  // render
  return (
    <>
      <Loading location={location} />

      <Switch>
        <Route path={['/', `/${lang?.lang}`]} exact>
          <Home
            node={getType(lang)}
            lang={lang}
            location={location}
            queries={queries} />
        </Route>

        <Route path={`/${lang.lang}/timeline`} exact>
          <TimeLine
            lang={lang}
            location={location}
            url={`/${lang.lang}/api/timeline`} />
        </Route>

        <Route path={`/${lang.lang}/search/:search`} exact>
          <Search
            lang={lang}
            location={location}
            queries={queries} />
        </Route>

        <Route path={`/${lang.lang}/:page`}>
          <PageDefault
            lang={lang}
            location={location}
            queries={queries}
            getType={getType} />
        </Route>
      </Switch>
    </>
  );
};

export default withMediaQueries(Content);
