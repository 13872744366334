import React, { FC, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { Field } from 'formik';
import { useLocation, useHistory } from "react-router-dom";

import ReCAPTCHA from 'react-google-recaptcha';

import Input from '../Form/Input';
import Checkbox from '../Form/Checkbox';
import Message from '../Form/Message';
import { SmallLoading } from '../../Loading';
import Select from '../Form/Select';
import Link from '../../Link';

import { IFormContact, IOption } from './interfaces';

import './form-contact.scss';

// form contact
const defaultOption: IOption = { value: '', label: '' };
const FormContact: FC<IFormContact> = (props) => {
  const { lang } = props;
  const { t }: any = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const goToHome = () => {
    history.push('/' + lang.lang);
  }
  
  // select subject
  const [lastFocused, setLastFocused] = useState<any>(false);
  const [formValues, setFormValues] = useState<any>({ContatoForm:{recaptcha:''}});
  const [formErrors, setFormErrors] = useState<any>({ContatoForm:{}});
  const [loading, setLoading] = useState<any>(false);
  const [submited, setSubmited] = useState<any>(false);
  const [form, setForm] = useState<any>(false);
  const [subjects, setSubjects] = useState<any>([]);
  const [selectSubject, setSelectSubject] = useState<IOption>(defaultOption);
  const [status, setStatus] = useState<string>('');

  // on change
  useEffect(() => {
    axios({
      url: process.env.REACT_APP_URL_FORM + '/site/assuntos',
    }).then((e) => {
      if (e instanceof Object) {
        if (e.data) {
          const dataOptions = e.data.map((item: any) => {
            return {
              value: item.id,
              label: lang.lang === 'en' && item.nome_en ? item.nome_en : item.nome
            }
          });
          setSubjects(dataOptions);
          if (e.data.length === 1) {
            setSelectSubject(dataOptions[0]);
          }
          else {
            let selected;
            if (location.search) {
              let keys: any = location.search.split('?')[1];
              if (keys) {
                let selectedKey = keys.split('&').find((value: any) => value.split('=')[0] === 'subject');
                if (selectedKey) {
                  selectedKey = decodeURIComponent(selectedKey.split('=')[1]);
                  selected = dataOptions.find((option: any) => selectedKey === option.label);
                }
              }
            }
            setSelectSubject(selected || defaultOption);
          }
        }
      }
    });
  }, [lang, location]);

  const onChangeRecaptcha = useCallback((value: any) => {
    const errors = {
      ...formErrors,
      captcha: false,
    };
    setFormErrors(errors);
    const values = {
      ...formValues,
      ContatoForm: {
        ...formValues.ContatoForm,
        recaptcha: value || ''
      }
    }
    setLastFocused('recaptcha');
    setFormValues(values);
  // eslint-disable-next-line 
  }, [formErrors, formValues]);

  function changeLastFocused(name:any) {
    let key = name.replace('ContatoForm[', '').replace(']','')
    setLastFocused(key);
  }

  function validateEmail(value: any) {
    let error;
    if (!value) {
      error = t('form.labels.errors.default');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      error = t('form.labels.errors.email');
    }
    return error;
  }
  const validateField = (name: string, values: any) => {
    if (!values.ContatoForm[name] && !formValues.ContatoForm[name]) {
        return t('form.labels.errors.default');
    }
    else if (name === 'email') {
      return validateEmail(values.ContatoForm.email);
    }
  }
  const onChangeFileAnexo = (event: any) => {
    const errors: any = {...formErrors};
    const values = {
      ...formValues,
      ContatoForm: {
        ...formValues.ContatoForm,
        anexoFiles: event.target.value
      }
    }
    setLastFocused('anexoFiles');
    setFormValues(values);
    errors.ContatoForm['anexoFiles'] = validateField('anexoFiles', values);
    setFormErrors(errors);
  }

  const validateForm = (values: any) => {
    const errors: any = {...formErrors};
    if (lastFocused) {
      errors.ContatoForm[lastFocused] = validateField(lastFocused, values);
    }
    if (values.ContatoForm) {
      for(var key in values.ContatoForm) {
        if (values.ContatoForm[key] || submited) {
          errors.ContatoForm[key] = validateField(key, values);
        }
      }
    }

    setSubmited(false);
    setFormErrors(errors);
    return validateErrors(errors);
  }

  const validateErrors = (errors: any) => {
    var newErrors: any = {};
    for(var key in errors) {
      if(typeof errors[key] === 'string' && !!errors[key]) {
        newErrors[key] = errors[key];
      }
      else if(typeof errors[key] === 'object') {
        var subError = validateErrors(errors[key]);
        if (subError && Object.keys(subError).length > 0 ) {
          newErrors[key] = subError;
        }
      }
    }
    return newErrors;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ContatoForm: {
          nome: '',
          email: '',
          assunto: '',
          mensagem: '',
          aceitar_termo: false,
          recaptcha: '',
        },
      }}
      validate={validateForm}
      onSubmit={(values: any) => {
        if (!loading) {
          var formData = new FormData(form);
          setLoading(true);
          axios({
            // url: '/contact_message?_format=json',
            url: process.env.REACT_APP_URL_FORM + '/site/contato?f=json',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: formData
          }).then(({data}:any) => {
            setLoading(false);
            if (data instanceof Object) {
              if (data.id) {
                setStatus('200');
              }
              else {
                setFormErrors({
                  ...formErrors,
                  captcha: !!data.recaptcha,
                  ContatoForm: {
                    ...formErrors?.ContatoForm,
                    ...data
                  }
                })
              }
            }
          }).catch(e => setStatus('300'));
        }
      }}>
      {({ values, errors }: any) => (
        <div className="form-contact">
          <Form className="form-contact--form" autoComplete="off" noValidate={true} encType="multipart/form-data" ref={ref => setForm(ref)}>
            {loading && <SmallLoading />}
            <Input
              label={t('form.labels.name')}
              name="ContatoForm[nome]"
              type="text"
              onFocus={(evt:any) => changeLastFocused(evt.target.name)}
              required={true}
              error={formErrors?.ContatoForm?.nome}
              value={values.ContatoForm.nome} />

            <Input
              label={t('form.labels.email')}
              name="ContatoForm[email]"
              onFocus={(evt:any) => changeLastFocused(evt.target.name)}
              type="email"
              required={true}
              error={formErrors?.ContatoForm?.email}
              value={values.ContatoForm.email} />

            <Select
              defaultText={t('form.labels.subject')}
              name="ContatoForm[assunto]"
              dataActive={!formErrors?.ContatoForm?.assunto}
              options={subjects}
              error={formErrors?.ContatoForm?.assunto}
              handleChange={(value: any) => {
                setSelectSubject(value)
                const errors: any = {...formErrors, };
                errors.ContatoForm.assunto = false;
                formValues.ContatoForm.assunto = value;
                setFormErrors(errors);
                setFormValues(formValues);
              }}
              selectedOption={selectSubject} />

            <Input
              label={t('form.labels.message')}
              name="ContatoForm[mensagem]"
              type="textarea"
              // maxLength={max_length}
              // info={t('form.labels.max_length').replace('{length}', max_length - values.ContatoForm.mensagem.length)}
              required={true}
              error={formErrors?.ContatoForm?.mensagem}
              value={values.ContatoForm.mensagem} />

            <label className="input-control" data-active="false" data-error={formErrors?.ContatoForm?.anexoFiles ? true : false}>
              <Field onChange={onChangeFileAnexo} name="ContatoForm[anexoFiles]" type="file" />
              {formErrors?.ContatoForm?.anexoFiles && <label className="error">{formErrors?.ContatoForm?.anexoFiles}</label>}
            </label>

            <label className='upload-info'>Você pode subir anexos de até 8 MB nos seguintes formatos: jpg, jpeg, gif, tiff, doc, docx, xls, xlsx, ppt, pptx, zip, pdf, txt e png.</label>

            <Checkbox
              label={
                lang.lang === 'en' ? 
                <p>By submitting the completed form, you agree to our privacy policy found <Link internal={true} href={'/en/privacy-policy'}>here</Link>.</p>
                :
              <p>Ao enviar os dados do formulário, você concorda com nossa política de privacidade que pode ser encontrada <Link internal={true} href={'/pt-br/politica-de-privacidade'}>aqui</Link>.</p>
              }
              name="ContatoForm[aceitar_termo]"
              type="checkbox"
              required={true}
              error={formErrors?.ContatoForm?.aceitar_termo}
              value={values.ContatoForm.aceitar_termo} />

              <label className="form-contact--form--text-info">{t('form.labels.all_required')}</label>
              <label className="input-control" data-active="false" data-error={formErrors.ContatoForm.recaptcha ? true : false}>
                <input type="hidden" name="ContatoForm[recaptcha]" value={formValues.ContatoForm.recaptcha} />
                <ReCAPTCHA
                  sitekey="6LfGGREaAAAAACXtWGdIlJ06gwSjlZNJK9u3jKSc"
                  onChange={onChangeRecaptcha}
                />
                {formErrors && formErrors.ContatoForm.recaptcha && <label className="error">{formErrors.ContatoForm.recaptcha}</label>}
              </label>


              <div className="form-contact--form--footer">
                <button className="btn-border" onClick={_ => setSubmited(true)} type="submit">{t('send')}</button>
              </div>
          </Form>

            {status === '200' && <Message description="form.success.description" icon="checked" title="form.success.title" type="">
              <div className="form-contact--message--footer success">
                {/* <Link to="/" className="btn-border back">{t('back_to_main')}</Link> */}
                <button
                  className="btn-border back"
                  onClick={goToHome}>{t('back_to_main')}</button>
              </div>
            </Message>}

            {status === '300' && <Message description="form.error.description" icon="close" title="form.error.title" type="error">
              <div className="form-contact--message--footer error">
                <button
                  className="btn-border"
                  onClick={() => setStatus('')}>{t('try_again')}</button>
                {/* <Link to="/" className="btn-border back">{t('back_to_main')}</Link> */}
                <button
                  className="btn-border back"
                  onClick={goToHome}>{t('back_to_main')}</button>
              </div>
            </Message>}
        </div>)}
    </Formik>
      );
};

export default FormContact;
