import React, { FC } from 'react';

import TabsItem from '../TabsItem';

import { ITabsItem } from '../TabsItem/interfaces';
import { ITabsBody } from './interfaces';

import './tabs-body.scss';

// tabs body
const TabsBody: FC<ITabsBody> = ({ current, items }) => {
  // render
  return (
    <div className="tabs-body">
      <ul className="tabs-body--list">
        {items && items.map((item: ITabsItem, index: number) =>
          <li className="tabs-body--item" data-active={index === current} key={index}>
            <TabsItem {...item} />
          </li>)}
      </ul>
    </div>
  );
};

export default TabsBody;