import React, { FC, memo, useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import { IViewVideoContent, IVideoItem } from './interfaces';

import ModalVideo from './ModalVideo';

// vars
const {
    REACT_APP_URL_BASE,
    REACT_APP_URL_NODE,
    REACT_APP_BLOG_CONSUMER_ID
}: any = process.env;

// api
const api = (url: string) => axios.get(REACT_APP_URL_BASE + REACT_APP_URL_NODE + url).then(({ data }) => data);

// ViewVideo
const ViewVideoContent: FC<IViewVideoContent> = ({ lang, page, getTotal, limit }: any) => {
    const [modal, setModal] = useState<any>(null);
    const [init, setInit] = useState<boolean>(false);
    const { data } = useSWR<any>(`/video?filter[langcode]=${lang.lang}&include=field_image&sort=-created&page[limit]=${limit}&page[offset]=${limit * (page - 1)}&consumerId=${REACT_APP_BLOG_CONSUMER_ID}`, api);

    // get item relation
    const getItemRelation = useCallback((id: string, items: any[]) =>
        new Promise((resolve, reject) => {
            const item = items.filter((item: any) => item.id === id);

            if (item instanceof Object) {
                resolve(item[0]);
            } else {
                reject(false);
            }
        }), []);

    // get info
    const getRelations = useCallback(async (relations: any) => {
        if (relations instanceof Object === false
            || data?.included instanceof Object === false) return false;

        const { field_image } = relations;

        const filesInclude = data?.included.filter((item: any) => item.type === 'file--file');

        const image = await getItemRelation(field_image.data.id, filesInclude);

        return image;
    }, [getItemRelation, data]);

    // use effect flag init
    useEffect(() => {
        if (init === false && data instanceof Object) {
            setInit(true);
            getTotal(data.meta.count);
        }
    }, [data, init, getTotal]);
    return (
        <div className="videos--content">
            {data && data.data && <ul className="videos--list">
                {data.data.map((item: any, index: number) =>
                    <VideoItem
                        title={item.attributes.title}
                        video_url={item.attributes.field_video_url}
                        resumo={item.attributes.field_resumo?.value}
                        onClick={(e:any, video_url:any, title: any) => {
                            e.preventDefault();
                            setModal({url: video_url, title: title});
                        }}
                        relationships={item.relationships}
                        getRelations={getRelations}
                        key={index} />)}
            </ul>}

            {modal && <ModalVideo {...modal} onClose={() => setModal(false)}/>}
        </div>
    );
};

const VideoItem: FC<IVideoItem> = ({ title, relationships, getRelations, video_url, resumo, onClick }: any) => {
    const [url, setUrl] = useState<any>(null);
    // use effect
    useEffect(() => {
        const getInfo = async () => {
            getRelations(relationships)
                .then(({attributes: {uri: {url}}, links: {thumb_454_341}}: any) => {
                    if (thumb_454_341 || url) {
                        if (thumb_454_341) {
                            setUrl(thumb_454_341.href);
                        }
                        else {
                            setUrl(url);
                        }
                    }
                });
        }

        getInfo();
    }, [relationships, getRelations]);
    return (<li className={`videos--list--item`} onClick={(e) => onClick(e, video_url, title)}>
        {url && <div className="thumb">
            <img src={url} alt={title} title={title} />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 88 88"
                fill="none"
            >
                <g clipPath="url(#clip0_5495_37041)">
                    <circle cx={44} cy={44} r={44} fill="#00B1E7" />
                    <path
                        d="M61.1606 43.9162C62.006 44.5446 62.006 45.8104 61.1606 46.4387L36.295 64.9199C35.2582 65.6905 33.7861 64.9505 33.7861 63.6587L33.7861 26.6962C33.7861 25.4044 35.2582 24.6644 36.295 25.435L61.1606 43.9162Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_5495_37041">
                        <rect width={88} height={88} fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </div>}
        <div className="description">
            <h3>{title}</h3>
            {resumo && <div dangerouslySetInnerHTML={{ __html: resumo }} />}
        </div>
    </li>);
};

export default memo(ViewVideoContent);