import '../nanoid/polyfill';

import React, { FC, useCallback, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import Vimeo from '@u-wave/react-vimeo';

import VideoPlayerControls from './VideoPlayerControls';

import { IVideoPlayer } from './interfaces';

import './video-player.scss';

// video player
const VideoPlayer: FC<IVideoPlayer> = ({ attributes }) => {
  const video = useRef<any>(null);
  const id = nanoid();

  // state
  const [ state, setState ] = useState<any>({
    playing: false,
    muted: 0,
    played: 0,
    percent: 0
  });

  // on play pause
  const onPlayPause =  useCallback(() =>
    setState({ ...state, playing: !state.playing }), [ state, setState ]);

  // on toggle muted
  const onToggleMuted = useCallback(() =>
    setState({ ...state, muted: !state.muted ? 1 : 0 }), [ state, setState ]);

  // on seek change
  const onSeekChange = useCallback((value: any) => {
    if (video.current instanceof Object === false) return false;
    setState({ ...state, played: value });
  }, [ video, setState, state ]);

  // on progress
  const onProgress = useCallback((value: any) => {
    if (value instanceof Object === false) return false;

    const { percent } = value;
    setState({ ...state, percent });
  }, [ state, setState ]);

  // render
  return (
    <div className="video-player">
      <div className="video-player--container">
        <Vimeo
          video={attributes?.field_video_url}
          id={id}
          ref={video}
          className="video"
          responsive
          volume={state.muted}
          onTimeUpdate={onProgress}
          onSeeked={onSeekChange}
          paused={!state?.playing}
          controls={false} />

        <VideoPlayerControls
          muted={state.muted}
          playing={state.playing}
          onPlayPause={onPlayPause}
          onSeekChange={onSeekChange}
          onToggleMuted={onToggleMuted}
          value={state.percent} />
      </div>
    </div>
  );
};

export default VideoPlayer;