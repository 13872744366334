import React, { FC  } from 'react';
import { useTranslation } from 'react-i18next';

import ShipsBackground from '../../../ShipsBackground';
import ShipsItemRelationshipsList from '../ShipsItemRelationshipsList';
import TitleText from '../../../../TitleText';
import withLoadComponentDataNode from '../../../../Load/LoadComponentDataNode';

import './ships-item-relationships-content.scss';


// ships item relationship content
const ShipsItemRelationshipsContent: FC<any> = ({ data, current }) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="ships-item-relationships-content">
      {data?.data && <div className="ships-item-relationships-content--background">  
        <ShipsBackground name={current?.name} type={current?.tipo} min="relations" />
      </div>}

      <TitleText attributes={{ field_title: t('ships_relationships'), field_title_tag: 'h2' }} />

      {data?.data &&
        <ShipsItemRelationshipsList items={data?.data} />}
    </div>
  );
};

export default withLoadComponentDataNode(ShipsItemRelationshipsContent);