import React, { FC } from 'react';

import { IMenuItem } from './interfaces';

import './menu-item.scss';

// menu item
const MenuItem: FC<IMenuItem> = ({ first, second, pathname }) => {
  // render
  return (
    <a className="menu-item" href={second} data-active={second === pathname}>
      <h2 className="text">{first}</h2>
    </a>
  );
};

export default MenuItem;