import React, { FC, useState, useEffect, useRef, memo } from 'react';

import HeaderMenu from './HeaderMenu';
import HeaderTop from './HeaderTop';

import { IHeader } from './interfaces';

import './header.scss';

// header
const Header: FC<IHeader> = ({ location, lang, mediaQuery }) => {
  // open
  const [ open, setOpen ] = useState<boolean>(false);
  const [ scroll, setScroll ] = useState<boolean>(false);

  const prevScrollY = useRef<number>(0);

  // use effect
  useEffect(() => {
    if (location) {
      setOpen(false);
    }

    // handle scroll
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < 60) {
        setScroll(false);
      }

      if (prevScrollY.current > 60 && !scroll) {
        setScroll(true);
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [ location, setOpen, scroll ]);

  // render
  return (
    <header
      className="header"
      data-active={open}
      data-scroll={scroll}
      data-media={mediaQuery}>
        <HeaderTop
          lang={lang}
          mediaQuery={mediaQuery}
          open={open}
          setOpen={setOpen}
          url={`/${lang.lang}/api/menu_items/top${lang.lang === 'pt-br' ? '':'-'+lang.lang}`} />
        
      <HeaderMenu
        lang={lang}
        url={`/${lang.lang}/api/menu_items/main${lang.lang === 'pt-br' ? '':'-'+lang.lang}`} 
        open={open}
        mediaQuery={mediaQuery} />
    </header>
  );
};

export default memo(Header);