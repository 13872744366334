import React, { FC } from 'react';

import TabsBody from '../TabsBody';
import TabsHeader from '../TabsHeader';

import withLoadComponentDataUrl from '../../Load/LoadComponentDataUrl';

import { ITabsContent } from './interfaces';

import './tabs-content.scss';

// tabs content
const TabsContent: FC<ITabsContent> = ({ animation, current, setCurrent, setAnimation, data }) => {
  // render
  return (
    <div className="tabs-content">
      <TabsHeader
        animation={animation}
        current={current}
        items={data?.included}
        setAnimation={setAnimation}
        setCurrent={setCurrent} />

      <TabsBody
        current={current}
        items={data?.included} />
    </div>
  );
};

export default withLoadComponentDataUrl(TabsContent);