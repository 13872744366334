import React, { FC, useCallback, useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ShipsFilter from '../../components/Ships/ShipsFilter';
import ShipsFiltersCategory from '../../components/Ships/ShipsFiltersCategory';
import ShipsProvider from '../../providers/ShipsProvider';
import ShipsTabs from '../../components/Ships/ShipsTabs';
import TitleText from '../../components/TitleText';
import WebdoorInterna from '../../components/WebdoorInterna';
import withLoadComponentDataUrl from '../../components/Load/LoadComponentDataUrl';

import { IShipsCategory } from './interfaces';

import './ships-category.scss';
// ships category
const ShipsCategory: FC<IShipsCategory> = (props) => {
  const { t } = useTranslation();
  const { data, lang, entity: { uuid } } = props;
  const [ filters, setFilters ] = useState<any>({});
  const [ activeFilter, setActiveFilter ] = useState<any>(false);
  const location = useLocation();

  // set filter
  const setFilterQuery = useCallback((value: string, name: string) => {
    return `filter[filter-${name}][condition][path]=${name}&filter[filter-${name}][condition][value]=${value}&filter[filter-${name}][condition][memberOf]=filtro-group`;
  }, []);
  
  // use effect
  useEffect(() => {
    if (data && data?.data) {
      data?.data.forEach( (item:any) => {
        if(location.pathname.indexOf(item.attributes.path.alias) !== -1) {
          setActiveFilter(item)
          setFilters({})
        }
      })
    }
  }, [ data, location ]);

  const getFilters = useCallback(() => {
    let filterString = '';

    if (Object.keys(filters).length > 0) {
      for (let key in filters) {
        const filter: any = filters[key];

        if (filter instanceof Object) {
          filterString += `&${setFilterQuery(filter.value, key)}`;
        }
      }
  
      return `filter[filtro-group][group][conjunction]=AND${filterString}`;
    }

    return '';
  }, [ filters, setFilterQuery ]);

  // render
  return (
    <div className="ships-category">
      <WebdoorInterna id={activeFilter?.relationships?.field_image?.data?.id} />
      <TitleText
        attributes={{ field_title: t('ships'), field_title_tag: 'h1' }} />

      {data?.data && <ShipsProvider>
        <ShipsTabs
          lang={lang} />

        <ShipsFiltersCategory
          filters={filters}
          lang={lang}
          setFilters={setFilters}
          uuid={uuid} />

        <ShipsFilter
          lang={lang}
          uuid={uuid}
          node={`embarcacao?filter[langcode]=${lang?.lang}&filter[categoria][path]=field_categoria_embarcacao.id&sort=-sticky,title&filter[categoria][value]=${uuid}&${getFilters()}`} />
        </ShipsProvider>}
    </div>
  );
};

export default withLoadComponentDataUrl(ShipsCategory);
