import '../../nanoid/polyfill';

import React, { FC } from 'react';
import { nanoid } from 'nanoid';
import { useSpring, animated as a } from 'react-spring';

import { IBackgroundFluent } from './interfaces';

const AnimFeTurbulence = a('feTurbulence');
const AnimFeDisplacementMap = a('feDisplacementMap');

// background fluent
const BackgroundFluent: FC<IBackgroundFluent> = ({
  active,
  children,
  height,
  width,
  options,
  speed,
  callback }) => {
  // id
  const id = nanoid();
  
  // props
  const { freq, scale, transform }: any = useSpring({
    reverse: active,
    onRest: () => {
      if (typeof callback === 'function') {
        return callback();
      }
    },
    ...options
  });

  // render
  return (
    <>
      <a.svg style={{ transform }} viewBox={`0 0 ${width} ${height}`}>
        <defs>
          <filter id={`water-effect-${id}`}>
            <AnimFeTurbulence
              type="fractalNoise"
              baseFrequency={freq}
              numOctaves="1"
              result="TURB"
              seed={speed} />
            <AnimFeDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              in="SourceGraphic"
              in2="TURB"
              result="DISP"
              scale={scale} />
          </filter>
        </defs>
        <g filter={`url(#water-effect-${id})`}>
          {children}
        </g>
      </a.svg>
    </>
  )
}

export default BackgroundFluent;
