import React, { FC, useCallback } from 'react';
import Slider from 'react-slick';

import ShipsCard from '../../../ShipsCard';
import { withMediaQueries } from '../../../../MediaQueries';

import { IShipsItemRelationshipsList } from './interfaces';

import { settings } from './settings';

import './ships-item-relationships-list.scss';

// ships item relationships list
const ShipsItemRelationshipsList: FC<IShipsItemRelationshipsList> = ({ items, queries }) => {
  // random number
  const randomNumber = useCallback((min: number, max: number) => 
    Math.floor(Math.random() * (max - min + 1)) + min, []);
  
  // random from to
  const randomFromTo = useCallback((min: number, max: number, length: number) => {
    let numbers: any = [];
      
    Array.apply(null, new Array(length)).reduce((previous: number) => {
      let nextRandom;
      
      if(previous === min) {
        nextRandom = randomNumber(min + 1, max);
      } else if(previous === max) {
        nextRandom = randomNumber(min, max - 1);
      } else {
        if(randomNumber(0, 1)) {
          nextRandom = randomNumber(previous + 1, max);                
        } else {
          nextRandom = randomNumber(min, previous - 1);            
        }
      }

      numbers.push(nextRandom);

      return nextRandom;
    }, randomNumber(min, max));
      
    return numbers;
  }, [ randomNumber ]);

  // random range
  const randomRange = useCallback((limit: number) => {
    const elements: number[] = randomFromTo(0, items.length - 1, limit);

    return elements;
  }, [ items, randomFromTo ]);

  // render
  return (
    <>
      {(queries !== 'xs' && queries !== 'sm') &&
        <ul className="ships-item-relationships-list">
          {items && randomRange(2).map((item: number, index: number) =>
            <li className="ships-item-relationships-list--item" key={index}>
              <ShipsCard {...items[item]} />
            </li>)}
        </ul>}
      
      {(queries === 'xs' || queries === 'sm') &&
        <div className="ships-item-relationships-list--gallery">
          <Slider
            {...settings}>
            {items && items.map((item: any, index: number) =>
              <ShipsCard {...item} key={index} />)}
          </Slider>
        </div>}
    </>
  );
};

export default withMediaQueries(ShipsItemRelationshipsList);