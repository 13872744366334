import React, { FC } from 'react';

import { IClientSliderArrow } from './interfaces';

import './client-slider-arrow.scss';

// client slider arrow
const ClientSliderArrow: FC<IClientSliderArrow> = ({ className, icon, onClick }) => {
  // render
  return (
    <button className={`client-slider-arrow ${className}`} onClick={onClick}>
      <span className={`icon icon-${icon}`}></span>
    </button>
  );
};

export default ClientSliderArrow;