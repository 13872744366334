import React, { FC } from 'react';

import ShipsBackground from '../ShipsBackground';
import ShipsGalleryList from './ShipsGalleryList';
import withLoadComponentDataNode from '../../Load/LoadComponentDataNode';

import { IShipsGallery } from './interfaces';

import './ships-gallery.scss';

// ships gallery
const ShipsGallery: FC<IShipsGallery> = ({ id, data, attributes }) => {
  const { name, field_categoriaembarcacao_tipo } = attributes;

  // render
  return (
    <div className="ships-gallery" data-type={field_categoriaembarcacao_tipo}>
      {data?.data &&
        <>
          <ShipsBackground name={name} type={field_categoriaembarcacao_tipo} min="base" />
          <ShipsGalleryList data={data} parentId={id} type={field_categoriaembarcacao_tipo} />
        </>}
    </div>
  );
};

export default withLoadComponentDataNode(ShipsGallery);