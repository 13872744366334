import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

import { IBlockShipInfo } from './interfaces';

import './block-ship-info.scss';

// block ship info
const BlockShipInfo: FC<IBlockShipInfo> = ({
  field_texto: { value },
  field_title,
  field_link_url, field_link_text
}) => {
  // render
  return (
    <div className="block-ship-info">
      <Parallax translateY={[ 0, 10 ]}>
        <p className="title">{field_title}</p>
        <div className="description" dangerouslySetInnerHTML={{ __html: value }} />

        {field_link_url &&
          <Link
          className="link-item"
          to={field_link_url}>
              <span className="text">{field_link_text}</span>
              <span className="icon icon-arrow-right-min"></span>
            </Link>}
      </Parallax>
    </div>
  );
};

export default BlockShipInfo;