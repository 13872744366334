import React, { FC } from 'react';

import SliderInput from '../../Slider/Input';

import './video-player-controls.scss';

// video player controls
const VideoPlayerControls: FC<any> = ({
  muted,
  playing,
  onPlayPause,
  onSeekChange,
  onToggleMuted,
  value
}) => {
  // render
  return (
    <div className="video-player-controls">
      <button
        className="btn-item"
        data-active={playing}
        onClick={() => onPlayPause()}>
        <span className="icon icon-play"></span>
        <span className="ico icon-pause"></span>
      </button>

      <div className="slider-item">
        <SliderInput
          callback={onSeekChange}
          value={Math.floor(value * 100)}
          options={{
            name: 'time',
            min: 0,
            step: 1,
            max: 100
          }} />
      </div>

      <button
        className="btn-item"
        data-active={muted === 0}
        onClick={() => onToggleMuted()}>
        <span className="icon icon-volume-medium"></span>
        <span className="icon icon-volume-mute"></span>
      </button>
    </div>
  );
};

export default VideoPlayerControls;