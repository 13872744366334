import React, { FC } from 'react';
import Image from '../Image';

import { IWebdoorInterna } from './interfaces';

import './webdoor-interna.scss';
import webdoor from './default.png';

// webdoor interna
const WebdoorInterna: FC<IWebdoorInterna> = ({ id, src }) => {
  // render
  return (
    <div className="webdoor-interna">
      <Image id={id} fallback={src || webdoor} />
    </div>
  );
};

export default WebdoorInterna;