import React, { FC, useContext, useEffect } from 'react';

import ShipsItemRelationshipsContent from './ShipsItemRelationshipsContainer';

import { ShipsContext } from '../../../../providers/ShipsProvider';

import { IShipsItemRelationships } from './interfaces';

import './ships-item-relationships.scss';

// ships item relationships
const ShipsItemRelationships: FC<IShipsItemRelationships> = ({ id, parentId, lang }) => {
  const shipsContext = useContext(ShipsContext);
  const { ships, current, setCurrent } = shipsContext;

  // use effect
  useEffect(() => {
    setCurrent(parentId, ships);
  }, [ parentId, ships, setCurrent ]);

  // render
  return (
    <div className="ships-item-relationships">
      <ShipsItemRelationshipsContent
        current={current}
        lang={lang}
        node={`embarcacao?filter[categoria][path]=field_categoria_embarcacao.id&filter[categoria][value]=${parentId}&filter[nid][path]=id&filter[nid][operator]=%3C%3E&filter[nid][value]=${id}`} />
    </div>
  );
};

export default ShipsItemRelationships;