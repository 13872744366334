import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IMessage } from './interfaces';

import './message.scss';

// message
const Message: FC<IMessage> = ({ children, description, icon, title, type }) => {
  const { t } = useTranslation(); 

  // render
  return (
    <div className="form-message" data-type={type}>
      <div className="form-message--content">
        <p className="form-message--title">
          <span className={`icon icon-${icon}`}></span>
          <span className="title">{t(title)}</span>
        </p>

        <p className="form-message--description">{t(description)}</p>

        <div className="form-message--footer">{children}</div>
      </div>
    </div>
  );
};

export default Message;