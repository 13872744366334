import React, { FC, useEffect, useMemo } from 'react';
import { useSpring, animated as a } from 'react-spring';

import { withMediaQueries } from '../../MediaQueries';

import { ITimeLineItem } from './interfaces';

import './timeline-item.scss';
import TimeLineItemBackground from './TimeLineItemBackground';

// time line item
const TimeLineItem: FC<ITimeLineItem> = ({
  current,
  color,
  body,
  image_1,
  image_2,
  image_3,
  setScroller,
  last,
  subtitle,
  queries,
  title }) => {
  const [{ y }, set] = useSpring(() => ({ y: 100, config: { delay: 1500 } }));

  // use effect
  useEffect(() => {
    if (current === true) {
      set({ y: 0 });
    } else if (last === true) {
      set({ y: 0 });
    }
  }, [ current, last, set ]);
  const id = useMemo(() => {
    const regex = / /ig;
    return title.replace(regex,'-');
  }, [title]);
  const prefix = useMemo(() => {
    return title.split(' ')[0].substring(2);
  }, [title]);

  // render
  return (
    <a.div
      id={`timeline-item-${id}`}
      className="timeline-item"
      data-active={current}
      data-out={last}
      data-type={color}
      style={{ 
        transform: y.interpolate((y) => `translateY(${y}%)`)
      }}>

      {(queries === 'xs' || queries === 'sm') &&
        <>
          <TimeLineItemBackground animate={current} type={color} />

          <div className="timeline-item--index">
            <p className="year">{title}</p>
          </div>
        </>}

      <div className="timeline-item--image">
        <img className="image-main" src={image_1} alt={title} />
        <img className="image-three" src={image_2} alt={title} />
        <img className="image-second" src={image_3} alt={title} />

        <p className="year">{prefix}</p>
      </div>

      <div className="timeline-item--info">
        <div className="timeline-item--scroll" onScroll={event => {
          const target:any = event.target;
          if (setScroller) {
            if ( (target.scrollTop+ target.clientHeight) < target.scrollHeight) {
              setScroller(false);
            }
            else {
              setScroller(true);
            }
          }
        }}>
          <p className="title">{subtitle}</p>
          <div className="description" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </a.div>
  );
};

export default withMediaQueries(TimeLineItem);