import React, { FC, useCallback } from 'react';

import BlockImageItem from './BlockImageItem';

import { IBlockImage } from './interfaces';

import './block-image.scss';

// block render
const BlockImage: FC<IBlockImage> = ({ attributes, lang, relationships }) => {
   // get type
   const getType = useCallback((relationships: any) => {
    if (relationships instanceof Object === false) return false;

    const { field_blocos: { data } } = relationships;

    return data.length > 1 ? true : false;
  }, []);

  // get component
  const getComponent = useCallback((item: any, relationships: any, total:any) => {
    const { id, type } = item;
    const typeComponent: string = type.replace('paragraph--', '');

    return <BlockImageItem
      {...item}
      type={attributes?.field_cor_fonte}
      image_style={total > 2 ? 'thumb_650' : (total === 2 ? 'thumb_1000':'thumb_1950') }
      multiple={getType(relationships)}
      url={`/${lang.lang}/jsonapi/paragraph/${typeComponent}/${id}?include=field_imagem&consumerId=cd2eec82-9387-4a64-b2d3-5443f4058dc3`}
      key={id} />;
  }, [ attributes, lang, getType ]);

  // get components
  const getComponents = useCallback((relationships: any) => {
    if (relationships instanceof Object === false) return false;

    const { field_blocos: { data } } = relationships;
    const items: any = [];

    for (let element of data) {
      if (element instanceof Object) {
        items.push(getComponent(element, relationships, data.length));
      }
    }

    return items;
  }, [ getComponent ]);

  // render
  return (
    <div className="block-image" >
      {getComponents(relationships)}
    </div>
  );
};

export default BlockImage;