import React, { FC } from 'react';

import SelectLangItem from '../SelectLangItem';

import { ISelectLangList } from './interfaces';
import { ILangItem } from '../../../providers/LangProvider/interfaces';

import './select-lang-list.scss';

// select lang list
const SelectLangList: FC<ISelectLangList> = ({
  lang,
  langs,
  setLang,
  setOpen
}) => {
  // render
  return (
    <ul className="select-lang-list">
      {langs && langs.map((item: ILangItem, index: number) => <li
        className="select-lang-list--item"
        data-active={item.name === lang?.name}
        key={index}>
        <SelectLangItem lang={item} setLang={setLang} setOpen={setOpen} />
      </li>)}
    </ul>
  );
};

export default SelectLangList;
