import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import Image from '../../Image';
import withLoadComponentDataUrl from '../../Load/LoadComponentDataUrl';

import { IFeaturesItem } from './interfaces';

import './features-item.scss';

// features item
const FeaturesItem: FC<IFeaturesItem> = ({ itemType, data }) => {
  // render
  return (
    <div className="features-item" data-type={itemType}>
      <div className="features-item--image">
        {data?.data.relationships &&
          <Image id={data?.data.relationships.field_icone_svg.data.id} />}
      </div>

      <div className="features-item--info">
        <p className="title">{data?.data.attributes.field_title}</p>
        <p className="description" dangerouslySetInnerHTML={{ __html: data?.data.attributes.field_text.value }} />
      
        {data?.data.attributes.field_link_url &&
          <Link className="link-item" to={data?.data.attributes.field_link_url}>
            {data?.data.attributes.field_link_text}
          </Link>}
      </div>
    </div>
  );
};

export default withLoadComponentDataUrl(FeaturesItem);