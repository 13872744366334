import React, { FC, useCallback } from 'react';

import { IPagination } from './interfaces';

import './pagination.scss';

// pagination
const Pagination: FC<IPagination> = ({ current, total, setCurrent }) => {
  // on change
  const onChange = useCallback((dir: string) => {
    if (dir === 'prev') {
      setCurrent((current - 1) < 1 ? 1 : (current - 1), total);
    } else {
      setCurrent((current + 1) >= total ? total : (current + 1), total);
    }
  }, [ current, total, setCurrent ]);

  // render
  return (
    <div className="pagination">
      <button
        className="pagination--action prev"
        disabled={current <= 1}
        onClick={() => onChange('prev')}>
        <span className="icon icon-arrow-left-min"></span>
      </button>
      
      <p className="pagination--info">
        <span className="current">{current}</span>/
        <span className="total">{total}</span>
      </p>

      <button
        className="pagination--action next"
        disabled={current >= total}
        onClick={() => onChange('next')}>
        <span className="icon icon-arrow-right-min"></span>
      </button>
    </div>
  );
};

export default Pagination;