import React, { FC } from 'react';
import Slider from 'react-slick';

import ListAddressItem from '../ListAddressItem';
import { withMediaQueries } from '../../../MediaQueries';

import { IListAddressContainer } from './interfaces';

import { settings } from './settings';

import './list-address-container.scss';

// list address container
const ListAddressContainer: FC<IListAddressContainer> = ({ items, linkText = false, queries }) => {
  // render
  let attributeItems: any = {};
  if (linkText) {
    attributeItems.linkText = linkText;
  }
  return (
    <div className="list-address-container">
      {(queries === 'md' || queries === 'lg') &&
        <ul className="list-address-container--list">
          {items && items.map((item: any, index: number) =>
            <li className="list-address-container--item" key={index}>
              <ListAddressItem {...item} {...attributeItems} />
            </li>)}
        </ul>}

      {(queries === 'xs' || queries === 'sm') &&
        <Slider
          {...settings}
          className="list-address-container--slider">
            {items && items.map((item: any, index: number) => 
            <ListAddressItem {...item} {...attributeItems} key={index} />)}
        </Slider>}
    </div>
  );
};

export default withMediaQueries(ListAddressContainer);