import React, { FC } from 'react';

import Image from '../../Image';
import CardGalleryControls from '../CardGalleryControls';

import { ICardGallery } from './interfaces';

import './card-gallery.scss';

// card gallery
const CardGallery: FC<ICardGallery> = ({ items, current, direction, setAnimation, onPrevNext }) => {
  // render
  return (
    <>
      <ul
        className="card-gallery"
        data-first={current === 0}
        data-direction={direction}>
        {items.map(({ id }: any, index: number) =>
          <li
            className="card-gallery--item"
            data-current={current === index}
            data-last={(current - 1) === index && (current - 1) >= 0}
            data-next={(current + 1) === index && (current + 1) <= (items.length - 1)}
            key={index}>
            <Image id={id}
              consumerId="aff15899-a163-4189-a961-83bc8a22e149"
              crop="fallback_100"
              lazyload="thumb_600"
              />
          </li>)}
      </ul>

      <CardGalleryControls
        current={current}
        counts={items.length}
        onPrevNext={onPrevNext}
        setAnimation={setAnimation} />
    </>
  );
};

export default CardGallery;