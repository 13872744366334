import React, { FC } from 'react';

import withLoadComponentDataNode from '../Load/LoadComponentDataNode';
import SocialMediaList from './SocialMediaList';
import TitleText from '../TitleText';

import { ISocialMedia } from './interfaces';

import './social-media.scss';

// social media
const SocialMedia: FC<ISocialMedia> = ({ data, title, image_style, consumerId }) => {
  // render
  return (
    <div className="social-media">
      <div className="social-media--wrapper">
        {data && 
          <TitleText attributes={{ field_title: title, field_title_tag: 'h2' }} id={data.data.id} />}
        
        <div className="social-media--container">
          <SocialMediaList consumerId={consumerId} image_style={image_style} items={data?.data} />
        </div>
      </div>
    </div>
  );
};

export default withLoadComponentDataNode(SocialMedia);