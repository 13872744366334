import React, { FC, useCallback } from 'react';

import withLoadComponentDataUrl from '../../../Load/LoadComponentDataUrl';

import { IGalleryThumbsItem } from './interfaces';

import './gallery-thumbs-item.scss';

// gallery thumbs item
const GalleryThumbsItem: FC<IGalleryThumbsItem> = ({
  meta: { alt },
  data,
  index,
  queries,
  onCurrent
}) => {
  // get image
  const getImage = useCallback((items: any) => {
    if (items instanceof Object === false) return false;

    const img = items.thumb_390_375;
    return <img src={img.href} alt={alt} />;
  }, [ alt ]);

  // render
  return (
    <div className="gallery-thumbs-item" onClick={() => onCurrent(index)}>
      {data && getImage(data?.data.links)}
    </div>
  );
};

export default withLoadComponentDataUrl(GalleryThumbsItem);