import React, { FC, useCallback } from 'react';

import Image from '../../Image';
import withLoadComponentDataUrl from '../../Load/LoadComponentDataUrl';

import { IHighLightsItem } from './interfaces';

import './highlights-item.scss';

// hight lights item
const HighLightsItem: FC<IHighLightsItem> = ({ data }) => {
  // get content
  const getInfo = useCallback(() => {
    if (data instanceof Object === false) return false;
    const { data: { attributes: { field_title_linha1, field_title_linha2 }} } = data;

    return <>
        <p className="name">{field_title_linha1}</p>
        <p className="name">{field_title_linha2}</p>
      </>
  }, [ data ]);

  // get image
  const getImage = useCallback(() => {
    if (data instanceof Object === false) return false;
    const { data: { relationships: { field_icone_svg: { data: { id } } }} } = data;

    return <Image id={id} />
  }, [ data ]);

  // render
  return (
    <>
      {data && <div className="highlights-item">
        <div className="image">{getImage()}</div>
        <div className="content">{getInfo()}</div>
      </div>}
    </>
  );
};

export default withLoadComponentDataUrl(HighLightsItem);
