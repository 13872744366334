import React, { FC, useCallback } from 'react';
import Slider from 'react-slick';

import HighLightsArrow from '../HighLightsArrow';
import HighLightsItem from '../HighLightsItem';
import { withMediaQueries } from '../../MediaQueries';

import { IHighLightsSlider } from './interfaces';

import './highlights-slider.scss';

import { settings } from './settings';

// highlights slider
const HighLightsSlider: FC<IHighLightsSlider> = ({ lang, items, queries, setAnimation }) => {
  // get component
  const getComponent = useCallback((item: any, index: number) => {
    const { id, type } = item;
    const typeComponent: string = type.replace('paragraph--', '');

    return <HighLightsItem
      {...item}
      url={`/${lang.lang}/jsonapi/paragraph/${typeComponent}/${id}?include=field_icone_svg`}
      key={index} />;
  }, [ lang ]);

  // render
  return (
    <div className="highlights-slider">
      <Slider
        {...settings}
        className="highlights-slider--content"
        variableWidth={queries === 'xs' || queries === 'sm'}
        beforeChange={() => setAnimation(true)}
        nextArrow={<HighLightsArrow icon="arrow-right" />}
        prevArrow={<HighLightsArrow icon="arrow-left" />}>
          {items && items.map((item: any, index: number) => getComponent(item, index))}
      </Slider>
    </div>
  );
};

export default withMediaQueries(HighLightsSlider);