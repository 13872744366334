import '../../nanoid/polyfill';

import React, { FC, memo } from 'react';
import { useSpring, animated as a } from 'react-spring';
import { useScroll } from 'react-use-gesture';

import { IBackgroundScroll } from './interfaces';

import './background-scroll.scss';

const AnimFeTurbulence = a('feTurbulence');
const AnimFeDisplacementMap = a('feDisplacementMap');

// background scroll
const BackgroundScroll: FC<IBackgroundScroll> = ({ children }) => {
  const [{ x }, set] = useSpring(() => ({ x: 0 }));
  const heightDoc = document.documentElement.scrollHeight;

  const width = 1600;
  const height= 740;
  const speed = 1;

  // bind
  const bind = useScroll(({ xy: [, y] }) => set({ 
    x: ((y / heightDoc) * 0.05), 
  }), { domTarget: window });

  // render
  return (
    <div className="background-scroll">
      <a.svg viewBox={`0 0 ${width} ${height}`} {...bind()}>
        <defs>
          <filter id={`water-effect-${Date.now()}`}>
            <AnimFeTurbulence
              type="fractalNoise"
              baseFrequency={x.interpolate((x: any) => `${((x * 0.08))}, 0`)}
              numOctaves="1"
              result="TURB"
              seed={speed} />
            <AnimFeDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              in="SourceGraphic"
              in2="TURB"
              result="DISP"
              scale={x.interpolate((x: any) => (((x - (height / 2))) * 0.08))} />
          </filter>
        </defs>
        <g filter={`url(#water-effect-${Date.now()})`}>
          {children}
        </g>
      </a.svg>
    </div>
  );
};

export default memo(BackgroundScroll);