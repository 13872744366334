import React, { FC, useCallback } from 'react';

import { ITabsItem } from '../TabsItem/interfaces';

import { ITabsHeader } from './interfaces';

import './tabs-header.scss';

// tabs header
const TabsHeader: FC<ITabsHeader> = ({ current, items, setCurrent, setAnimation }) => {
  // on select item
  const onSelectItem = useCallback((index: number) => {
    setAnimation(true);
    setCurrent(index);
  }, [ setAnimation, setCurrent ]);

  // render
  return (
    <div className="tabs-header">
      <ul className="tabs-header--list">
        {items && items.map(({ attributes: { field_embarcacao_categoria } }: ITabsItem, index: number) =>
          <li className="tabs-header--item" key={index}>
            <button
              className="btn"
              data-active={current === index}
              onClick={() => onSelectItem(index)}>{field_embarcacao_categoria}</button>
          </li>)}
      </ul>
    </div>
  );
};

export default TabsHeader;