import React, { FC } from 'react';

import ListGroupContent from './ListGroupContent';

import { IListGroup } from './interfaces';

import './list-group.scss';

// list group
const ListGroup: FC<IListGroup> = ({ lang, id, type }) => {
  // render
  return (
    <div className="list-group">
      <ListGroupContent
        url={`/${lang?.lang}/jsonapi/paragraph/${type.replace('paragraph--', '')}/${id}?include=field_bloco_icones_texto_item`} />
    </div>
  );
};

export default ListGroup;