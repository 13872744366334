import React, { FC } from 'react';
import Slider from 'react-slick';

import { withMediaQueries } from '../../MediaQueries';
import SocialMediaItem from '../SocialMediaItem';

import { ISocialMediaList } from './interfaces';

import { settings } from './settings';

import './social-media-list.scss';

// social media list
const SocialMediaList: FC<ISocialMediaList> = ({ items, queries, image_style, consumerId }) => {
  // render
  return (
    <div className="social-media-list">
      {(queries === 'lg' || queries === 'md') && 
        <>
          {items && items.map((item: any, index: number) =>
            <SocialMediaItem {...item} key={index} image_style={image_style} consumerId={consumerId} />)}
        </>}
        
      {(queries === 'xs' || queries === 'sm') &&
        <Slider
          {...settings}
          className="social-media-list--slider">
            {items && items.map((item: any, index: number) => 
            <SocialMediaItem
              {...item}
              consumerId={consumerId}
              image_style={image_style}
              key={index} />)}
            </Slider>}
    </div>
  );
};

export default withMediaQueries(SocialMediaList);