import useSWR from 'swr';
import React, { FC, memo, useContext, useMemo } from 'react';
import { ShipsContext } from '../../providers/ShipsProvider';
import { IShipsContext } from '../../providers/ShipsProvider/interfaces';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ShipsItem from '../../components/Ships/ShipsItem';
import ShipsTabs from '../../components/Ships/ShipsTabs';
import TitleText from '../../components/TitleText';
import WebdoorInterna from '../../components/WebdoorInterna';
import {getNodeUrl, api} from '../../components/Load/LoadComponentDataNode';
import ShipsProvider from '../../providers/ShipsProvider';

import { IShipsPage } from './interfaces';

import './ships-page.scss';

// ships page
const ShipsPage: FC<IShipsPage> = ({ lang, entity: { uuid } }) => {
  const { t } = useTranslation();
  const shipsContext = useContext(ShipsContext);
  const { ships }: IShipsContext = shipsContext;

  const url = getNodeUrl(lang?.lang, `embarcacao/${uuid}?include=field_embarcacao_especificacao`);
  const { data } = useSWR<any>(url, api);
  const header:any = useMemo( () => {
    if (ships && data) {
      const id = data?.data?.relationships?.field_categoria_embarcacao?.data?.id;
      const ship:any = ships.find(s => s.uuid === id)
      return ship?.image;
    }
    return false;
  }, [ships, data])
  const urlToShips:any = useMemo( () => {
    return `/${lang.lang}/${lang?.lang === 'pt-br' ? `embarcacoes` : `our-vessels`}`;
  }, [lang])
  
  // render
  return (
    <div className="ships-page">
      {header && <WebdoorInterna src={header} />}
      <TitleText
        attributes={{ field_title: t('ships'), field_title_tag: 'h1' }} />

      <ShipsProvider>
        <ShipsTabs
          lang={lang} />

        <ShipsItem
          lang={lang}
          id={uuid}
          node={`embarcacao/${uuid}?include=field_embarcacao_especificacao`} />
        
        <div className="ships-page--footer">
          <Link className="btn btn-border" to={`${urlToShips}`}>
            <span className="icon icon-arrow-left-min"></span>
            <span className="text">{t('back_to_ships')}</span>
          </Link>
        </div>
      </ShipsProvider>
    </div>
  );
};

export default memo(ShipsPage);