import React, { FC } from 'react';

import BackgroundFluent from '../../Backgrounds/BackgroundFluent';
import Image from '../../Image';

import { IBlockShipImage } from './interfaces';

import { ReactComponent as Background } from '../../../assets/svg/bg-ship.svg';

import { settings } from './settings';

import './block-ship-image.scss';

// block ship image
const BlockShipImage: FC<IBlockShipImage> = ({
  animate,
  field_imagem,
  type,
  title,
  subtitle,
  setAnimate }) => {
  const { data: { id }} = field_imagem;

  // render
  return (
    <div
      className="block-ship-image"
      data-type={type}>
      <div className="block-ship-image--background">
        <BackgroundFluent
          active={animate}
          speed={1}
          height={860}
          width={640}
          options={settings}
          callback={() => setAnimate(false)}>
            <Background />
        </BackgroundFluent>
      </div>

      <Image id={id} options={{ alt: title }} />
      <p className="subtitle">{subtitle}</p>
    </div>
  );
};

export default BlockShipImage;