import React, { FC } from 'react';

import ListIndicatorsItem from '../ListIndicatorsItem';

import { IListIndicatorsItem } from '../ListIndicatorsItem/interfaces';
import { IListIndicatorsContainer } from './interfaces';

import { ReactComponent as Background } from '../../../../assets/svg/bg-list-indicators.svg';

import './list-indicators-container.scss';
import { Parallax } from 'react-scroll-parallax';

// list indicators container
const ListIndicatorsContainer: FC<IListIndicatorsContainer> = ({ items }) => {
  // render
  return (
    <div className="list-indicators-container">
      <div className="list-indicators-container--background">
        <Parallax translateY={[-20, 0]}>
          <Background />
        </Parallax>
      </div>

      <ul className="list-indicators-container--list">
        {items && items.map(({ first, second }: IListIndicatorsItem, index: number) =>
          <li className="list-indicators-container--item" key={index}>
            <ListIndicatorsItem first={first} second={second} />
          </li>)}
      </ul>
    </div>
  );
};

export default ListIndicatorsContainer;