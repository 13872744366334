import React, { FC } from 'react';

import SliderBase from '../../Slider/Base';
import WebdoorControls from '../WebdoorControls';
import WebdoorInfo from '../WebdoorInfo';
import WebdoorSlider from '../WebdoorSlider';

import { IWebdoorContent } from './interfaces';

// Webdoor content
const WebdoorContent: FC<IWebdoorContent> = ({
  current,
  direction,
  data,
  items,
  last,
  setCurrent,
  onPrevNext
}) => {
  // render
  return (
    <>
      <WebdoorInfo
        item={data[current]} />

      <WebdoorSlider
        direction={direction}
        current={current}
        data={data}
        items={items}
        prev={last}
        onPrevNext={onPrevNext} />

      <WebdoorControls
        current={current}
        items={items}
        setCurrent={setCurrent} />
    </>
  )
};

export default SliderBase(WebdoorContent);
