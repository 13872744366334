import React, { FC } from 'react';

import { ITimeLineControls } from './interfaces';
import { ITimeLineItem } from '../TimeLineItem/interfaces';

import './timeline-controls.scss';

// timeline controls
const TimeLineControls: FC<ITimeLineControls> = ({ current, items, setCurrent }) => {
  // render
  return (
    <div className="timeline-controls">
      {items && items.map(({ color, title }: ITimeLineItem, index: number) =>
        <button
          className="timeline-controls--item"
          onClick={() => setCurrent(index)}
          data-active={current === index}
          data-type={color}
          key={index}
          disabled={current === index}>
          <span className="label">{title}</span>
        </button>)}
    </div>
  );
};

export default TimeLineControls;