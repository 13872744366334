import React, { FC, useContext } from 'react';

import withLoadComponentDataNode from '../../components/Load/LoadComponentDataNode';

import Header from '../../layouts/Header';
import Footer, {gerFooterUrl} from '../../layouts/Footer';

import { MainContext } from '../../providers/MainProvider';

import { IHome } from './interfaces';
import { IMainContext } from '../../providers/MainProvider/interfaces';

// home
const Home: FC<IHome> = ({ data, lang, location, queries }) => {
  // main context
  const mainContext = useContext<IMainContext>(MainContext);
  const { getComponents } = mainContext;

  // render
  return (
    <>
      <Header location={location} lang={lang} mediaQuery={queries} />

      <div className="page home">
        {data && getComponents(data?.included, lang)}
      </div>

      <Footer
        url={gerFooterUrl(lang)}
        location={location}
        lang={lang}
        mediaQuery={queries} />
    </>
  );
};

export default withLoadComponentDataNode(Home);