import React, { FC } from 'react';

import { IShipsCardModalList, IShipsCardModalListItem } from './interfaces';

import './ships-card-modal-list.scss';

// ships card modal list
const ShipsCardModalList: FC<IShipsCardModalList> = ({ items }) => {
  // render
  return (
    <ul className="ships-card-modal-list">
      {items && items.map(({ first, second }: IShipsCardModalListItem, index: number) =>
        <li className="ships-card-modal-list--item" key={index}>
          <span className="label">{first}</span>
          <span className="value">{second}</span>
        </li>)}
    </ul>
  );
};

export default ShipsCardModalList;