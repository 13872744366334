import React, { FC } from 'react';

import BlockPrintItem from '../BlockPrintItem';

import { IBlockPrintInfo } from './interfaces';

import './block-print-info.scss';

// block print info
const BlockPrintInfo: FC<IBlockPrintInfo> = ({ lang, items, title }) => {
  // render
  return (
    <div className="block-print-info">
      <p className="title">{title}</p>

      <ul className="block-print-info--list">
        {items && items.map(({ id, type }: any, index: number) =>
          <li className="block-print-info--item" key={index}>
            <BlockPrintItem
              url={`/${lang?.lang}/jsonapi/paragraph/${type.replace('paragraph--', '')}/${id}`} />
          </li>)}
      </ul>
    </div>
  );
};

export default BlockPrintInfo;