import React, { FC, useCallback, useRef, useState } from 'react';
import Slider from 'react-slick';

import { withMediaQueries } from '../../MediaQueries';
import FeaturesHistoryArrow from '../FeaturesHistoryArrow';
import FeaturesHistoryItem from '../FeaturesHistoryItem';

import { IFeaturesHistoryItem, IFeaturesHistoryItemImage } from '../FeaturesHistoryItem/interfaces';
import { IFeaturesHistorySlider } from './interfaces';

import { settings } from './settings';

import './features-history-slider.scss';

// highlights slider
const FeaturesHistorySlider: FC<IFeaturesHistorySlider> = ({ items, include, queries }) => {
  const sliderRef = useRef<any>(null);
  const [lastSlide, setLastSlide] = useState<boolean>(false);
  
  // get type
  const getType = useCallback((index: number) => {
    if ((index + 1) % 3 === 0)
      return 3;

    if ((index + 1) % 2 === 0)
      return 2;

    return 1;
  }, []);

  // get image
  const getImage = useCallback((item: IFeaturesHistoryItem): any => {
    const { relationships: { field_image: { data } } } = item;
    const imageId = data.id;

    const image = include.filter(({ id }: IFeaturesHistoryItemImage) => imageId === id);

    if (image.length > 0) {
      return image[0];
    }

    return false;
  }, [ include ]);

  const sortByYear = () => {
    return items.sort((a: IFeaturesHistoryItem, b: IFeaturesHistoryItem) => {
      const aYear = parseInt(a.attributes.title, 10);
      const bYear = parseInt(b.attributes.title, 10);

      return aYear - bYear;
    });
  }

  const sliderToBegin = (_: number, next: number) => {
    let slideCount = 3;
    if (window.innerWidth <= 480) {
      slideCount = 1
    } else if (window.innerWidth <= 768) {
      slideCount = 2
    }

    const rest = (items.length - slideCount) - next
    if (rest === 0) {
      setLastSlide(true)
    } else {
      setLastSlide(false)
    }
  };

  const sliderGoTo = (index: number) => {
    sliderRef.current.slickGoTo(index);
  }

  // render
  return (
    <div className="features-history-slider">
      <Slider
        {...settings}
        swipeEvent={(e) => console.log(e)}
        ref={sliderRef}
        className="features-history-slider--content"
        variableWidth={queries === 'xs' || queries === 'sm'}
        beforeChange={sliderToBegin}
        nextArrow={
          <FeaturesHistoryArrow 
            lastSlide={lastSlide}
            sliderGoTo={sliderGoTo}
            icon="arrow-right-l" 
          />
      }
        prevArrow={
          <FeaturesHistoryArrow 
            lastSlide={lastSlide}
            sliderGoTo={sliderGoTo}
            icon="arrow-left-l" 
          />
        }>
          {items && sortByYear().map((item: IFeaturesHistoryItem, index: number) => 
          <FeaturesHistoryItem
            {...item}
            image={getImage(item)}
            dataType={getType(index)}
            key={item.attributes.title} />)}
      </Slider>
    </div>
  );
};

export default withMediaQueries(FeaturesHistorySlider);