import React, { FC, memo, useState } from 'react';

import BlogContainer from './BlogContainer';

import { IBlog } from './interfaces';

import './blog.scss';

// blog
const Blog: FC<IBlog> = ({ lang }) => {
  // page
  const [ page, setPage ] = useState<number>(1);

  // render
  return (
    <div className="blog">
      <BlogContainer
        lang={lang}
        page={page}
        setPage={setPage} />
    </div>
  );
};

export default memo(Blog);