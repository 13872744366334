export const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};
