import React, { useState, useRef, FC, useContext } from 'react';

import SelectLangList from './SelectLangList';
import UseClickOutSide from '../../uses/UseClickOutSide';

import { LangContext } from '../../providers/LangProvider';

import './select-lang.scss';

// select lang
const SelectLang: FC<any> = () => {
  const { lang, langs, setLang }: any = useContext(LangContext);

  // lang, langs, getLanguage, setLang: setLanguage
  const [ open, setOpen ] = useState<boolean>(false); // open

  // element
  const element: any = useRef<any>(null);

  // click out
  UseClickOutSide(element, () => setOpen(false));

  // render
  return (
    <div className="select-lang" data-active={open} ref={element}>
      {lang && <button
          aria-label="open"
          className="select-lang--title"
          onClick={() => setOpen(!open)}>
          <span className="text">{lang?.shortname.toUpperCase()}</span>
          <span className="icon icon-arrow-down-round"></span>
        </button>}

      <SelectLangList
        lang={lang}
        langs={langs}
        setLang={setLang}
        setOpen={setOpen} />
    </div>
  );
};

export default SelectLang;
