import React, { FC } from 'react';

import BackgroundFluent from '../../../components/Backgrounds/BackgroundFluent';
import FooterSocial from '../../Footer/FooterSocial';
import withLoadComponentDataUrl from '../../../components/Load/LoadComponentDataUrl';

import { IHeaderTopItem } from '../HeaderTop/interfaces';
import { IHeaderTopMobile } from './interfaces';

import { ReactComponent as Background } from '../../../assets/mobile/bg-menu-mobile.svg';

import './header-top-mobile.scss';

// options
const options = {
  from: { scale: 0, transform: 'scale(1)', freq: '0.008, 0' },
  to: { scale: 150, transform: 'scale(1)', freq: '0.0, 0.0' },
  config: { duration: 10000 }
};

// header top mobile
const HeaderTopMobile: FC<IHeaderTopMobile> = ({ data, open, lang }) => {
  // render
  return (
    <div className="header-top-mobile">
      <div className="header-top-mobile--background">
        <BackgroundFluent
          active={open}
          speed={1}
          height={210}
          width={375}
          options={options}>
          <Background />
        </BackgroundFluent>
      </div>

      <ul className="header-top-mobile--list">
        {data && data.map(({ relative, title }: IHeaderTopItem, index: number) =>
          <li className="header-top-mobile--item" key={index}>
            <a
              className="link-item"
              href={relative}
              aria-label={title}>{title}</a>
          </li>)}
      </ul>

      <FooterSocial url={`/pt-br/jsonapi/node/rede_social?filter[langcode]=${lang?.lang}&sort=field_ordem`} />
    </div>
  );
};

export default withLoadComponentDataUrl(HeaderTopMobile);
