import React, { FC } from 'react';

import { IText } from './interfaces';

import './text.scss';

// text
const Text: FC<IText> = ({ attributes }) => {
  const { field_texto_traco, field_texto: { value } } = attributes;

  // render
  return (
    <div className="text" data-line={field_texto_traco}>
      <div className="text--container" dangerouslySetInnerHTML={{ __html: value }} />
    </div>
  );
};

export default Text;