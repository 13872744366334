import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';

import { IFooterLogo } from './interfaces';

import './footer-logo.scss';

// footer logo
const FooterLogo: FC<IFooterLogo> = ({lang}) => {
  // render
  const {t}:any = useTranslation();
  return (
    <div className="footer-logo">
      <Link to={"/" + lang?.lang} className="link-item">
        <Logo />
        <span className="text">{ t('footer_message') }</span>
      </Link>
    </div>
  );
};

export default FooterLogo;