import React, { FC, useMemo } from 'react';

import GoldenRulesItem from './GoldenRulesItem';
import withLoadComponentDataUrl from '../Load/LoadComponentDataUrl';

import './golden-rules.scss';
import './GoldenRulesContainer/golden-rules-container.scss';

// list address
const GoldenRulesParagraph: FC<any> = (props) => {
    // render
    const items = useMemo(() => {
        const field = props?.data?.data?.relationships?.field_regra_ouro_item?.data;
        const included = props?.data?.included;
        return field?.map( (item:any) => {
            var inc = included.find( (i:any) => item.id === i.id && item.type === i.type);
            if (inc) {
                var icon = inc?.relationships?.field_imagem?.data;
                icon = included.find( (i:any) => icon.id === i.id && icon.type === i.type);   
                return {
                    title: inc?.attributes?.field_title,
                    text: inc?.attributes?.field_texto?.value,
                    icon: icon?.attributes?.uri?.url,
                }
            }
        }) || [];
    }, [props]);
    return (
        <div className="golden-rules">
            {props?.data?.data && <div className="golden-rules-container">
                <ul className="golden-rules-container--list">
                    {items && items.map((item: any, index: number) =>
                    <li className="golden-rules-container--item" key={index}>
                        <GoldenRulesItem /*number={index + 1}*/ title={item.title} text={item.text} icon={item.icon} />
                    </li>)}
                </ul>
            </div>}
        </div>
    );
};

export default withLoadComponentDataUrl(GoldenRulesParagraph);