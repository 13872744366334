import React, { FC } from 'react';

import { IGalleryThumbsArrow } from './interfaces';

import './gallery-thumbs-arrow.scss';

// gallery thumbs arrow
const GalleryThumbsArrow: FC<IGalleryThumbsArrow> = ({ className, icon, onClick }) => {
  return (
    <button className={`gallery-thumbs-arrow ${className}`} onClick={onClick}>
      <span className={`icon icon-${icon}`}></span>
    </button>
  );
};

export default GalleryThumbsArrow;