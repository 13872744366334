import React, { FC, useContext } from 'react';

import ShipsBackground from '../ShipsBackground';
import ShipsCard from '../ShipsCard';
import withLoadComponentDataNode from '../../Load/LoadComponentDataNode';

import { ShipsContext } from '../../../providers/ShipsProvider';

import { IShipsContext } from '../../../providers/ShipsProvider/interfaces';
import { IShipsFilter } from './interfaces';

import './ships-filter.scss';
import { useTranslation } from 'react-i18next';

// hips filter
const ShipsFilter: FC<IShipsFilter> = ({ data, uuid, lang }) => {
  const shipsContext = useContext(ShipsContext);
  const { t } = useTranslation();
  const { ships, getItem }: IShipsContext = shipsContext;

  // parent
  const parent: any = getItem(uuid, ships);

  // render
  return (
    <div className={`ships-filter ${data && data.data.length === 0  ? 'empty':''}`}  data-type={parent?.tipo}>
      <div className="ships-filter--background">
        {parent &&
          <ShipsBackground min="base" name={parent.name} type={parent?.tipo} />}
          {data && data.data.length === 0 && <p className="not-found">{t('ships_not_found')}</p>}
      </div>
      {!data && <p>Loading</p>}
      {data?.data && <ul className="ships-filter--list">
        {data?.data.map((item: any, index: number) =>
          <li className="ships-filter--item" key={index}>
            <ShipsCard {...item} />
          </li>)}
      </ul>}
    </div>
  );
};

export default withLoadComponentDataNode(ShipsFilter);