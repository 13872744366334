import React, { useCallback, useRef, useState, useEffect, FC, memo } from 'react';

import SelectItem from './SelectMinItem';
import SelectMinTitle from './SelectMinTitle';

import { ISelectMin } from './interfaces';

import './select-min.scss';

// select
const SelectMin: FC<ISelectMin> = ({
  disable,
  defaultText,
  handleChange,
  name,
  options,
  selectedOption
}) => {
  const [ active, setActive ] = useState<boolean>(false);

  // element
  const element = useRef<any>(null);

  // check element
  const checkElement = useCallback(event => {
    if (element.current instanceof Object && event instanceof Object) {
      const { x, y } = event;
      const { height, left, top, width } = element.current.getBoundingClientRect();

      const maxHeight = height + top;
      const maxWidth = left + width;

      if ((x < left || x > maxWidth) || (y < top || y > maxHeight)) {
        setActive(false);
      }
    }
  }, [ setActive ]);

  // on change
  const onChange = useCallback(item => {
    setActive(false);
    handleChange(item);
  }, [ handleChange, setActive ]);

  // on toggle
  const onToggle = useCallback((active) =>
    setActive(active), [ setActive ]);

  // use effect
  useEffect(() => {
    if (active === true) {
      window.addEventListener('click', checkElement);
    } else {
      window.removeEventListener('click', checkElement);
    }

    return () => {
      window.removeEventListener('click', checkElement);
    };
  }, [ active, checkElement ]);

  // render
  return (
    <div
      className="select-min-control"
      data-disabled={disable}
      data-active={!selectedOption}>
      <div
        className="select-min"
        data-active={active}
        ref={element}>
        <SelectMinTitle
          active={active}
          defaultText={defaultText}
          onToggle={onToggle}
          selectedOption={selectedOption} />

        {options && <ul className="select-min--list">
          {options.map((item: any, index: number) =>
            <li
              className="select-min--list--item"
              key={index}>
              <SelectItem
                item={item}
                name={name}
                onSelectOption={onChange}
                selectedOption={selectedOption} />
            </li>)}
        </ul>}
      </div>
    </div>
  );
};

export default memo(SelectMin);