import React, { FC } from 'react';

import { IFeaturesHistoryArrow } from './interfaces';

import './features-history-arrow.scss';

// high light arrow
const FeaturesHistoryArrow: FC<IFeaturesHistoryArrow> = ({ className, icon, onClick, lastSlide, sliderGoTo }) => {
  return (
    <button className={`features-history-arrow ${className}`} onClick={() => {
      lastSlide ? sliderGoTo(0) : onClick();
    }}>
      <span className={`icon icon-${icon}`}></span>
    </button>
  );
};

export default FeaturesHistoryArrow;