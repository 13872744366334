import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { IModalVideo } from './interfaces';

const ModalVideo: FC<IModalVideo> = ({ onClose, url, title }: any) => {
    const [open, setOpen] = useState<any>(false);

    const close = useCallback((event: any) => {
        event.preventDefault();
        setOpen(false)
        if (onClose) onClose();
    }, [onClose]);

    const onClickOutside = useCallback((event: any) => {
        if (event.target.classList.contains('modal-video')) {
            close(event);
        }
    }, [close]);

    const videoUrl = useMemo(() => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        var id = (match && match[7].length === 11) ? match[7] : false;
        return id ? `https://www.youtube.com/embed/${id}?autoplay=1` : url;
    }, [url]);

    useEffect(() => {
        setOpen(true)
        return () => {
            setOpen(false);
        }
    }, [open]);

    return (
        <div className={`modal-video ${open ? 'active' : ''}`} onClick={(e) => onClickOutside(e)}>
            <div className="modal-video--content">
                <button className="close" onClick={(e) => close(e)}>
                    <span className="icon icon-close"></span>
                </button>
                <div className="modal-video--body">
                    <iframe
                        title={title}
                        src={videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};


export default ModalVideo;
