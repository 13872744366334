import React, { FC, useState } from 'react';
import {ICookieAlert} from './interfaces';
import './index.scss';
import { useTranslation } from 'react-i18next';
// CookieAlert info
const CookieAlert: FC<ICookieAlert> = _ => {
  const { t } = useTranslation();
  const styles: any = {
    enableBackground: 'new 0 0 46 46'
  }

  const [accepted, setAccepted] = useState(!!localStorage.getItem('accept-cookies'));

  const acceptCookies = (event:any) => {
    event.preventDefault();
    event.stopPropagation();
    localStorage.setItem('accept-cookies', '1')
    setAccepted(true);

  }
  // render
  if (!accepted)
    return (
      <div className="alert--footer fixed-bottom">
        <div className="wrapper">
          <div className="alert--content">
            <div className="alert--info">
              <div className="info-icon"><span className="icon icon-info-warning">
                  {/* ?xml version="1.0" encoding="utf-8"?*/}
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 46 46" style={styles} xmlSpace="preserve">
                    <g>
                      <path d="M23,0C10.3,0,0,10.3,0,23c0,12.7,10.3,23,23,23c12.7,0,23-10.3,23-23C46,10.3,35.7,0,23,0z M23,42.5                                C12.2,42.4,3.6,33.8,3.5,23C3.6,12.2,12.2,3.6,23,3.5c10.8,0,19.4,8.7,19.5,19.5C42.4,33.8,33.8,42.4,23,42.5z" />
                      <path d="M23.2,29.6c1,0,1.8-0.8,1.8-1.8V11.8c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v16.1C21.4,28.8,22.2,29.6,23.2,29.6z" />
                      <path d="M23.2,32.7c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8C25,33.5,24.2,32.7,23.2,32.7z" />
                    </g>
                  </svg></span></div>
              <p className="info">
                <span className="description">{ t('cookie_alert_message') }</span>
              </p>
            </div>
            <div className="alert--actions">
              <form action="?accept-cookies" method="post">
                <input className="form-control" type="hidden" name="lgpd-accept" />
                <div className="form-group">
                  <button className="action" onClick={acceptCookies} type="submit">{ t('cookie_alert_button') }</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  return null;
};

export default CookieAlert;