import React, { FC, useCallback, useEffect, useState } from 'react';

import { IPicture } from './interfaces';

// picture
const Picture: FC<IPicture> = ({ alt, sources, properties, prefix }) => {
  // items
  const [ items, setItems ] = useState<any[]>([]);

  // filter sources
  const filterSources = useCallback((sources) => {
    const elements: any= [];

    for (let key in sources) {
      const item = sources[key];
      const size = key.replace(prefix, '').split('_');

      if (size.length > 1) {
        const { href } = item;
        
        elements.push({ size: { 
          width: parseInt(size[1], 10),
          height: parseInt(size[2], 10) 
        }, href });
      }
    }

    elements.sort((a: any, b: any) => b.size.width - a.size.width); // order by with

    setItems(elements);
  }, [ prefix ]);

  // use effect
  useEffect(() => {
    if (sources instanceof Object) {
      filterSources(sources);
    }
  }, [ sources, filterSources ]);

  // render
  return (
    <picture {...properties}>
      {items && items.map(({ size, href }, index: number) => 
        <source
          key={index}
          media={`(max-width: ${size.width}px)`}
          srcSet={href} />)}

      {items.length > 0 && <img src={items[0].href} alt={alt} />}
    </picture>
  );
};

export default Picture;