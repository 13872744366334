import React from 'react';
import axios from 'axios';
import useSWR from 'swr';

// env
const { REACT_APP_URL_BASE, REACT_APP_URL_NODE } = process.env;

// api
export const api = (url: string) => axios.get(url).then(({ data }) => data);
export const getNodeUrl = (lang:any, node:any, ) => {
  let url =`${REACT_APP_URL_BASE}${lang ? `/${lang}`:``}${REACT_APP_URL_NODE}/${node}${lang ? `${node.indexOf('?') === -1 ? '?':'&'}filter[langcode]=${lang}`:``}`;
  return url;
}
// load component data
const withLoadComponentDataNode = (ComponentElement: any) => {
  // render
  return (props: any) => {
    const { data, error } = useSWR<[any]>(getNodeUrl(props?.lang?.lang, props?.node), api);
    if (data && props.setContent) {
      props.setContent(data);
    }

    return(
      <>
        <ComponentElement data={data} error={error} {...props} />
      </>);
  };
};

export default withLoadComponentDataNode;