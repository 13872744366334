import React, { FC, useContext } from 'react';

import BackgroundScroll from '../../components/Backgrounds/BackgroundScroll';
import withLoadComponentDataUrl from '../../components/Load/LoadComponentDataUrl';
import WebdoorInterna from '../../components/WebdoorInterna';

import { MainContext } from '../../providers/MainProvider';

import { ReactComponent as Background } from '../../assets/svg/bg-lines-lg.svg';

import { IInterna } from './interfaces';

import './interna.scss';
// page
const Interna: FC<IInterna> = ({ data, lang }) => {
  const mainContext = useContext(MainContext);
  const { getComponents } = mainContext;

  // render
  return (
    <div className="interna">
      {data && <>
        <BackgroundScroll>
          <Background />
        </BackgroundScroll>
        {data?.data?.relationships?.field_image?.data?.id && <WebdoorInterna id={data?.data.relationships.field_image.data.id} />}

        {getComponents(data?.included, lang)}
        </>}
    </div>
  );
};

export default withLoadComponentDataUrl(Interna);
