import React, { createContext, FC, useCallback } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import LangProvider from '../LangProvider';

import { ILangItem } from '../LangProvider/interfaces';
import { IMainContext, IMainProvider } from './interfaces';

// components
import { createComponent } from './components';

// main context
const MainContext = createContext({} as IMainContext);

// main provider
const MainProvider: FC<IMainProvider> = ({ children }) => {
  // create component
  const createComponents = useCallback((items: any[], lang: any) => {
    if (Array.isArray(items) === false) return false;

    const components = [];

    for (let element of items) {
      if (element instanceof Object) {
        const { type } = element;
        const component: any = createComponent(element, type.replace('paragraph--', ''), lang);

        if (component instanceof Object) {
          components.push(component);
        }
      }
    }

    return components;
  }, []);

  // get component
  const getComponents = useCallback((data: any, lang: ILangItem) => {
    if (Array.isArray(data))
      return createComponents(data, lang);

    return false;
  }, [ createComponents ]);

  // get type
  const getType = useCallback((lang: ILangItem, data?: any): string => {
    if (data instanceof Object === false) {
      return `home?filter[langcode]=${lang?.lang}&fields[node--home]=field_home_components&include=field_home_components&page[limit]=1`;
    }

    const { entity: { type, bundle, uuid } } = data;
    let include;
    
    if (bundle === 'page' || bundle === 'blog') {
      include = 'field_paragraphs';
      if (bundle === 'blog') {
        include += ',field_assunto';
      }
    }

    return `/jsonapi/${type}/${bundle}/${uuid}${include ? `?include=${include}` : ''}`;
  }, []);
  
  // render
  return (
    <MainContext.Provider value={{
      getComponents,
      getType
    }}>
      <ParallaxProvider>
        <LangProvider>
          {children}
        </LangProvider>
      </ParallaxProvider>
    </MainContext.Provider>
  );
};

export { MainContext, MainProvider };
export default MainProvider;