import React, { FC, useRef, useState } from 'react';

import BackgroundFluent from '../Backgrounds/BackgroundFluent';
import HighLightsSlider from './HighLightsSlider';
import UseDimension from '../../uses/UseDimension';

import { ReactComponent as Background } from '../../assets/svg/bg-highlights-ex.svg';

import { IHighLights } from './interfaces';

import { options } from './options';

import './highlights.scss';

// highlights
const HighLights: FC<IHighLights> = ({ attributes, lang, relationships }) => {
  const { field_diferenciais: { data } } = relationships;
  
  const [ animation, setAnimation ] = useState<boolean>(false);
  
  const element = useRef<any>(null);
  const { height, width } = UseDimension(element);

  // render
  return (
    <div
      ref={element}
      className="highlights">
      <div className="highlights--background">
        <BackgroundFluent
          active={animation}
          speed={4}
          height={height}
          width={width}
          options={options}
          callback={() => setAnimation(false)}>
          <Background />
        </BackgroundFluent>
      </div>
      
      <div className="highlights--content">
        <div className="highlights--title">
          <p className="title">{attributes.field_title}</p>
        </div>

        {data && <HighLightsSlider
          lang={lang}
          items={data}
          setAnimation={setAnimation} />}
      </div>
    </div>
  );
};

export default HighLights;