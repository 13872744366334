import { Link } from 'react-router-dom';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import BlogInfo from './BlogInfo';
import BlogRelatedPosts from './BlogRelatedPosts';
import TitleText from '../../components/TitleText';
import WebdoorInterna from '../../components/WebdoorInterna';
import withLoadComponentDataUrl from '../../components/Load/LoadComponentDataUrl';

import { MainContext } from '../../providers/MainProvider';

import { IBlog } from './interfaces';

import './blog.scss';

// page
const Blog: FC<IBlog> = ({ data, lang }) => {
  const mainContext = useContext(MainContext);
  const { getComponents } = mainContext;

  const { t }: any = useTranslation();
  
  const assunto = data?.data?.relationships?.field_assunto?.data
  const field_assunto = data?.included.find( (item:any) => item.id === assunto.id && assunto.type === item.type);
  // render
  return (
    <div className="blog">
      {data && <>
        <WebdoorInterna id={data?.data?.relationships?.field_banner?.data?.id} />

        <TitleText attributes={{ field_title: t('media'), field_title_tag: 'h1' }} id="" />

        {data?.data && <BlogInfo {...data?.data} />}
        {getComponents(data?.included, lang)}
        {field_assunto && <BlogRelatedPosts lang={lang} field_assunto={field_assunto} nid={data?.data?.attributes?.drupal_internal__nid} />}
        <div className="blog--footer">
          <Link className="btn-border" to={lang.lang === 'en' ? '/en/media' : `/${lang.lang}/midia`}>
            <span className="icon icon-arrow-left-l"></span>
            <span className="text">{t('all_articles')}</span>
          </Link>
        </div>
        </>}
    </div>
  );
};

export default withLoadComponentDataUrl(Blog);