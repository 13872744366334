import React, { FC } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import {IBlogRelatedPosts} from './interfaces';
import './blog-related-posts.scss';

const {
    REACT_APP_URL_BASE,
    REACT_APP_URL_NODE,
}: any = process.env;

const api = (url: string) => axios.get(REACT_APP_URL_BASE + REACT_APP_URL_NODE + url).then(({ data }) => data);


const BlogRelatedPosts : FC<IBlogRelatedPosts> = ({lang, field_assunto, nid}) => {
    const { data } = useSWR<any>(`/blog?filter[langcode]=${lang.lang}`
    + `&include=field_image,field_assunto`
    + `&sort=-field_date`
    + `&filter[assunto][path]=field_assunto.id`
    + `&filter[assunto][value]=${field_assunto.id}`
    + `&filter[nid][condition][path]=nid`
    + `&filter[nid][condition][operator]=<>`
    + `&filter[nid][condition][value]=${nid}`
    + `&page[limit]=3`, api);
    const getUrlPost = ({alias, langcode}:any) => {
        return alias ? `/${langcode}${alias}` : '/';
    }

    return (<div className="blog--related-posts">
            {/* {!data && } */}
            {!!data && <>
                <div className="title-text" data-type="h2">
                    <h1 className="text">CONTEÚDOS RELACIONADOS</h1>
                </div>
                <div className="list-simple">
                    <ul className="list-simple--list">
                        {data?.data.map( (post:any, index:any) => <li key={index} className="list-simple--item">
                            <p>
                                <Link to={getUrlPost(post?.attributes?.path)}>
                                    {post.attributes.title}
                                </Link>
                            </p>
                        </li>)}
                    </ul>
                </div>
            </>}
    </div>);
}

export default BlogRelatedPosts