import React, { FC, useCallback } from 'react';

import { ICardGalleryControls } from './interface';

import './card-gallery-controls.scss';

// card gallery controls
const CardGalleryControls: FC<ICardGalleryControls> = ({
  current,
  counts,
  onPrevNext,
  setAnimation
}) => {
  // on change
  const onChange = useCallback((type: string) => {
    setAnimation(true);
    onPrevNext(type);
  }, [ onPrevNext, setAnimation ]);

  // render
  return (
    <div className="card-gallery-controls">
      <button
        className="btn left"
        onClick={() => onChange('prev')}
        disabled={current === 0}>
          <span className="icon icon-arrow-left-l"></span>
      </button>
      
      <button
        className="btn right"
        onClick={() => onChange('next')}
        disabled={current === (counts - 1)}>
          <span className="icon icon-arrow-right-l"></span>
      </button>
    </div>
  );
};

export default CardGalleryControls;