import React, { FC, useRef } from 'react';
import UseDimension from '../../../../uses/UseDimension';

import BackgroundFluent from '../../../Backgrounds/BackgroundFluent';

import { ReactComponent as Background } from '../../../../assets/timeline/bg-timeline-item.svg';

import { ITimeLineItemBackground } from './interfaces';

import { settings } from './settings';

import './timeline-item-background.scss';

// time line item background
const TimeLineItemBackground: FC<ITimeLineItemBackground> = ({ animate, type }) => {
  const element = useRef<any>(null);
  const { height, width } = UseDimension(element);

  // render
  return (
    <div
      ref={element}
      className="timeline-item-background"
      data-type={type}>
      <BackgroundFluent
        active={animate}
        speed={1}
        height={height}
        width={width}
        options={settings}>
          <Background />
      </BackgroundFluent>
    </div>
  );
};

export default TimeLineItemBackground;