import React, { FC } from 'react';
import { Link as LinkRoute } from 'react-router-dom';
const Link: FC<any> = (props) => {
  const { children, internal } = props;
  const attributes = {
    className: props?.className,
    href: props?.href,
    onClick: props?.onClick,
    target: props?.target,
  }

  if (internal) {
    return (<LinkRoute {...attributes} to={props.href || ''}>
      {children}
    </LinkRoute>);
  }
  else {
    return (<a {...attributes}>
      {children}
    </a>);
  }
};

export default Link;