import React, { FC } from 'react';

import MenuItem from '../MenuItem';
import withLoadComponentDataNode from '../../Load/LoadComponentDataNode';

import { IMenuItem } from '../MenuItem/interfaces';
import { IMenuContainer } from './interfaces';

import './menu-container.scss';

// menu container
const MenuContainer: FC<IMenuContainer> = ({ data, pathname }) => {
  // render
  return (
    <div className="menu-container">
      {data?.data && 
        data?.data.attributes.field_menu_item.map((item: IMenuItem, index: number) =>
        <MenuItem {...item} pathname={pathname} key={index} />)}
    </div>
  );
};

export default withLoadComponentDataNode(MenuContainer);