import React, { FC, useCallback, useState } from 'react';
import { useDrag } from 'react-use-gesture';
import { withMediaQueries } from '../../MediaQueries';

import WebdoorItem from '../WebdoorItem';
import { IWebdoorItem } from '../WebdoorItem/interfaces';

import { IWebdoorSlider } from './interfaces';

import './webdoor-slider.scss';

// webdoor slider
const WebdoorSlider: FC<IWebdoorSlider> = ({
  direction,
  current,
  prev,
  data,
  items,
  queries,
  onPrevNext,
}) => {
  const [ init, setInit ] = useState<boolean>(true)

  // drag
  const drag = useDrag(({ down, direction: [xDir], distance, cancel }: any) => {
    if (down && distance > 300) {
      const dir = xDir < 0 ? 'next' : 'prev';
      cancel(onPrevNext(dir));
    }
  });

  // get attrs
  const getImages = useCallback((element: any, items: any[]) => {
    if (items instanceof Object === false) return false;

    const { relationships: { field_image: { data: { id } } } } = element;

    return items.filter((item: any) => item.id === id)[0];
  }, []);

  // check mobile
  const checkMobile = useCallback(() => {
    if ((queries === 'xs' || queries === 'sm')) {
      return true;
    }

    return init === false;
  }, [ init, queries ]);

  // render
  return (
    <div
      className="webdoor-slider"
      data-direction={direction}
      {...drag()}>
      {data && data.map((item: IWebdoorItem, index: number) =>
        <WebdoorItem
          key={index}
          current={current === index && checkMobile()}
          prev={prev === index}
          index={index}
          images={getImages(item, items)}
          item={item}
          setInit={setInit} />)}
    </div>
  );
};

export default withMediaQueries(WebdoorSlider);