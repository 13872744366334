import React, { FC } from 'react';

import Footer, {gerFooterUrl} from '../Footer';
import Header from '../Header';
import Page from '../Page';

import { IPageDefault } from './interfaces';

// page default
const PageDefault: FC<IPageDefault> = ({
  location,
  getType,
  lang,
  queries
}) => {
  // render
  return (
    <>
      <Header
        location={location}
        lang={lang}
        mediaQuery={queries} />

      <Page
        getType={getType}
        lang={lang}
        location={location}
        queries={queries}
        url={`/${lang?.lang}/router/translate-path?path=${location.pathname}`} />

      <Footer
        url={gerFooterUrl(lang)}
        location={location}
        lang={lang}
        mediaQuery={queries} />
    </>
  );
};

export default PageDefault;