import React, { FC, memo } from 'react';

import { IGalleryModalControls } from './interfaces';

import './gallery-modal-controls.scss';

// gallery modal controls
const GalleryModalControls: FC<IGalleryModalControls> = ({ onPrevNext }) => {
  // return
  return (
    <div className="gallery-modal-controls">
      <button
        className="btn-item left"
        onClick={() => onPrevNext('prev')}>
        <span className="icon icon-arrow-down-round"></span>
      </button>

      <button
        className="btn-item right"
        onClick={() => onPrevNext('next')}>
        <span className="icon icon-arrow-down-round"></span>
      </button>
    </div>
  );
};

export default memo(GalleryModalControls);