import React, { FC } from 'react';
import Slider from 'react-slick';

import ShipsCard from '../../ShipsCard';
import { withMediaQueries } from '../../../MediaQueries';

import { IShipsGalleryList } from './interfaces';

import { settings } from './settings';

import './ships-gallery-list.scss';

// ships gallery list
const ShipsGalleryList: FC<IShipsGalleryList> = ({ data, queries, type }) => {
  // render
  return (
    <>
      {(queries === 'lg' || queries === 'md') &&
        <ul className="ships-gallery-list" data-type={type}>
          {data?.data && data?.data.map((item: any, index: number) =>
            <li className="ships-gallery-list--item" key={index}>
              <ShipsCard {...item} />
            </li>)}
        </ul>}

      {(queries === 'xs' || queries === 'sm') &&
        <div className="ships-gallery-list--gallery">
          <Slider
            {...settings}>
            {data && data.data.map((item: any, index: number) => <ShipsCard {...item} key={index} />)} 
          </Slider>
        </div>}
    </>
  );
};

export default withMediaQueries(ShipsGalleryList);