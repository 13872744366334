import { ILangItem } from "./interfaces";
// langs
export const langs: ILangItem[] = [
  {
    lang: 'pt-br',
    image: '/images/lang-pt.png',
    shortname: 'PT',
    name: 'Português',
  }, {
    lang: 'en',
    image: '/images/lang-en.png',
    shortname: 'EN',
    name: 'English'
  }
];

export function findLangByUrl<ILangItem>(url: string) {
  const language:any = url.split('/').length > 1 ? url.split('/')[1] : false;
  const lang = getLang(language || 'pt-br');
  if (!lang) return langs[0]
  return lang;
}

export function getLang<ILangItem>(key: string) {
  return langs.find(lang => lang.lang === key);
}