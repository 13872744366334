import React, { FC } from 'react';

import Image from '../../../Image';

import './timeline-home-image.scss';

// time line home image
const TimeLineHomeImage: FC<any> = ({ data }) => {
  const { relationships: { field_imagem: { data: { id, meta: { alt } } } } } = data;

  // render
  return (
    <div className="timeline-home-image">
      <Image id={id} options={{ alt }} />
    </div>
  );
};

export default TimeLineHomeImage;