import '../../nanoid/polyfill';

import React, { FC, useCallback, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import Vimeo from '@u-wave/react-vimeo';

import { withMediaQueries } from '../../MediaQueries';
import Picture from '../../Picture';
import UseDimension from '../../../uses/UseDimension';

import { IWebdoorItem, IWebdoorItemImage } from './interfaces';

import './webdoor-item.scss';

// Webdoor item
const WebdoorItem: FC<IWebdoorItem> = ({
  current,
  index,
  item,
  images,
  queries,
  prev,
  setInit
}) => {
  const { attributes: { field_webdoor_video } }: IWebdoorItemImage = item;
  const id = nanoid();

  const [ loaded, setLoaded ] = useState<boolean>(false);

  // video
  const element = useRef<any>(null);
  const { width } = UseDimension(element);

  // on load
  const onLoad = useCallback((value: boolean) => {
    if (index === 0) {
      setInit(false);
    }

    setLoaded(value);
  }, [ setLoaded, setInit, index ]);

  // render
  return (
    <div
      className="webdoor-item"
      data-active={current}
      data-last={prev}
      ref={element}>
        {(queries === 'xs' || queries === 'sm' || queries === 'md') &&
          <Picture
            alt={images.attributes.filename}
            sources={images.links}
            properties={{}}
            prefix="webdoor_" />}
        {(field_webdoor_video && queries === 'lg') &&
          <Vimeo
            className="video"
            background
            autoplay
            autopause={false}
            controls={false}
            loop={true}
            id={id}
            muted
            onLoaded={() => onLoad(true)}
            paused={!current && loaded === true}
            video={field_webdoor_video}
            width={width} />}
    </div>
  );
};

export default withMediaQueries(WebdoorItem);
