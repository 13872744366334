import React, { FC } from 'react';

import { IHightLightsArrow } from './interfaces';

import './highlights-arrow.scss';

// high light arrow
const HighLightsArrow: FC<IHightLightsArrow> = ({ className, icon, onClick }) => {
  return (
    <button className={`highlights-arrow ${className}`} onClick={onClick}>
      <span className={`icon icon-${icon}`}></span>
    </button>
  );
};

export default HighLightsArrow;