import React, { FC, memo } from 'react';
import useSWR from 'swr';
import Link from '../../Link';

import Image from '../../Image';
import { api } from '../../Load';

import { IClientSliderItem } from './interfaces';

import './client-slider-item.scss';

// client slider item
const ClientSliderItemWrapper: FC<any> = ({ url, image, target }) => {
  // render
  let attributes: any = {}
  if (target) {
    attributes.target = target
  }
  return (
    <div className="client-slider-item">
      {url && <Link
        className="link-client"
        {...attributes}
        internal={false}
        target='_blank'
        href={url || ''}>
          <Image {...image} />
      </Link>}
      {!url && <span className="link-client">
        <Image {...image} />
      </span>}
    </div>
  );
};
const ClientSliderItem: FC<IClientSliderItem> = ({ attributes, relationships }) => {
  const { field_logo: { data: { id, meta: {alt, title : meta_title} } } } = relationships;
  const { field_link, title } = attributes;

  // render
  return (
    <ClientSliderItemWrapper url={field_link?.uri || ''} image={{id:id, alt: alt || title, title: meta_title || title}} />
  );
};

export const ClientSliderItemParagraph: FC<any> = ({lang, type, id}) => {
  const entity_type = type.split('--')[1];
  const url= `/${lang.lang}/jsonapi/paragraph/${entity_type}/${id}?include=field_imagem`;
  const { data } = useSWR<any>(url, api);
  if (data) {
    const {data: { attributes : {field_link_tipo, field_link_url}, relationships:{field_imagem} }, included} = data;
    const image = included.find( (inc: any) => inc.type === field_imagem.data.type && inc.id === field_imagem.data.id);
    return (
      <ClientSliderItemWrapper url={field_link_url || ''} target={field_link_tipo === 'interno' ? '_blank':''} image={{id:image.id, option:{alt: field_imagem?.data?.meta?.titl}}} />
    );
  }
  return null;
}

export default memo(ClientSliderItem);