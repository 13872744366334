import React, { FC } from 'react';

import { ISliderInput } from './interfaces';

// slider input
const SliderInput: FC<ISliderInput> = ({ callback, value, options }) => {
  return (
    <>
      <input
        {...options}
        type="range"
        value={value}
        onChange={({ target: { value }}) => callback(value)} />
    </>
  );
};

export default SliderInput;