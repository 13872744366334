import React, { FC } from "react";

import { IButtonDownload } from "./interface";

import PDF from "../../assets/svg/pdf.svg";

import "./button-download.scss";

// button download
const ButtonDownload: FC<IButtonDownload> = ({ attributes }) => {
  const { field_botao_icone, field_link_url, field_link_text } = attributes;

  // button download
  const iconExists = !!field_botao_icone;
  const icone = field_botao_icone === "externallink";

  return (
    <div className="button-download">
      <a
        className="btn-item"
        href={field_link_url}
        download={iconExists}
        target={icone ? "_blank" : ""}
      >
        <span className="text">{field_link_text}</span>
        {field_botao_icone === "pdf" && (
          <img
            className="icon-item"
            data-type={field_botao_icone}
            src={PDF}
            alt={field_link_text}
          />
        )}

        {field_botao_icone === "externallink" && (
          <svg
            width="20"
            height="20"
            viewBox="0 0 22 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 7.50001L20 1.50001M20 1.50001H14M20 1.50001L11 10.5M9 1.5H6.8C5.11984 1.5 4.27976 1.5 3.63803 1.82698C3.07354 2.1146 2.6146 2.57354 2.32698 3.13803C2 3.77976 2 4.61984 2 6.3V14.7C2 16.3802 2 17.2202 2.32698 17.862C2.6146 18.4265 3.07354 18.8854 3.63803 19.173C4.27976 19.5 5.11984 19.5 6.8 19.5H15.2C16.8802 19.5 17.7202 19.5 18.362 19.173C18.9265 18.8854 19.3854 18.4265 19.673 17.862C20 17.2202 20 16.3802 20 14.7V12.5"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}

        {field_botao_icone === "noicone" && (
          <img
            className="icon-item"
            data-type={field_botao_icone}
            src={PDF}
            alt={field_link_text}
            style={{display: 'none'}}
          />)}
      </a>
    </div>
  );
};

export default ButtonDownload;
