import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { IShipsTabItemHome } from './interfaces';

import './ships-tab-item-home.scss';

// ships tab item home
const ShipsTabItemHome: FC<IShipsTabItemHome> = ({ lang, link, image }) => {
  // render
  return (
    <NavLink
      exact
      className="ships-tab-item-home"
      to={`/${lang.lang}/${link}`}>
      <img className="image" src={`${process.env.PUBLIC_URL}${image}`} alt="all" />
      <span className="text">{lang.lang === 'pt-br' ? 'Toda frota' : 'All Ships'}</span>
    </NavLink>
  );
};

export default ShipsTabItemHome;