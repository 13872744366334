import React, { FC } from 'react';

import BackgroundFluent from '../../Backgrounds/BackgroundFluent';
import BaseSlider from '../../Slider/Base';
import CardGallery from '../CardGallery';

import { ReactComponent as Background } from '../../../assets/svg/bg-card.svg';

import { ICardImage } from './interfaces';

import { options } from './options';

import './card-image.scss';

// card image
const CardImage: FC<ICardImage> = ({
  animation,
  direction,
  current,
  last,
  onPrevNext,
  items,
  type,
  setAnimation
}) => {
  // render
  return (
    <div
      className="card-image"
      data-direction={direction}
      data-type={type}
      data-multiple={items.length > 1}>
      <div className="card-image--background">
        <BackgroundFluent
          active={animation}
          speed={1}
          height={600}
          width={780}
          options={options}
          callback={() => setAnimation(false)}>
            <Background />
        </BackgroundFluent>
      </div>
      
      <CardGallery
        direction={direction}
        current={current}
        last={last}
        items={items}
        onPrevNext={onPrevNext}
        setAnimation={setAnimation} />
    </div>
  );
};

export default BaseSlider(CardImage);