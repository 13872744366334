import React, { FC } from 'react';

import { ISelectTitle } from './interfaces';

import './select-title.scss';

// select title
const SelectTitle: FC<ISelectTitle> = ({ active, defaultText, onToggle, selectedOption: { label, value } }) => {
  // render
  return (
    <div className="select-title" onClick={() => onToggle(!active)} data-active={active}>
      <p className="select-title--content">
        <span className="text" data-empty={value ? true : false}>{ label ? label : defaultText }</span>
        <span className="icon icon-arrow-right" data-active={active}></span>
      </p>
    </div>
  );
};


export default SelectTitle;