import React, { FC, useEffect, useState, useMemo } from 'react';

import PDF from "../../assets/svg/pdf.svg";
import axios from 'axios';
import withLoadComponentDataNode from '../Load/LoadComponentDataNode';
import './index.scss';

// webdoor
const ViewDocumentosIgualdadeSalarial: FC<any> = ({ data, lang }) => {
    const [semestres, setSemestres] = useState([]);
    const [selected, setSelected] = useState('');

    const items = useMemo(() => data?.data?.length > 0 && selected ? data.data.map((item: any) => {
            return {
                title: item.attributes.title,
                file: data.included.find((file: any) => file.id === item.relationships.field_documento_arquivo.data.id)?.attributes?.uri?.url,
                categoria: item.relationships.field_documento_categoria.data.id
            }
        }).filter((item:any) => item.categoria === selected) : []
    , [data, selected]);

    const options = useMemo(() => data?.data?.length > 0 && semestres.length > 0 ?
        semestres.filter((item: any) => data?.data.find((i: any) => i.relationships.field_documento_categoria.data.id === item.tid)) : []
    , [data, semestres]);

    useEffect(() => {
        const getInfo = async () => {
            if (semestres.length === 0) {
                const url = `/${lang.lang}/jsonapi/taxonomy_term/igualdade_salarial?sort=weight&fields=name,tid,weight`;
                axios.get(url).then(({ data }) => {
                    const options = data.data.map((item: any) => {
                        return {
                            name: item.attributes.name,
                            tid: item.id
                        }
                    });
                    setSemestres(options);
                });
            }
            if (options.length > 0 && !selected) {
                const first: any = options[0];
                setSelected(first.tid);
            }
        }
        getInfo();
    }, [lang.lang, options, semestres, selected]);

    return (
        <div className="view-documentos-igualdade-salarial">
            {options.length > 0 && <div className="semestre-wrapper">
                <select onChange={({ target }) => setSelected(target.value)} defaultValue={selected}>
                    {options.map((item: any) => {
                        return <option key={item.tid} value={item.tid}>{item.name}</option>
                    })}
                </select>
                <span className="icon icon-arrow-down-round"></span>
            </div>}
            {items.length > 0 &&
                <div className="items">
                    {items.map((item: any, index: number) => <div className="button-download" key={index}>
                        <a className="btn-item" href={item.file} download="" target="">
                            <span className="text">{item.title}</span>
                            <img
                                className="icon-item"
                                data-type='pdf'
                                src={PDF}
                                alt={item.title}
                            />
                        </a>
                    </div>)}
                </div>}
        </div>
    );
};

export default withLoadComponentDataNode(ViewDocumentosIgualdadeSalarial);